import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';
import { types as CreateWizardActionsTypes } from './createWizard/actions';
import bulkWizardReducer, {
  initialState as bulkWizardInitialState
} from './bulkWizard/reducers';

import createWizardReducer, {
  initialState as createWizardInitialState
} from './createWizard/reducers';
import { action } from '@storybook/addon-actions';

const initialState = {
  updateUserByIdIsInProgress: false,
  updateUserByIdHasFailed: false,
  updateUserByIdSuccess: false,
  deleteUserByIdIsInProgress: false,
  deleteUserByIdHasFailed: false,
  deleteUserByIdSuccess: false,
  createUserIsInProgress: false,
  createUserHasFailed: false,
  createUserSuccess: false,
  findUserByIdIsInProgress: false,
  findUserByIdHasFailed: false,
  currentUser: null,
  currentUserUnits: [],
  currentUserSelectedUnits: [],
  queryUnitsIsInProgress: false,
  queryUnitsHasFailed: false,
  queryUsersIsInProgress: false,
  queryUsersHasFailed: false,
  list: [],
  currentUserVehicles: [],
  totalPages: -1,
  currentPage: 0,
  apiError: null,
  bulkWizard: bulkWizardInitialState,
  createWizard: createWizardInitialState,
  sendWelcomeEmailIsInProgress: false,
  sendWelcomeEmailHasFailed: false,
  sendWelcomeEmailSuccess: false,
  assignUnitsToUserInProgress: false,
  assignUnitsToUserHasFailed: false,
  assignUnitsToUserSuccess: false,
  unassignUnitFromUserInProgress: false,
  unassignUnitFromUserHasFailed: false,
  changeIndividualStatusInCommunityInProgress: false,
  changeIndividualStatusInCommunityFailed: false,
  changeIndividualStatusInCommunitySuccess: false,
  assignVehiclesToUserHasFailed: false,
  isAssignVehicleInProgress: false,
  hasAssignVehicleFailed: false,
  isUnassignVehicleFromUserInProgress: false,
  hasUnassignVehicleFromUserFailed: false,
  petImageUploadedUri: undefined,
  uploadingSuccess: null,
  petCreated: null,
  updatePetInProgress: false,
  updatePetSuccess: false,
  updatePetFail: false,
  individualForPet: false,
  totalGuards: '',
  totalEnabledUsers: '',
  totalDisabledUsers: '',
  assingExistingUserInProgress: false,
  assingExistingUserSuccess: false,
  assingExistingUserFail: false
};

const reducer = handleActions(
  {
    ...bulkWizardReducer,
    ...createWizardReducer,
    [types.assingExistingUserInProgress]: state => {
      return {
        ...state,
        assingExistingUserInProgress: true,
        assingExistingUserSuccess: false,
        assingExistingUserFail: false
      };
    },
    [types.assingExistingUserSuccess]: state => {
      return {
        ...state,
        assingExistingUserInProgress: false,
        assingExistingUserSuccess: true,
        assingExistingUserFail: false
      };
    },
    [types.assingExistingUserFail]: state => {
      return {
        ...state,
        assingExistingUserInProgress: false,
        assingExistingUserSuccess: false,
        assingExistingUserFail: false
      };
    },
    [types.setTotalDisabledUsers]: (state, action) => {
      return {
        ...state,
        totalDisabledUsers: action.payload
      };
    },
    [types.setTotalEnabledUsers]: (state, action) => {
      return {
        ...state,
        totalEnabledUsers: action.payload
      };
    },
    [types.setTotalGuards]: (state, action) => {
      return {
        ...state,
        totalGuards: action.payload
      };
    },
    [types.setIndividualForPet]: (state, action) => {
      return {
        ...state,
        individualForPet: action.payload
      };
    },
    [types.updatePetInProgress]: state => {
      return {
        ...state,
        updatePetFail: false,
        updatePetInProgress: true,
        updatePetSuccess: false
      };
    },
    [types.updatePetSuccess]: state => {
      return {
        ...state,
        updatePetFail: false,
        updatePetInProgress: false,
        updatePetSuccess: true
      };
    },
    [types.updatePetFail]: state => {
      return {
        ...state,
        updatePetFail: true,
        updatePetInProgress: false,
        updatePetSuccess: false
      };
    },
    [types.deleteUserByIdInProgress]: state => {
      return {
        ...state,
        deleteUserByIdIsInProgress: true,
        deleteUserByIdHasFailed: false,
        deleteUserByIdSuccess: false,
        apiError: null
      };
    },
    [types.deleteUserByIdSuccess]: state => {
      return {
        ...state,
        deleteUserByIdIsInProgress: false,
        deleteUserByIdHasFailed: false,
        deleteUserByIdSuccess: true,
        apiError: null
      };
    },
    [types.deleteUserByIdFailed]: (state, action) => {
      return {
        ...state,
        deleteUserByIdIsInProgress: false,
        deleteUserByIdHasFailed: true,
        deleteUserByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },

    [types.changeIndividualStatusInCommunityInProgress]: state => {
      return {
        ...state,
        changeIndividualStatusInCommunityInProgress: true,
        changeIndividualStatusInCommunityFailed: false,
        changeIndividualStatusInCommunitySuccess: false,
        apiError: null
      };
    },
    [types.changeIndividualStatusInCommunitySuccess]: state => {
      return {
        ...state,
        changeIndividualStatusInCommunityInProgress: false,
        changeIndividualStatusInCommunityFailed: false,
        changeIndividualStatusInCommunitySuccess: true,
        apiError: null
      };
    },
    [types.changeIndividualStatusInCommunityFailed]: (state, action) => {
      return {
        ...state,
        changeIndividualStatusInCommunityInProgress: false,
        changeIndividualStatusInCommunityFailed: true,
        changeIndividualStatusInCommunitySuccess: false,
        apiError: action.payload.apiError
      };
    },

    [types.createUserInProgress]: state => {
      return {
        ...state,
        createUserIsInProgress: true,
        createUserHasFailed: false,
        createUserSuccess: false,
        apiError: null
      };
    },
    [types.createUserSuccess]: state => {
      return {
        ...state,
        createUserIsInProgress: false,
        createUserHasFailed: false,
        createUserSuccess: true,
        apiError: null
      };
    },
    [types.createUserFailed]: (state, action) => {
      return {
        ...state,
        createUserIsInProgress: false,
        createUserHasFailed: true,
        createUserSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.updateUserByIdInProgress]: state => {
      return {
        ...state,
        updateUserByIdIsInProgress: true,
        updateUserByIdHasFailed: false,
        updateUserByIdSuccess: false,
        apiError: null
      };
    },
    [types.updateUserByIdSuccess]: (state, action) => {
      const updatedUser = action.payload;
      const userList = state.list;

      const updatedUserList = userList.map(user => {
        if (user.id === updatedUser) {
          return updatedUser;
        } else {
          return user;
        }
      });

      return {
        ...state,
        updateUserByIdIsInProgress: false,
        updateUserByIdHasFailed: false,
        updateUserByIdSuccess: true,
        list: updatedUserList,
        apiError: null
      };
    },
    [types.updateUserByIdFailed]: (state, action) => {
      return {
        ...state,
        updateUserByIdIsInProgress: false,
        updateUserByIdHasFailed: true,
        updateUserByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.queryUsersSuccess]: (state, action) => {
      const individuals = action.payload.individuals;
      return {
        ...state,
        queryUsersIsInProgress: false,
        queryUsersHasFailed: false,
        list: individuals.content,
        totalPages: individuals.totalPages,
        currentPage: individuals.number,
        apiError: null
      };
    },
    [types.queryUsersFailed]: (state, action) => {
      return {
        ...state,
        queryUsersIsInProgress: false,
        queryUsersHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryUsersInProgress]: state => {
      return {
        ...state,
        queryUsersIsInProgress: true,
        queryUsersHasFailed: false,
        apiError: null
      };
    },
    [types.findUserByIdSuccess]: (state, action) => {
      return {
        ...state,
        findUserByIdIsInProgress: false,
        findUserByIdHasFailed: false,
        currentUser: action.payload,
        apiError: null
      };
    },
    [types.findUserByIdInProgress]: state => {
      return {
        ...state,
        findUserByIdIsInProgress: true,
        findUserByIdHasFailed: false,
        currentUser: null,
        apiError: null
      };
    },
    [types.findUserByIdFailed]: (state, action) => {
      return {
        ...state,
        findUserByIdIsInProgress: false,
        findUserByIdHasFailed: true,
        currentUser: null,
        apiError: action.payload.apiError
      };
    },
    [types.assignUnitsToUserSuccess]: (state, action) => {
      const oldUser = state.currentUser;
      const updatedUser = action.payload;
      return {
        ...state,
        assignUnitsToUserInProgress: false,
        assignUnitsToUserHasFailed: false,
        assignUnitsToUserSuccess: true,
        currentUser: {
          ...oldUser,
          profiles: updatedUser.profiles
        },
        apiError: null
      };
    },
    [types.assignUnitsToUserInProgress]: state => {
      return {
        ...state,
        assignUnitsToUserInProgress: true,
        assignUnitsToUserHasFailed: false,
        assignUnitsToUserSuccess: false,
        apiError: null
      };
    },
    [types.assignUnitsToUserFailed]: (state, action) => {
      return {
        ...state,
        assignUnitsToUserInProgress: false,
        assignUnitsToUserHasFailed: true,
        assignUnitsToUserSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.isAssignVehicleInProgress]: state => {
      return {
        ...state,
        isAssignVehicleInProgress: true,
        hasAssignVehicleFailed: false,
        apiError: null
      };
    },
    [types.hasAssignVehicleFailed]: (state, action) => {
      return {
        ...state,
        isAssignVehicleInProgress: false,
        hasAssignVehicleFailed: true,
        currentUserVehicles: [],
        apiError: action.payload.apiError
      };
    },

    [types.assignVehicleToUserSuccess]: (state, action) => {
      return {
        ...state,
        currentUserVehicles: action.payload.vehicles,
        isAssignVehicleInProgress: false,
        hasAssignVehicleFailed: false,
        apiError: null
      };
    },
    [types.isUnassignVehicleFromUserInProgress]: state => {
      return {
        ...state,
        isUnassignVehicleFromUserInProgress: true,
        hasUnassignVehicleFromUserFailed: false,
        apiError: null
      };
    },
    [types.hasUnassignVehicleFromUserFailed]: (state, action) => {
      return {
        ...state,
        isUnassignVehicleFromUserInProgress: false,
        hasUnassignVehicleFromUserFailed: true,
        apiError: action.payload.apiError,
        currentUserVehicles: []
      };
    },
    [types.unassignVehicleToUserSuccess]: (state, action) => {
      return {
        ...state,
        currentUserVehicles: action.payload.vehicles,
        isUnassignVehicleFromUserInProgress: false,
        hasUnassignVehicleFromUserFailed: false,
        apiError: null
      };
    },
    [types.unassignUnitFromUserSuccess]: (state, action) => {
      return {
        ...state,
        unassignUnitFromUserInProgress: false,
        unassignUnitFromUserHasFailed: false,
        apiError: null
      };
    },
    [types.unassignUnitFromUserInProgress]: state => {
      return {
        ...state,
        unassignUnitFromUserInProgress: true,
        unassignUnitFromUserHasFailed: false,
        apiError: null
      };
    },
    [types.unassignUnitFromUserFailed]: (state, action) => {
      return {
        ...state,
        unassignUnitFromUserInProgress: false,
        unassignUnitFromUserHasFailed: true,
        apiError: action.payload.apiError
      };
    },
    [types.sendWelcomeEmailInProgress]: state => {
      return {
        ...state,
        sendWelcomeEmailIsInProgress: true,
        sendWelcomeEmailHasFailed: false,
        sendWelcomeEmailSuccess: false,
        apiError: null
      };
    },
    [types.sendWelcomeEmailSuccess]: state => {
      return {
        ...state,
        sendWelcomeEmailIsInProgress: false,
        sendWelcomeEmailHasFailed: false,
        sendWelcomeEmailSuccess: true,
        apiError: null
      };
    },
    [types.sendWelcomeEmailFailed]: (state, action) => {
      return {
        ...state,
        sendWelcomeEmailIsInProgress: false,
        sendWelcomeEmailHasFailed: true,
        sendWelcomeEmailSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.clearEmailSuccess]: (state, action) => {
      return {
        ...state,
        sendWelcomeEmailIsInProgress: false,
        sendWelcomeEmailHasFailed: false,
        sendWelcomeEmailSuccess: false,
        apiError: null
      };
    },
    [types.queryUnitsInProgress]: state => {
      return {
        ...state,
        queryUnitsIsInProgress: true,
        queryUnitsHasFailed: false
      };
    },
    [types.queryUnitsFailed]: state => {
      return {
        ...state,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: true
      };
    },
    [types.queryUnitsSuccess]: (state, action) => {
      return {
        ...state,
        currentUserUnits: action.payload.content,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: false
      };
    },
    [types.clearUnits]: state => {
      return {
        ...state,
        currentUserSelectedUnits: []
      };
    },
    [types.addUnitToSelection]: (state, action) => {
      const currentUserSelectedUnits = state.currentUserSelectedUnits;
      const unitToAdd = action.payload;
      const duplicateUnit = currentUserSelectedUnits.filter(
        unit => unit.id === unitToAdd.id
      );

      return {
        ...state,
        currentUserSelectedUnits:
          duplicateUnit.length > 0
            ? currentUserSelectedUnits
            : currentUserSelectedUnits.concat([unitToAdd])
      };
    },
    [types.removeUnitFromSelection]: (state, action) => {
      const currentUserSelectedUnits = state.currentUserSelectedUnits;
      const unitToRemove = action.payload;
      return {
        ...state,
        currentUserSelectedUnits: currentUserSelectedUnits.filter(
          unit => unit.id !== unitToRemove.id
        )
      };
    },
    [types.updateSelectedUnitRelationship]: (state, action) => {
      const currentUserSelectedUnits = state.currentUserSelectedUnits;
      const unitToUpdate = action.payload.unit;
      const newRelationship = action.payload.relationship;
      return {
        ...state,
        currentUserSelectedUnits: currentUserSelectedUnits.map(unit => {
          if (unit.id === unitToUpdate.id) {
            unit.relationship = newRelationship;
          }
          return unit;
        })
      };
    },
    [CreateWizardActionsTypes.stopCreateUserWizard]: state => {
      const createWizardState = state.createWizard;
      return {
        ...state,
        createWizard: {
          ...createWizardInitialState,
          type: createWizardState.type,
          recentSelectedProfiles: createWizardState.selectedUnits.map(
            unit => unit.relationship
          )
        },
        createUserIsInProgress: false,
        createUserHasFailed: false,
        createUserSuccess: false,
        apiError: null
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    },
    [types.uploadPetFileInProgress]: (state, { payload }) => {
      return { ...state, uploadingSuccess: false };
    },
    [types.uploadPetFileSuccess]: (state, { payload }) => {
      return { ...state, petImageUploadedUri: payload, uploadingSuccess: true };
    },
    [types.createNewPetSuccess]: state => {
      return { ...state, petCreated: true };
    }
  },
  initialState
);

export default reducer;
