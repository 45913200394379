import { createAction } from 'redux-actions';
import { destroy } from 'redux-form';
import get from 'lodash/get';
import UnitActions from '../actions';
import { isWizardFirstStep, isWizardLastStep } from './selectors';

const types = {
  startCreateUnitWizard: 'startCreateUnitWizard',
  stopCreateUnitWizard: 'stopCreateUnitWizard',
  setCurrentUnitWizardStep: 'setCurrentUnitWizardStep',
  updateWizardUnit: 'updateWizardUnit',
  setCreateUnitCoordinates: 'setCreateUnitCoordinates'
};

const startCreateUnitWizard = createAction(types.startCreateUnitWizard);
const stopCreateUnitWizard = createAction(types.stopCreateUnitWizard);
const setCurrentUnitWizardStep = createAction(types.setCurrentUnitWizardStep);
const updateWizardUnit = createAction(types.updateWizardUnit);
const setCreateUnitCoordinates = createAction(types.setCreateUnitCoordinates);

const backInUnitCreateWizard = () => {
  return (dispatch, getState) => {
    const currentStep = getState().units.createUnitWizard.step;
    if (isWizardFirstStep(getState())) {
      return; //no-op
    }
    if (currentStep === 'location') {
      dispatch(setCurrentUnitWizardStep('lotInformation'));
    }
  };
};

const nextInUnitCreateWizard = unit => {
  return (dispatch, getState) => {
    const currentStep = getState().units.createUnitWizard.step;
    if (currentStep == null) {
      return; //no-op
    }
    if (isWizardLastStep(getState())) {
      dispatch(stopCreateUnitWizard());
      return;
    }
    dispatch(updateWizardUnit(unit));
    if (currentStep === 'lotInformation') {
      dispatch(setCurrentUnitWizardStep('location'));
    }
    if (currentStep === 'location') {
      const unitLocation = get(
        getState(),
        'units.createUnitWizard.createUnit.location'
      );
      dispatch(
        UnitActions.createUnit({
          ...unit,
          location: unitLocation
        })
      )
        .then(() => {
          dispatch(setCurrentUnitWizardStep('unitCreationSuccess'));
          dispatch(destroy('createUnitForm'));
        })
        .catch(() => null);
    }
  };
};

/**
 * Used when the Unit closes the wizard without finishing.
 * That happens when closes the modal.
 */
const closeCreateUnitWizard = () => {
  return dispatch => {
    dispatch(destroy('createUnitForm'));
    dispatch(stopCreateUnitWizard());
  };
};

export { types };
export default {
  startCreateUnitWizard,
  closeCreateUnitWizard,
  nextInUnitCreateWizard,
  backInUnitCreateWizard,
  setCreateUnitCoordinates
};
