import { handleActions } from 'redux-actions';
import { types } from './actions';

const initialState = {
  apiError: {},
  sendNotificationSuccess: false
};

const reducer = handleActions(
  {
    [types.sendNotificationInProgress]: state => {
      return {
        ...state,
        sendNotificationSuccess: false
      };
    },
    [types.sendNotificationSuccess]: state => {
      return {
        ...state,
        sendNotificationSuccess: true
      };
    }
  },
  initialState
);

export default reducer;
