import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.css';
import { delay } from 'lodash';

const Avatar = ({ uri, modifier, canShowName = false, noRadius = false }) => {
  const [showImageName, setShowImageName] = useState(false);

  const toggleShowImageName = () => {
    setShowImageName(!showImageName);
  };

  const imageStyles = classNames(
    'kv-avatar__image',
    `kv-avatar__image-${modifier}`,
    noRadius ? 'kv-avatar__image-noRadius' : ''
  );
  const iconStyles = classNames(
    'fa fa-user-circle',
    `kv-avatar__icon-${modifier}`
  );

  const imageName = uri
    ?.split('/')
    .slice(-1)[0]
    .split('-')
    .slice(0, -3)
    .join('-');

  return (
    <div className="kv-avatar" onClick={toggleShowImageName}>
      {showImageName && canShowName ? (
        <div className="kv-avatar__image_name">{imageName}</div>
      ) : null}
      {uri != null && <img src={uri} className={imageStyles} alt="Avatar" />}
      {uri == null && <i className={iconStyles} aria-hidden="true" />}
    </div>
  );
};

Avatar.propTypes = {
  uri: PropTypes.string,
  modifier: PropTypes.oneOf(['x-small', 'small', 'medium', 'large'])
};

Avatar.defaultProps = {
  uri: null,
  modifier: 'small'
};

export default Avatar;
