import isString from 'lodash/isString';

const modifiers = {
  BOOLEAN: [
    {
      value: 'IS',
      name: 'Es',
      fields: 1
    },
    {
      value: 'NOT',
      name: 'No es',
      fields: 1
    }
  ],
  RANGE: [
    {
      value: 'LESSTHAN',
      name: 'Menor a',
      fields: 1
    },
    {
      value: 'GREATERTHAN',
      name: 'Mayor a',
      fields: 1
    },
    {
      value: 'BETWEEN',
      name: 'Entre',
      fields: 2
    },
    {
      value: 'EQUALTO',
      name: 'Es',
      fields: 1
    }
  ]
};

export function getSegments(state) {
  return state.segments.list.map(segment => {
    return {
      ...segment
    };
  });
}

export function isQuerySegmentsInProgress(state) {
  return state.segments.querySegmentsIsInProgress;
}

export function getQuerySegmentsTotalPages(state) {
  return state.segment.totalPages;
}

export function getQuerySegmentsCurrentPage(state) {
  return state.segment.currentPage;
}

export function getSegmentById(state, id) {
  let segmentId = id;

  if (isString(id)) {
    segmentId = parseInt(id, 10);
  }
  const segments = state.list;
  const result = segments.filter(segment => segment.id === segmentId);
  return result.length > 0 ? result[0] : null;
}

export function getCurrentSegment(state) {
  return state.segments.currentSegment;
}

export function isDeleteSegmentByIdInProgress(state) {
  return state.segments.deleteSegmentByIdIsInProgress;
}

export function getFilters(state) {
  return state.segments.filters;
}

export function mapTypeToModifiers(type) {
  return modifiers[type];
}

export function transformSegmentToOptions(segments) {
  return segments.map(segment => {
    return {
      value: segment.id,
      label: segment.name
    };
  });
}
