import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import { getSegmentById } from './selectors';

import { serialize, unserialize } from './serializer';

const types = {
  querySegmentsSuccess: 'querySegmentsSuccess',
  querySegmentsInProgress: 'querySegmentsInProgress',
  querySegmentsFailed: 'querySegmentsFailed',
  findSegmentByIdSuccess: 'findSegmentByIdSuccess',
  findSegmentByIdInProgress: 'findSegmentByIdInProgress',
  findSegmentByIdFailed: 'findSegmentByIdFailed',
  updateSegmentByIdSuccess: 'updateSegmentByIdSuccess',
  updateSegmentByIdInProgress: 'updateSegmentByIdInProgress',
  updateSegmentByIdFailed: 'updateSegmentByIdFailed',
  deleteSegmentByIdSuccess: 'deleteSegmentByIdSuccess',
  deleteSegmentByIdInProgress: 'deleteSegmentByIdInProgress',
  deleteSegmentByIdFailed: 'deleteSegmentByIdFailed',
  createSegmentInProgress: 'createSegmentInProgress',
  createSegmentFailed: 'createSegmentFailed',
  createSegmentSuccess: 'createSegmentSuccess',
  findAvailableFiltersInProgress: 'findAvailableFiltersInProgress',
  findAvailableFiltersSuccess: 'findAvailableFiltersSuccess',
  findAvailableFiltersFailed: 'findAvailableFiltersFailed'
};

const querySegmentsSuccess = createAction(types.querySegmentsSuccess);
const querySegmentsInProgress = createAction(types.querySegmentsInProgress);
const querySegmentsFailed = createAction(types.querySegmentsFailed);
const findSegmentByIdSuccess = createAction(types.findSegmentByIdSuccess);
const findSegmentByIdInProgress = createAction(types.findSegmentByIdInProgress);
const findSegmentByIdFailed = createAction(types.findSegmentByIdFailed);
const updateSegmentByIdSuccess = createAction(types.updateSegmentByIdSuccess);
const updateSegmentByIdInProgress = createAction(
  types.updateSegmentByIdInProgress
);
const updateSegmentByIdFailed = createAction(types.updateSegmentByIdFailed);
const deleteSegmentByIdSuccess = createAction(types.deleteSegmentByIdSuccess);
const deleteSegmentByIdInProgress = createAction(
  types.deleteSegmentByIdInProgress
);
const deleteSegmentByIdFailed = createAction(types.deleteSegmentByIdFailed);
const createSegmentInProgress = createAction(types.createSegmentInProgress);
const createSegmentFailed = createAction(types.createSegmentFailed);

const createSegmentSuccess = createAction(types.createSegmentSuccess);

const findAvailableFiltersInProgress = createAction(
  types.findAvailableFiltersInProgress
);

const findAvailableFiltersSuccess = createAction(
  types.findAvailableFiltersSuccess
);

const findAvailableFiltersFailed = createAction(
  types.findAvailableFiltersFailed
);

/**
 * Segment's page size
 */
export const PAGE_SIZE = 100;

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const querySegments = query => {
  return (dispatch, getState, Api) => {
    dispatch(querySegmentsInProgress());

    const segmentModule = Api.getModule('segment');
    segmentModule.adapter.token = getState().session.user.token;
    segmentModule.adapter.communityId = getState().session.profile.community.id;

    return segmentModule
      .querySegments(query)
      .then(response => {
        const list = response.content;
        const segments = list.map(segment => unserialize(segment));
        dispatch(querySegmentsSuccess(segments));
      })
      .catch(error => {
        dispatch(
          querySegmentsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findSegmentById = id => {
  return (dispatch, getState, Api) => {
    dispatch(findSegmentByIdInProgress());

    const localSegment = getSegmentById(getState().segments, id);

    if (localSegment) {
      dispatch(findSegmentByIdSuccess(localSegment));
    } else {
      const segmentModule = Api.getModule('segment');
      segmentModule.adapter.token = getState().session.user.token;
      segmentModule.adapter.communityId = getState().session.profile.community.id;

      return segmentModule
        .findSegmentById(id)
        .then(response => {
          dispatch(findSegmentByIdSuccess(unserialize(response)));
        })
        .catch(error => {
          dispatch(
            findSegmentByIdFailed({
              apiError: error.apiErrors
            })
          );
        });
    }
  };
};

const createSegment = segment => {
  return (dispatch, getState, Api) => {
    dispatch(createSegmentInProgress());

    const segmentModule = Api.getModule('segment');
    segmentModule.adapter.token = getState().session.user.token;
    segmentModule.adapter.communityId = getState().session.profile.community.id;
    const serializedSegment = serialize(segment, getState());

    return segmentModule
      .createSegment(serializedSegment)
      .then(response => {
        dispatch(createSegmentSuccess(response));
        dispatch(push('/segments'));
      })
      .catch(error => {
        dispatch(
          createSegmentFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updateSegmentById = segment => {
  return (dispatch, getState, Api) => {
    dispatch(updateSegmentByIdInProgress());

    const segmentModule = Api.getModule('segment');
    segmentModule.adapter.token = getState().session.user.token;
    segmentModule.adapter.communityId = getState().session.profile.community.id;

    const serializedSegment = serialize(segment, getState());

    return segmentModule
      .updateSegmentById(serializedSegment)
      .then(response => {
        dispatch(updateSegmentByIdSuccess(response));
        dispatch(push('/segments'));
      })
      .catch(error => {
        dispatch(
          updateSegmentByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const deleteSegment = id => {
  return (dispatch, getState, Api) => {
    dispatch(deleteSegmentByIdInProgress());

    const segmentModule = Api.getModule('segment');
    segmentModule.adapter.token = getState().session.user.token;
    segmentModule.adapter.communityId = getState().session.profile.community.id;

    return segmentModule
      .deleteSegmentById(id)
      .then(() => {
        dispatch(deleteSegmentByIdSuccess(id));
        Promise.resolve();
      })
      .catch(error => {
        dispatch(
          deleteSegmentByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findAvailableFilters = () => {
  return (dispatch, getState, Api) => {
    const segmentModule = Api.getModule('segment');
    segmentModule.adapter.token = getState().session.user.token;
    segmentModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(findAvailableFiltersInProgress());

    const localFilters = getState().segments.filters;
    if (localFilters && localFilters.length > 0) {
      dispatch(
        findAvailableFiltersSuccess({
          filters: localFilters
        })
      );
      return Promise.resolve(localFilters);
    } else {
      return segmentModule
        .findFilters()
        .then(response => {
          dispatch(
            findAvailableFiltersSuccess({
              filters: response
            })
          );
          return Promise.resolve(response);
        })
        .catch(error => {
          dispatch(
            findAvailableFiltersFailed({
              apiError: error.apiErrors
            })
          );
          return Promise.reject(error);
        });
    }
  };
};

export { types };
export default {
  querySegments,
  findSegmentById,
  updateSegmentById,
  deleteSegment,
  createSegment,
  findAvailableFilters
};
