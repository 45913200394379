import { handleActions } from 'redux-actions';
import { types } from './actions';

const initialState = {
  getProvidersInProgress: false,
  getProvidersFail: false,
  apiErrors: null,
  setSettingsInProgress: false,
  setSettingsFail: false,
  setSettingsSuccess: false,
  providers: {},
  settingsCreated: false
};

const reducer = handleActions(
  {
    [types.getProvidersInProgress]: state => {
      return {
        ...state,
        getProvidersInProgress: true,
        getProvidersFail: false
      };
    },
    [types.getProvidersFail]: (state, action) => {
      return {
        ...state,
        getProvidersInProgress: false,
        getProvidersFail: true,
        apiErrors: action.payload.apiError
      };
    },
    [types.getProvidersSuccess]: (state, action) => {
      const providers = action.payload;
      return {
        ...state,
        getProvidersInProgress: false,
        getProvidersFail: false,
        getProvidersSuccess: true,
        providers: providers
      };
    },

    [types.setSettingsInProgress]: state => {
      return {
        ...state,
        setSettingsInProgress: true,
        setSettingsFail: false,
        setSettingsSuccess: false,
        settingsCreated: false
      };
    },

    [types.setSettingsFail]: state => {
      return {
        ...state,
        setSettingsInProgress: false,
        setSettingsFail: true,
        setSettingsSuccess: false,
        settingsCreated: false
      };
    },

    [types.setSettingsSuccess]: (state, action) => {
      return {
        ...state,
        getProvidersInProgress: false,
        getProvidersFail: false,
        setSettingsSuccess: true,
        settingsCreated: true
      };
    }
  },
  initialState
);

export default reducer;
