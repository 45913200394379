import { createAction } from 'redux-actions';
import { destroy } from 'redux-form';
import get from 'lodash/get';
import UserActions from '../actions';
import {
  accountTypes,
  normalProfileTypes
} from '../../../constants/profileTypes';
import { profiles } from '../../../constants/contracts';
import contractActions from '../../contracts/actions';
import { isEmpty } from 'lodash';
import UsersActions from '../actions';

const types = {
  resetExistingUser: 'resetExistingUser',
  verifyUserExistInProgress: 'verifyUserExistInProgress',
  verifyUserExistSuccess: 'verifyUserExistSuccess',
  verifyUserExistFail: 'verifyUserExistInFail',
  setContractsPerUnit: 'setContractsPerUnit',
  startCreateUserWizard: 'startCreateUserWizard',
  createWizardWithSpecificUnit: 'createWizardWithSpecificUnit',
  stopCreateUserWizard: 'stopCreateUserWizard',
  setCurrentStep: 'setCurrentStep',
  updateWizardUser: 'updateWizardUser',
  queryWizardUnitsSuccess: 'queryWizardUnitsSuccess',
  queryWizardUnitsInProgress: 'queryWizardUnitsInProgress',
  queryWizardUnitsFailed: 'queryWizardUnitsFailed',
  clearWizardUnits: 'clearWizardUnits',
  addWizardUnitToSelection: 'addWizardUnitToSelection',
  removeWizardUnitFromSelection: 'removeUnitToSelection',
  updateWizardSelectedUnitRelationship: 'updateWizardSelectedUnitRelationship',
  setSelectedGuardProfile: 'setSelectedGuardProfile',
  setGuardGeneratedPassword: 'setGuardGeneratedPassword',
  setGuardGeneratedEmail: 'setGuardGeneratedEmail',
  openConfirmModal: 'openConfirmModal',
  closeConfirmModal: 'closeConfirmModal',
  openSuccessModalInSpecificUnit: 'openSuccessModalInSpecificUnit',
  closeSuccessModalInSpecificUnit: 'closeSuccessModalInSpecificUnit',
  retryIdentity: 'retryIdentity',
  retryEmail: 'retryEmail',
  continueWithUser: 'continueWithUser',
  notifyDeletedUser: 'notifyDeletedUser',
  resertDeletedUser: 'resertDeletedUser'
};

const openSuccessModalInSpecificUnit = createAction(
  types.openSuccessModalInSpecificUnit
);
const closeSuccessModalInSpecificUnit = createAction(
  types.closeSuccessModalInSpecificUnit
);

const setContractsPerUnit = createAction(types.setContractsPerUnit);
const verifyUserExistInProgress = createAction(types.verifyUserExistInProgress);
const verifyUserExistSuccess = createAction(types.verifyUserExistSuccess);
const verifyUserExistFail = createAction(types.verifyUserExistFail);
const resetExistingUser = createAction(types.resetExistingUser);

const createWizardWithSpecificUnit = createAction(
  types.createWizardWithSpecificUnit
);

const openConfirmModal = createAction(types.openConfirmModal);
const closeConfirmModal = createAction(types.closeConfirmModal);

const startCreateUserWizard = createAction(types.startCreateUserWizard);
const stopCreateUserWizard = createAction(types.stopCreateUserWizard);
const setCurrentStep = createAction(types.setCurrentStep);
const updateWizardUser = createAction(types.updateWizardUser);
const queryWizardUnitsSuccess = createAction(types.queryWizardUnitsSuccess);
const queryWizardUnitsInProgress = createAction(
  types.queryWizardUnitsInProgress
);
const queryWizardUnitsFailed = createAction(types.queryWizardUnitsFailed);
const clearWizardUnits = createAction(types.clearWizardUnits);
const addWizardUnitToSelection = createAction(types.addWizardUnitToSelection);
const removeWizardUnitFromSelection = createAction(
  types.removeWizardUnitFromSelection
);
const updateWizardSelectedUnitRelationship = createAction(
  types.updateWizardSelectedUnitRelationship
);
const setSelectedGuardProfile = createAction(types.setSelectedGuardProfile);
const setGuardGeneratedPassword = createAction(types.setGuardGeneratedPassword);

const retryIdentity = createAction(types.retryIdentity);
const retryEmail = createAction(types.retryEmail);
const continueWithExistingUser = createAction(types.continueWithUser);

const notifyDeletedUser = createAction(types.notifyDeletedUser);
const resertDeletedUser = createAction(types.resertDeletedUser);

const backInCreateWizard = () => {
  return (dispatch, getState) => {
    const currentStep = getState().users.createWizard.step;
    const continueWithExistingUser = getState().users.createWizard
      .continueWithExistingUser;
    const type = getState().users.createWizard.type;
    if (currentStep == null || currentStep === 'identity') {
      return; //no-op
    }
    if (currentStep === 'personal') {
      dispatch(setCurrentStep('identity'));
    }
    if (currentStep === 'contact') {
      dispatch(resetExistingUser());
      dispatch(setCurrentStep('personal'));
    }
    if (currentStep === 'properties') {
      if (continueWithExistingUser) {
        dispatch(retryIdentity());
        dispatch(resetExistingUser());
        dispatch(setCurrentStep('identity'));
      } else {
        dispatch(setCurrentStep('contact'));
      }
    }
    if (currentStep === 'contracts') {
      dispatch(setCurrentStep('properties'));
    }
    if (currentStep === 'profile') {
      if (type === 'resident') {
        dispatch(setCurrentStep('contact'));
      } else {
        dispatch(setCurrentStep('personal'));
      }
    }
  };
};

const verifyUserExistAndCotinue = user => {
  return (dispatch, getState, Api) => {
    dispatch(verifyUserExistInProgress());

    const communityId = getState().session.profile.community.id;

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = communityId;

    const query = {
      ...user?.personalId
    };

    const wizardType = getState().users.createWizard.type;

    return communityModule
      .getIndividuals(query)
      .then(user => {
        const profiles =
          wizardType === 'guard'
            ? user.profiles
            : user.profiles.filter(profile => {
                return normalProfileTypes[profile.type] != null;
              });
        const filteredUser = {
          ...user,
          profiles
        };
        const hasCommunityProfiles = filteredUser.profiles.filter(
          profile => profile.community.id === communityId
        );

        if (hasCommunityProfiles.length > 0) {
          dispatch(verifyUserExistSuccess(filteredUser));
        } else {
          dispatch(verifyUserExistFail());
          dispatch(setCurrentStep('personal'));
        }
      })
      .catch(err => {
        dispatch(verifyUserExistFail());
        dispatch(setCurrentStep('personal'));
      });
  };
};

const verifyUserExistEmailAndCotinue = (email, accountType) => {
  return (dispatch, getState, Api) => {
    dispatch(verifyUserExistInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const creatingUser = getState().users.createWizard.createUser;

    return communityModule
      .findByEmail(email)
      .then(res => {
        const foundUser = {
          ...res,
          profiles: user.profiles.filter(profile => {
            return normalProfileTypes[profile.type] != null;
          })
        };
        if (
          creatingUser.personalId.idType === foundUser.personalId.idType &&
          creatingUser.personalId.number === foundUser.personalId.number
        ) {
          dispatch(verifyUserExistFail());
          dispatch(notifyDeletedUser(foundUser));
          if (accountType === accountTypes.resident) {
            dispatch(setCurrentStep('properties'));
          } else {
            dispatch(setCurrentStep('profile'));
          }
        } else {
          if (foundUser.profiles.length) {
            dispatch(verifyUserExistSuccess({ ...foundUser, show: true }));
          } else {
            dispatch(verifyUserExistSuccess({ ...foundUser, show: false }));
          }
        }
      })
      .catch(err => {
        dispatch(verifyUserExistFail());
        if (accountType === accountTypes.resident) {
          dispatch(setCurrentStep('properties'));
        } else {
          dispatch(setCurrentStep('profile'));
        }
      });
  };
};

const closeCreateConfirmModal = () => {
  return (dispatch, getState, Api) => {
    dispatch(closeConfirmModal());

    return Promise.resolve();
  };
};

const continueWithUser = user => {
  return (dispatch, getState, Api) => {
    dispatch(continueWithExistingUser());
    dispatch(setCurrentStep('properties'));
  };
};

const nextInCreateWizard = user => {
  return (dispatch, getState, Api) => {
    const currentStep = getState().users.createWizard.step;
    const type = getState().users.createWizard.type;
    const isConfirmModalOpen = getState().users.openConfirmModal;
    if (currentStep == null) {
      return; //no-op
    }
    if (currentStep === 'success') {
      dispatch(stopCreateUserWizard());
      return;
    }
    dispatch(updateWizardUser(user));
    if (currentStep === 'identity') {
      dispatch(verifyUserExistAndCotinue(user));
    }
    if (currentStep === 'personal') {
      if (type === 'resident') {
        dispatch(setCurrentStep('contact'));
      } else {
        dispatch(setCurrentStep('profile'));
      }
    }
    if (currentStep === 'contact') {
      dispatch(verifyUserExistEmailAndCotinue(user.email, type));
    }
    if (currentStep === 'properties') {
      dispatch(setCurrentStep('contracts'));
    }
    if (currentStep === 'contracts') {
      if (isConfirmModalOpen) {
        const assignedUnits = getState().users.createWizard.selectedUnits;
        const withSpecificUnit = getState().users.createWizard.withSpecificUnit;
        const contractsPerUnits = getState().users.createWizard
          .contractsPerUnits;
        const contractsDataPerUnits = getState().users.createWizard.contractsPerUnits.map(
          contract => contract.contractData
        );
        const existingUser = getState().users.createWizard.existingUser;
        const continueWithExistingUser = getState().users.createWizard
          .continueWithExistingUser;
        3;
        if (continueWithExistingUser) {
          dispatch(UserActions.assingExistingUserInProgress());
          dispatch(
            UsersActions.assignUnitsWithContracts(
              existingUser,
              assignedUnits,
              contractsPerUnits,
              withSpecificUnit
            )
          )
            .then(res => {
              dispatch(UserActions.assignUnitsToUserSuccess(res));
              dispatch(setCurrentStep('success'));
            })
            .catch(err => {
              dispatch(UserActions.assingExistingUserFail(err));
            });
        } else {
          dispatch(
            UserActions.createUser(
              user,
              'resident',
              assignedUnits,
              null,
              contractsDataPerUnits,
              withSpecificUnit
            )
          ).then(() => {
            if (withSpecificUnit) {
              dispatch(openSuccessModalInSpecificUnit());

              if (isEmpty(contractsDataPerUnits[0].id)) {
                dispatch(contractActions.createContractsSuccess());
              }
            }

            dispatch(setCurrentStep('success'));
            dispatch(destroy('createUserForm'));
          });
        }
      } else {
        dispatch(openConfirmModal());
      }
    }
    if (currentStep === 'profile') {
      const { personalId } = user;
      const guardEmail = `${personalId.number}@keenvil.com`;
      if (isConfirmModalOpen) {
        const guardProfile = getState().users.createWizard.selectedGuardProfile;

        dispatch(
          UserActions.createUser(user, 'guard', null, guardProfile)
        ).then(newGuard => {
          const generatedPassword = get(
            newGuard,
            'accountInfo.selfGeneratedPassword',
            null
          );
          dispatch(setGuardGeneratedPassword(generatedPassword));
          dispatch(setCurrentStep('success'));
        });
      } else {
        dispatch(updateWizardUser({ ...user, email: guardEmail }));
        dispatch(openConfirmModal());
      }
    }
  };
};

/**
 * Used when the user closes the wizard without finishing.
 * That happens when closes the modal.
 */
const closeCreateUserWizard = () => {
  return dispatch => {
    dispatch(destroy('createUserForm'));
    dispatch(stopCreateUserWizard());
  };
};

/**
 *
 * @param {Object} queryObject is the query sent by the auto-complete
 *                              component
 */
const queryWizardUnits = queryObject => {
  return (dispatch, getState, Api) => {
    dispatch(queryWizardUnitsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const query = {
      q: queryObject.value,
      empty: false
    };

    return communityModule
      .queryUnits(query)
      .then(response => {
        dispatch(queryWizardUnitsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryWizardUnitsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const setContractsPerUnits = data => {
  return (dispatch, getState, Api) => {
    dispatch(setContractsPerUnit(data));
    return Promise.resolve();
  };
};

export { types };
export default {
  retryIdentity,
  retryEmail,
  createWizardWithSpecificUnit,
  startCreateUserWizard,
  closeCreateUserWizard,
  nextInCreateWizard,
  backInCreateWizard,
  queryWizardUnits,
  clearWizardUnits,
  addWizardUnitToSelection,
  removeWizardUnitFromSelection,
  updateWizardSelectedUnitRelationship,
  setSelectedGuardProfile,
  setContractsPerUnits,
  openConfirmModal,
  closeCreateConfirmModal,
  closeConfirmModal,
  closeSuccessModalInSpecificUnit,
  openSuccessModalInSpecificUnit,
  continueWithUser
};
