import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';
import serializer from './serializer';

const initialState = {
  queryCommunicationsIsInProgress: false,
  queryCommunicationsHasFailed: false,
  updateCommunicationByIdIsInProgress: false,
  updateCommunicationByIdHasFailed: false,
  updateCommunicationByIdSuccess: false,
  deleteCommunicationByIdIsInProgress: false,
  deleteCommunicationByIdHasFailed: false,
  deleteCommunicationByIdSuccess: false,
  findCommunicationByIdIsInProgress: false,
  findCommunicationByIdHasFailed: false,
  createCommunicationIsInProgress: false,
  createCommunicationHasFailed: false,
  createCommunicationSucceded: false,
  uploadArticleFileIsInProgress: false,
  uploadArticleFileHasFailed: false,
  uploadArticleFileSucceded: false,
  articleFilePath: null,
  list: [],
  apiError: null,
  currentCommunication: null
};

const reducer = handleActions(
  {
    [types.queryCommunicationsSuccess]: (state, action) => {
      const communications = action.payload;
      const unserializedCommunications = communications.content.map(item =>
        serializer.unserializeCommunication(item)
      );

      return {
        ...state,
        queryCommunicationsIsInProgress: false,
        queryCommunicationsHasFailed: false,
        list: unserializedCommunications,
        apiError: null
      };
    },
    [types.queryCommunicationsFailed]: (state, action) => {
      return {
        ...state,
        queryCommunicationsIsInProgress: false,
        queryCommunicationsHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryCommunicationsInProgress]: state => {
      return {
        ...state,
        queryCommunicationsIsInProgress: true,
        queryCommunicationsHasFailed: false,
        apiError: null
      };
    },
    [types.findCommunicationByIdSuccess]: (state, action) => {
      return {
        ...state,
        findCommunicationByIdIsInProgress: false,
        findCommunicationByIdHasFailed: false,
        currentCommunication: serializer.unserializeCommunication(
          action.payload
        ),
        apiError: null
      };
    },
    [types.findCommunicationByIdIsInProgress]: state => {
      return {
        ...state,
        findCommunicationByIdIsInProgress: true,
        findCommunicationByIdHasFailed: false,
        apiError: null
      };
    },
    [types.findCommunicationByIdHasFailed]: (state, action) => {
      return {
        ...state,
        findCommunicationByIdIsInProgress: false,
        findCommunicationByIdHasFailed: true,
        currentCommunication: null,
        apiError: action.payload.apiError
      };
    },
    [types.deleteCommunicationByIdInProgress]: state => {
      return {
        ...state,
        deleteCommunicationByIdIsInProgress: true,
        deleteCommunicationByIdHasFailed: false,
        deleteCommunicationByIdSuccess: false,
        apiError: null
      };
    },
    [types.deleteCommunicationByIdSuccess]: (state, action) => {
      return {
        ...state,
        deleteCommunicationByIdIsInProgress: false,
        deleteCommunicationByIdHasFailed: false,
        deleteCommunicationByIdSuccess: true,
        apiError: null,
        list: state.list.filter(item => item.id !== action.payload)
      };
    },
    [types.deleteCommunicationByIdFailed]: (state, action) => {
      return {
        ...state,
        deleteCommunicationByIdIsInProgress: false,
        deleteCommunicationByIdHasFailed: true,
        deleteCommunicationByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.updateCommunicationByIdInProgress]: state => {
      return {
        ...state,
        updateCommunicationByIdIsInProgress: true,
        updateCommunicationByIdHasFailed: false,
        updateCommunicationByIdSuccess: false,
        apiError: null
      };
    },
    [types.updateCommunicationByIdSuccess]: state => {
      return {
        ...state,
        updateCommunicationByIdIsInProgress: false,
        updateCommunicationByIdHasFailed: false,
        updateCommunicationByIdSuccess: true,
        apiError: null,
        articleFilePath: null
      };
    },
    [types.updateCommunicationByIdFailed]: (state, action) => {
      return {
        ...state,
        updateCommunicationByIdIsInProgress: false,
        updateCommunicationByIdHasFailed: true,
        updateCommunicationByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.createCommunicationInProgress]: state => {
      return {
        ...state,
        createCommunicationIsInProgress: true,
        createCommunicationHasFailed: false,
        createCommunicationSucceded: false,
        apiError: null
      };
    },
    [types.createCommunicationSuccess]: state => {
      return {
        ...state,
        createCommunicationIsInProgress: false,
        createCommunicationHasFailed: false,
        createCommunicationSucceded: true,
        apiError: null
      };
    },
    [types.createCommunicationFailed]: (state, action) => {
      return {
        ...state,
        createCommunicationIsInProgress: false,
        createCommunicationHasFailed: true,
        createCommunicationSucceded: false,
        apiError: action.payload.apiError
      };
    },
    [types.uploadArticleFileInProgress]: state => {
      return {
        ...state,
        uploadArticleFileIsInProgress: true,
        uploadArticleFileHasFailed: false,
        uploadArticleFileSucceded: false
      };
    },
    [types.uploadArticleFileSuccess]: (state, action) => {
      return {
        ...state,
        uploadArticleFileIsInProgress: false,
        uploadArticleFileHasFailed: false,
        uploadArticleFileSucceded: true,
        articleFilePath: action.payload.fileUri
      };
    },
    [types.uploadArticleFileFailed]: state => {
      return {
        ...state,
        uploadArticleFileIsInProgress: false,
        uploadArticleFileHasFailed: true,
        uploadArticleFileSucceded: false
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
