import './config/reactotronConfig';
import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import configureStore, { history } from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-table/react-table.css';
import './styles/icons.css';
import './styles/styles.css';

import appConfig from './config/appConfig';
import Application from './modules/application';
import registerServiceWorker from './registerServiceWorker';

import 'react-datepicker/dist/react-datepicker.css';

const target = document.querySelector('#root');
const store = configureStore();

let app = document.getElementById('root');
if (app) {
  // 1. Set up the browser history with the updated location
  // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  // 2. Render our app

  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Application store={store} />
      </ConnectedRouter>
    </Provider>,
    target
  );
}

registerServiceWorker();
