import { handleActions } from 'redux-actions';
import { types } from './types';

const initialState = {
  list: [],
  amenity: null,
  findAmenitiesInProgress: false,
  findAmenitiesFailed: false,
  findAmenityByIdInProgress: false,
  findAmenityByIdFailed: false,
  newBook: null,
  bookingAmenityInProgress: false,
  bookingAmenityFailed: false,
  amenitiesBooked: [],
  unitsDefaultersList: [],
  findAmenitiesBookedFailed: false,
  findAmenitiesBookedInProgress: false,
  uploadAmenityFileInProgress: false,
  uploadAmenityFileFailed: false,
  uploadAmenityFileSuccess: false,
  cancelAmenityBookedInProgress: false,
  cancelAmenityBookedFailed: false,
  categories: [],
  getAmenitiesCategoriesInProgress: false,
  getAmenitiesCategoriesFailed: false,
  apiErrors: null,
  getBookingsInProgress: false,
  getBookingsFailed: false,
  bookings: [],
  creditsHistory: [],
  createAmenityCategoryInProgress: false,
  createAmenityCategoryFailed: false,
  createAmenityCategorySuccess: false,
  thumbnailPath: '',
  getCreditsHistoryInProgress: false,
  getCreditsHistoryFailed: false,
  getCreditsHistorySuccess: false,
  currentUnitBalance: null,
  getUnitBalanceInProgress: false,
  getUnitBalanceFailed: false,
  getUnitBalanceSuccess: false
};

const reducer = handleActions(
  {
    [types.getDefaultersListInProgress]: state => {
      return {
        ...state,
        getUnitsDefaultersInProgress: true,
        getUnitsDefaultersFailed: false,
        getUnitsDefaultersSuccess: false
      };
    },
    [types.getDefaulterListFailed]: (state, action) => {
      return {
        ...state,
        getUnitsDefaultersInProgress: false,
        getUnitsDefaultersFailed: true,
        getUnitsDefaultersSuccess: false,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.getDefaultersListSuccess]: (state, action) => {
      return {
        ...state,
        getUnitsDefaultersInProgress: false,
        getUnitsDefaultersFailed: false,
        getUnitsDefaultersSuccess: true,
        unitsDefaultersList: action.payload
      };
    },
    [types.getUnitBalanceInProgress]: state => {
      return {
        ...state,
        getUnitBalanceInProgress: true,
        getUnitBalanceFailed: false,
        getUnitBalanceSuccess: false
      };
    },
    [types.getUnitBalanceFailed]: (state, action) => {
      return {
        ...state,
        getUnitBalanceInProgress: false,
        getUnitBalanceFailed: true,
        getUnitBalanceSuccess: false,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.getUnitBalanceSuccess]: (state, action) => {
      return {
        ...state,
        getUnitBalanceInProgress: false,
        getUnitBalanceFailed: false,
        getUnitBalanceSuccess: true,
        currentUnitBalance: action.payload.balance
      };
    },
    [types.getCreditsAssignmentsInProgress]: state => {
      return {
        ...state,
        getCreditsHistoryInProgress: true,
        getCreditsHistoryFailed: false,
        getCreditsHistorySuccess: false
      };
    },
    [types.getCreditsAssignmentsFailed]: (state, action) => {
      return {
        ...state,
        getCreditsHistoryInProgress: false,
        getCreditsHistoryFailed: true,
        getCreditsHistorySuccess: false,
        creditsHistory: [],
        apiErrors: action.payload.apiErrors
      };
    },
    [types.getCreditsAssignmentsSuccess]: (state, action) => {
      return {
        ...state,
        getCreditsHistoryInProgress: false,
        getCreditsHistoryFailed: false,
        getCreditsHistorySuccess: true,
        creditsHistory: action.payload
      };
    },
    [types.createAmenityCategoryInProgress]: state => {
      return {
        ...state,
        createAmenityCategoryInProgress: true,
        createAmenityCategoryFailed: false,
        apiErrors: null
      };
    },
    [types.createAmenityCategoryFailed]: (state, action) => {
      return {
        ...state,
        createAmenityCategoryInProgress: false,
        createAmenityCategoryFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.createAmenityCategorySuccess]: (state, action) => {
      return {
        ...state,
        createAmenityCategoryInProgress: false,
        createAmenityCategoryFailed: false,
        apiErrors: null
      };
    },
    [types.getBookingsInProgress]: state => {
      return {
        ...state,
        getBookingsInProgress: true,
        getBookingsFailed: false,
        apiErrors: null
      };
    },
    [types.getBookingsFailed]: (state, action) => {
      return {
        ...state,
        getBookingsInProgress: false,
        getBookingsFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.getBookingsSuccess]: (state, action) => {
      return {
        ...state,
        bookings: action.payload,
        getBookingsInProgress: false,
        getBookingsFailed: false,
        apiErrors: null
      };
    },
    [types.getAmenitiesCategoriesInProgress]: state => {
      return {
        ...state,
        getAmenitiesCategoriesInProgress: true,
        getAmenitiesCategoriesFailed: false,
        apiErrors: null
      };
    },
    [types.getAmenitiesCategoriesFailed]: (state, action) => {
      return {
        ...state,
        getAmenitiesCategoriesInProgress: false,
        getAmenitiesCategoriesFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.getAmenitiesCategoriesSuccess]: (state, action) => {
      return {
        ...state,
        categories: action.payload,
        getAmenitiesCategoriesInProgress: false,
        getAmenitiesCategoriesFailed: false,
        apiErrors: null
      };
    },
    [types.findAmenitiesInProgress]: state => {
      return {
        ...state,
        findAmenitiesInProgress: true,
        findAmenitiesFailed: false,
        apiErrors: null
      };
    },
    [types.findAmenitiesFailed]: (state, action) => {
      return {
        ...state,
        findAmenitiesInProgress: false,
        findAmenitiesFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.findAmenitiesSuccess]: (state, action) => {
      return {
        ...state,
        list: action.payload,
        findAmenitiesInProgress: false,
        findAmenitiesFailed: false,
        apiErrors: null
      };
    },
    [types.findAmenityByIdInProgress]: state => {
      return {
        ...state,
        amenity: null,
        findAmenityByIdInProgress: true,
        findAmenityByIdFailed: false,
        apiErrors: null
      };
    },
    [types.findAmenityByIdFailed]: (state, action) => {
      return {
        ...state,
        findAmenityByIdInProgress: false,
        findAmenityByIdFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.findAmenityByIdSuccess]: (state, action) => {
      return {
        ...state,
        amenity: action.payload,
        findAmenityByIdInProgress: false,
        findAmenityByIdFailed: false,
        apiErrors: null
      };
    },

    [types.deleteAmenityByIdInProgress]: state => {
      return {
        ...state,
        deleteAmenityByIdInProgress: true,
        deleteAmenityByIdFailed: false,
        apiErrors: null
      };
    },
    [types.deleteAmenityByIdFailed]: (state, action) => {
      return {
        ...state,
        deleteAmenityByIdInProgress: false,
        deleteAmenityByIdFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.deleteAmenityByIdSuccess]: (state, action) => {
      return {
        ...state,
        deleteAmenityByIdInProgress: false,
        deleteAmenityByIdFailed: false,
        apiErrors: null
      };
    },
    [types.updateAmenityByIdInProgress]: state => {
      return {
        ...state,
        updateAmenityByIdInProgress: true,
        updateAmenityByIdFailed: false,
        apiErrors: null
      };
    },
    [types.updateAmenityByIdFailed]: (state, action) => {
      return {
        ...state,
        updateAmenityByIdInProgress: false,
        updateAmenityByIdFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.updateAmenityByIdSuccess]: (state, action) => {
      return {
        ...state,
        oneAmenity: action.payload.amenity,
        updateAmenityByIdInProgress: false,
        updateAmenityByIdFailed: false,
        apiErrors: null
      };
    },
    [types.createAmenityInProgress]: state => {
      return {
        ...state,
        createAmenityInProgress: true,
        createAmenityFailed: false,
        apiErrors: null
      };
    },
    [types.createAmenityFailed]: (state, action) => {
      return {
        ...state,
        createAmenityInProgress: false,
        createAmenityFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.createAmenitySuccess]: (state, action) => {
      return {
        ...state,
        oneAmenity: action.payload.amenity,
        createAmenityInProgress: false,
        createAmenityFailed: false,
        apiErrors: null
      };
    },

    [types.uploadAmenityFileInProgress]: state => {
      return {
        ...state,
        uploadAmenityFileInProgress: true,
        uploadAmenityFileFailed: false,
        apiErrors: null
      };
    },
    [types.uploadAmenityFileFailed]: (state, action) => {
      return {
        ...state,
        uploadAmenityFileInProgress: false,
        uploadAmenityFileFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.uploadAmenityFileSuccess]: (state, action) => {
      return {
        ...state,
        thumbnailPath: action.payload.id,
        uploadAmenityFileInProgress: false,
        uploadAmenityFileFailed: false,
        apiErrors: null
      };
    },

    [types.bookingAmenityInProgress]: state => {
      return {
        ...state,
        bookingAmenityInProgress: true,
        bookingAmenityFailed: false,
        apiErrors: null
      };
    },
    [types.bookingAmenityFailed]: (state, action) => {
      return {
        ...state,
        bookingAmenityInProgress: false,
        bookingAmenityFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.bookingAmenitySuccess]: (state, action) => {
      return {
        ...state,
        newBook: action.payload,
        bookingAmenityInProgress: false,
        bookingAmenityFailed: false,
        apiErrors: null
      };
    },
    [types.findAmenitiesBookedInProgress]: state => {
      return {
        ...state,
        findAmenitiesBookedInProgress: true,
        findAmenitiesBookedFailed: false,
        apiErrors: null
      };
    },
    [types.findAmenitiesBookedFailed]: (state, action) => {
      return {
        ...state,
        findAmenitiesBookedInProgress: false,
        findAmenitiesBookedFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.findAmenitiesBookedSuccess]: (state, action) => {
      return {
        ...state,
        amenitiesBooked: action.payload,
        findAmenitiesBookedInProgress: false,
        findAmenitiesBookedFailed: false,
        apiErrors: null
      };
    },
    [types.cancelAmenityBookedInProgress]: state => {
      return {
        ...state,
        cancelAmenityBookedInProgress: true,
        cancelAmenityBookedFailed: false,
        apiErrors: null
      };
    },
    [types.cancelAmenityBookedFailed]: (state, action) => {
      return {
        ...state,
        cancelAmenityBookedInProgress: false,
        cancelAmenityBookedFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.cancelAmenityBookedSuccess]: state => {
      return {
        ...state,
        amenitiesBooked: action.payload,
        cancelAmenityBookedInProgress: false,
        cancelAmenityBookedFailed: false,
        apiErrors: null
      };
    }
  },
  initialState
);

export default reducer;
