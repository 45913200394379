import { types } from './actions';

export const initialState = {
  step: null,
  uploadUsersFileIsInProgress: false,
  uploadUsersFileHasFailed: false,
  uploadUsersFileSuccess: false,
  jobId: null,
  startBulkUserCreationProcessIsInProgress: false,
  startBulkUserCreationProcessHasFailed: false,
  startBulkUserCreationProcessSuccess: false,
  apiError: null
};

const reducer = {
  [types.startBulkWizard]: state => {
    return {
      ...state,
      bulkWizard: {
        step: 'uploadFile'
      }
    };
  },
  [types.cancelBulkWizard]: state => {
    return {
      ...state,
      bulkWizard: initialState
    };
  },
  [types.uploadUsersFileInProgress]: state => {
    return {
      ...state,
      bulkWizard: {
        ...state.bulkWizard,
        uploadUsersFileIsInProgress: true,
        uploadUsersFileHasFailed: false,
        uploadUsersFileSuccess: false,
        jobId: null,
        apiError: null
      }
    };
  },
  [types.uploadUsersFileSuccess]: (state, action) => {
    return {
      ...state,
      bulkWizard: {
        ...state.bulkWizard,
        uploadUsersFileIsInProgress: false,
        uploadUsersFileHasFailed: false,
        uploadUsersFileSuccess: true,
        jobId: action.payload.fileUri,
        apiError: null
      }
    };
  },
  [types.startBulkUserCreationProcessFailed]: (state, action) => {
    return {
      ...state,
      bulkWizard: {
        ...state.bulkWizard,
        startBulkUserCreationProcessIsInProgress: false,
        startBulkUserCreationProcessHasFailed: true,
        startBulkUserCreationProcessSuccess: false,
        jobId: null,
        apiError: action.payload.apiError
      }
    };
  },
  [types.startBulkUserCreationProcessInProgress]: state => {
    return {
      ...state,
      bulkWizard: {
        ...state.bulkWizard,
        step: 'bulkCreation',
        startBulkUserCreationProcessIsInProgress: true,
        startBulkUserCreationProcessHasFailed: false,
        startBulkUserCreationProcessSuccess: false,
        apiError: null
      }
    };
  },
  [types.startBulkUserCreationProcessSuccess]: (state, action) => {
    return {
      ...state,
      bulkWizard: {
        ...state.bulkWizard,
        startBulkUserCreationProcessIsInProgress: false,
        startBulkUserCreationProcessHasFailed: false,
        startBulkUserCreationProcessSuccess: true,
        apiError: null
      }
    };
  },
  [types.uploadUsersFileFailed]: (state, action) => {
    return {
      ...state,
      bulkWizard: {
        ...state.bulkWizard,
        uploadUsersFileIsInProgress: false,
        uploadUsersFileHasFailed: true,
        uploadUsersFileSuccess: false,
        apiError: action.payload.apiError
      }
    };
  }
};

export default reducer;
