import uuid from 'uuid/v1';
import parseISO from 'date-fns/parseISO';
import AppConfig from '../../config/appConfig';

/**
 * Takes the article object representation and transforms it to what the
 * API expects.
 * @param {Object} communication
 */
function serializeArticle(communication, imageUri) {
  const publishedDate = communication.published
    ? new Date(communication.published)
    : new Date();

  if (communication.publishedTime) {
    publishedDate.setHours(communication.publishedTime.hours());
    publishedDate.setMinutes(communication.publishedTime.minutes());
  }

  const serializedSegments =
    communication.segments &&
    communication.segments.map(segment => {
      return { id: segment.value };
    });

  const serializedCommunication = {
    id: communication.id ? communication.id : uuid(),
    title: communication.title,
    brief: communication.brief,
    body: communication.body,
    image: imageUri
      ? AppConfig.getS3BucketUrlForResource(imageUri)
      : communication.imageUri,
    datePublished: publishedDate.toISOString(),
    segments: serializedSegments
  };

  return serializedCommunication;
}

/**
 * Takes the poll object representation and transforms it to what the
 * API expects.
 * @param {Object} communication
 */
function serializeOptions(options) {
  options.forEach((option, index) => {
    option.id = index;
  });
  return options;
}

/**
 * Takes the poll object representation and transforms it to what the
 * API expects.
 * @param {Object} communication
 */
function serializePoll(communication) {
  const publishedDate = communication.published
    ? parseISO(communication.published)
    : new Date();

  const serializedSegments =
    communication.segments &&
    communication.segments.map(segment => {
      return { id: segment.value };
    });

  const serializedCommunication = {
    id: communication.id ? communication.id : uuid(),
    title: communication.title,
    question: communication.question,
    options: serializeOptions(communication.options),
    dueDate: communication.dueDate,
    published: publishedDate.toISOString(),
    segments: serializedSegments,
    contextual: communication.contextual === 'true'
  };

  return serializedCommunication;
}

/**
 * Takes the poll object representation and transforms it to what the
 * API expects.
 * @param {Object} communication
 */
function unserializeCommunication(communication) {
  const serializedCommunication = {
    ...communication,
    contextual: communication.contextual ? 'true' : 'false'
  };

  return serializedCommunication;
}

/**
 * Takes the event object representation and transforms it to what the
 * API expects.
 * @param {Object} communication
 */
function serializeEvent(communication, imageUri) {
  const publishedDate = communication.published
    ? parseISO(communication.published)
    : new Date();

  if (communication.publishedTime) {
    publishedDate.setHours(communication.publishedTime.hours());
    publishedDate.setMinutes(communication.publishedTime.minutes());
  }

  const serializedSegments =
    communication.segments &&
    communication.segments.map(segment => {
      return { id: segment.value };
    });

  const serializedCommunication = {
    id: communication.id ? communication.id : uuid(),
    title: communication.title,
    brief: communication.brief,
    body: communication.body,
    image: communication.imageUri
      ? communication.imageUri
      : AppConfig.getS3BucketUrlForResource(imageUri),
    datePublished: publishedDate.toISOString(),
    segments: serializedSegments,
    date: communication.eventDate
  };

  return serializedCommunication;
}

/**
 * Takes the poll object representation and transforms it to what the
 * API expects.
 * @param {Object} communication
 */
function serializeQuiz(communication) {
  const publishedDate = communication.published
    ? parseISO(communication.published)
    : new Date();

  const serializedSegments =
    communication.segments &&
    communication.segments.map(segment => {
      return { id: segment.value };
    });

  const serializedCommunication = {
    id: communication.id ? communication.id : uuid(),
    title: communication.title,
    question: communication.question,
    options: serializeOptions(communication.options),
    points: communication.points,
    dueDate: communication.dueDate,
    published: publishedDate.toISOString(),
    segments: serializedSegments,
    contextual: communication.contextual === 'true'
  };

  return serializedCommunication;
}

export default {
  serializeArticle,
  serializePoll,
  unserializeCommunication,
  serializeEvent,
  serializeQuiz
};
