import { createAction } from 'redux-actions';

const types = {
  findCommunityByIdInProgress: 'findCommunityByIdInProgress',
  findCommunityByIdFail: 'findCommunityByIdFail',
  findCommunityByIdSuccess: 'findCommunityByIdSuccess',
  findCommunityGatesInProgress: 'findCommunityGatesInProgress',
  findCommunityGatesFail: 'findCommunityGatesFail',
  findCommunityGatesSuccess: 'findCommunityGatesSuccess'
};

const findCommunityByIdInProgress = createAction(
  types.findCommunityByIdInProgress
);
const findCommunityByIdFail = createAction(types.findCommunityByIdFail);
const findCommunityByIdSuccess = createAction(types.findCommunityByIdSuccess);
const findCommunityGatesInProgress = createAction(
  types.findCommunityGatesInProgress
);
const findCommunityGatesFail = createAction(types.findCommunityGatesFail);
const findCommunityGatesSuccess = createAction(types.findCommunityGatesSuccess);

const findCommunityById = id => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = null;

    const communityId = id || getState().session.profile.community.id;

    dispatch(findCommunityByIdInProgress());

    return communityModule
      .findCommunityById(communityId)
      .then(communityData => {
        dispatch(findCommunityByIdSuccess(communityData));
      })
      .catch(error => {
        dispatch(
          findCommunityByIdFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findCommunityGates = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(findCommunityGatesInProgress());

    return communityModule
      .findCommunityGates()
      .then(communityData => {
        dispatch(findCommunityGatesSuccess(communityData));
      })
      .catch(error => {
        dispatch(
          findCommunityGatesFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  types,
  findCommunityById,
  findCommunityGates
};
