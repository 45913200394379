import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';

const initialState = {
  querySegmentsIsInProgress: false,
  querySegmentsHasFailed: false,
  updateSegmentByIdIsInProgress: false,
  updateSegmentByIdHasFailed: false,
  updateSegmentByIdSuccess: false,
  deleteSegmentByIdIsInProgress: false,
  deleteSegmentByIdHasFailed: false,
  deleteSegmentByIdSuccess: false,
  findSegmentByIdIsInProgress: false,
  findSegmentByIdHasFailed: false,
  createSegmentIsInProgress: false,
  createSegmentHasFailed: false,
  createSegmentSucceded: false,
  list: [],
  apiError: null,
  currentSegment: null,
  filters: [],
  findFiltersInProgress: false,
  findFiltersSuccess: false,
  findFiltersFailed: false
};

const reducer = handleActions(
  {
    [types.querySegmentsSuccess]: (state, action) => {
      const segments = action.payload;
      return {
        ...state,
        querySegmentsIsInProgress: false,
        querySegmentsHasFailed: false,
        list: segments,
        apiError: null
      };
    },
    [types.querySegmentsFailed]: (state, action) => {
      return {
        ...state,
        querySegmentsIsInProgress: false,
        querySegmentsHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.querySegmentsInProgress]: state => {
      return {
        ...state,
        querySegmentsIsInProgress: true,
        querySegmentsHasFailed: false,
        apiError: null
      };
    },
    [types.findSegmentByIdSuccess]: (state, action) => {
      return {
        ...state,
        findSegmentByIdIsInProgress: false,
        findSegmentByIdHasFailed: false,
        currentSegment: action.payload,
        apiError: null
      };
    },
    [types.findSegmentByIdIsInProgress]: state => {
      return {
        ...state,
        findSegmentByIdIsInProgress: true,
        findSegmentByIdHasFailed: false,
        apiError: null
      };
    },
    [types.findSegmentByIdHasFailed]: (state, action) => {
      return {
        ...state,
        findSegmentByIdIsInProgress: false,
        findSegmentByIdHasFailed: true,
        currentSegment: null,
        apiError: action.payload.apiError
      };
    },
    [types.deleteSegmentByIdInProgress]: state => {
      return {
        ...state,
        deleteSegmentByIdIsInProgress: true,
        deleteSegmentByIdHasFailed: false,
        deleteSegmentByIdSuccess: false,
        apiError: null
      };
    },
    [types.deleteSegmentByIdSuccess]: (state, action) => {
      return {
        ...state,
        deleteSegmentByIdIsInProgress: false,
        deleteSegmentByIdHasFailed: false,
        deleteSegmentByIdSuccess: true,
        apiError: null,
        list: state.list.filter(item => item.id !== action.payload)
      };
    },
    [types.deleteSegmentByIdFailed]: (state, action) => {
      return {
        ...state,
        deleteSegmentByIdIsInProgress: false,
        deleteSegmentByIdHasFailed: true,
        deleteSegmentByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.updateSegmentByIdInProgress]: state => {
      return {
        ...state,
        updateSegmentByIdIsInProgress: true,
        updateSegmentByIdHasFailed: false,
        updateSegmentByIdSuccess: false,
        apiError: null
      };
    },
    [types.updateSegmentByIdSuccess]: state => {
      return {
        ...state,
        updateSegmentByIdIsInProgress: false,
        updateSegmentByIdHasFailed: false,
        updateSegmentByIdSuccess: true,
        apiError: null
      };
    },
    [types.updateSegmentByIdFailed]: (state, action) => {
      return {
        ...state,
        updateSegmentByIdIsInProgress: false,
        updateSegmentByIdHasFailed: true,
        updateSegmentByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.createSegmentInProgress]: state => {
      return {
        ...state,
        createSegmentIsInProgress: true,
        createSegmentHasFailed: false,
        createSegmentSucceded: false,
        apiError: null
      };
    },
    [types.createSegmentSuccess]: state => {
      return {
        ...state,
        createSegmentIsInProgress: false,
        createSegmentHasFailed: false,
        createSegmentSucceded: true,
        apiError: null
      };
    },
    [types.createSegmentFailed]: (state, action) => {
      return {
        ...state,
        createSegmentIsInProgress: false,
        createSegmentHasFailed: true,
        createSegmentSucceded: false,
        apiError: action.payload.apiError
      };
    },
    [types.findAvailableFiltersInProgress]: state => {
      return {
        ...state,
        findFiltersInProgress: true,
        findFiltersFailed: false,
        findFiltersSuccess: false
      };
    },
    [types.findAvailableFiltersSuccess]: (state, action) => {
      return {
        ...state,
        findFiltersInProgress: false,
        findFiltersFailed: false,
        findFiltersSuccess: true,
        filters: action.payload.filters
      };
    },
    [types.findAvailableFiltersFailed]: (state, action) => {
      return {
        ...state,
        findFiltersInProgress: false,
        findFiltersFailed: true,
        findFiltersSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
