import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';
import { getResidentProfiles, getSecurityProfiles } from '../users/selectors';
import { action } from '@storybook/addon-actions';

const initialState = {
  searchUsersIsInProgress: false,
  searchUsersHasFailed: false,
  searchContractUsersIsInProgress: false,
  searchContractUsersHasFailed: false,
  list: [],
  usersList: [],
  unitsList: [],
  contractUserList: [],
  securityList: [],
  totalPages: 0,
  currentPage: 0,
  apiError: null
};

const reducer = handleActions(
  {
    [types.searchUnitsSuccess]: (state, action) => {
      const result = action.payload.content;
      return {
        ...state,
        unitsList: result
      };
    },
    [types.searchUsersSuccess]: (state, action) => {
      const individuals = action.payload.individuals;
      const allResults = individuals.content;
      const users = allResults.filter(individual => {
        const userProfiles = getResidentProfiles(individual.profiles);
        return userProfiles.length > 0;
      });
      const guards = allResults.filter(individual => {
        const securityProfiles = getSecurityProfiles(individual.profiles);
        return securityProfiles.length > 0;
      });
      return {
        ...state,
        searchUsersIsInProgress: false,
        searchUsersHasFailed: false,
        list: allResults,
        usersList: users,
        securityList: guards,
        totalPages: individuals.totalPages,
        currentPage: individuals.number,
        apiError: null
      };
    },
    [types.searchUsersFailed]: (state, action) => {
      return {
        ...state,
        searchUsersIsInProgress: false,
        searchUsersHasFailed: true,
        list: [],
        usersList: [],
        securityList: [],
        totalPages: 0,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.searchUsersInProgress]: state => {
      return {
        ...state,
        searchUsersIsInProgress: true,
        searchUsersHasFailed: false,
        totalPages: 0,
        currentPage: 0,
        apiError: null
      };
    },
    [types.clearUsersSearch]: () => {
      return initialState;
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    },

    [types.searchContractUsersSuccess]: (state, action) => {
      const individuals = action.payload.individuals;
      const allResults = individuals.content;

      const users = allResults.filter(individual => {
        const userProfiles = getResidentProfiles(individual.profiles);
        return userProfiles.length > 0;
      });

      return {
        ...state,
        searchContractUsersIsInProgress: false,
        searchContractUsersHasFailed: false,
        list: allResults,
        contractUserList: users,
        totalPages: individuals.totalPages,
        currentPage: individuals.number,
        apiError: null
      };
    },
    [types.searchContractUsersFailed]: (state, action) => {
      return {
        ...state,
        searchContractUsersIsInProgress: false,
        searchContractUsersHasFailed: true,
        list: [],
        usersList: [],
        securityList: [],
        totalPages: 0,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.searchContractUsersInProgress]: state => {
      return {
        ...state,
        searchContractUsersIsInProgress: true,
        searchContractUsersHasFailed: false,
        list: [],
        usersList: [],
        securityList: [],
        totalPages: 0,
        currentPage: 0,
        apiError: null
      };
    }
  },
  initialState
);

export default reducer;
