import { createAction } from 'redux-actions';

const types = {
  findNationalitiesInProgress: 'findNationalitiesInProgress',
  findNationalitiesFail: 'findNationalitiesFail',
  findNationalitiesSuccess: 'findNationalitiesSuccess'
};

const findNationalitiesInProgress = createAction(
  types.findNationalitiesInProgress
);
const findNationalitiesFail = createAction(types.findNationalitiesFail);
const findNationalitiesSuccess = createAction(types.findNationalitiesSuccess);

const findNationalities = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = null;

    dispatch(findNationalitiesInProgress());

    return communityModule
      .findNationalities()
      .then(countries => {
        dispatch(findNationalitiesSuccess(countries));
      })
      .catch(error => {
        dispatch(
          findNationalitiesFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  types,
  findNationalities
};
