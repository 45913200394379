import utc from '../../services/utc';
import get from 'lodash/get';
/**
 * Transforms the stored user in the redux store
 * into a suitable representation to send to API.
 * Caveats:
 * - User Update API expects a personalId object
 * - User Create API expects idType and idNumber fields
 * - User update API expects email as newEmail field
 * - User create expects email as email field
 */
export function serialize(
  storedUser,
  userType,
  assignedUnits,
  guardProfileType,
  contractsPerUnits
) {
  const capitalizeFirstLetter = string => {
    String.prototype.capitalize = function(lower) {
      return (lower ? this.toLowerCase() : this).replace(
        /(?:^|\s|['`‘’.-])[^\x00-\x60^\x7B-\xDF](?!(\s|$))/g,
        function(a) {
          return a.toUpperCase();
        }
      );
    };

    return string.capitalize(true);
  };

  const user = {
    birthDate: utc(storedUser.birthDate),
    email: get(storedUser, 'accountInfo.email') || storedUser.email,
    newEmail: get(storedUser, 'accountInfo.email') || storedUser.email,
    firstName: capitalizeFirstLetter(storedUser.firstName),
    lastName: capitalizeFirstLetter(storedUser.lastName),
    gender: storedUser.gender,
    idType: storedUser.personalId.idType,
    idNumber: storedUser.personalId.number,
    personalId: storedUser.personalId,
    phoneNumber: storedUser.phoneNumber,
    nationality: storedUser.nationality,
    contractsPerUnits
  };
  if (storedUser.id != null) {
    user.id = storedUser.id;
  }
  if (storedUser.phone != null) {
    user.phone = storedUser.phone;
  }
  if (userType === 'resident' && assignedUnits != null) {
    user.profiles = assignedUnits.map(unit => {
      return {
        profile: unit.relationship,
        lot: unit.lotNumber
      };
    });
    user.contracts = contractsPerUnits;
  } else {
    if (userType === 'guard') {
      user.profiles = [
        {
          profile: guardProfileType
        }
      ];
      user.contracts = [];
    }
  }
  return user;
}
