import isString from 'lodash/isString';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import { isEmpty, sortBy } from 'lodash';
import {
  contractStatus,
  contractTypes,
  profiles
} from '../../constants/contracts';
import {
  differenceInMinutes,
  setHours,
  setMinutes,
  setSeconds
} from 'date-fns';
import { isArray } from 'lodash';
import { individualStatusTypes } from '../../constants/units';

const today = setHours(setMinutes(setSeconds(new Date(), 0), 0), 0);

export function getUnits(state) {
  return state.units.list.map(unit => {
    return {
      ...unit
    };
  });
}

export function isQueryUnitsInProgress(state) {
  return state.units.queryUnitsIsInProgress;
}

export function getQueryUnitsTotalPages(state) {
  return state.units.totalPages;
}

export function getQueryUnitsCurrentPage(state) {
  return state.units.currentPage;
}

export function getUnitById(state, id) {
  let unitId = id;
  if (isString(id)) {
    unitId = parseInt(id, 10);
  }
  const units = state.list;
  const result = units.filter(unit => unit.id === unitId);
  return result.length > 0 ? result[0] : null;
}

export function getCurrentUnit(state) {
  return state.units.currentUnit;
}

export function isDeleteUnitByIdInProgress(state) {
  return state.units.deleteUnitByIdIsInProgress;
}

export function getUnitOwners(unit) {
  if (!unit || isEmpty(unit) || !unit?.contracts?.length) {
    return [];
  } else {
    const ownersGroups = unit.individualGroups.filter(
      group => group.type === profiles.OWNER
    );

    const filteredContract = unit.contracts.filter(
      contract =>
        contract.status === contractStatus.VALID &&
        contract.type === contractTypes.PROPERTY_TITLE
    );

    const contractOwners = filteredContract
      ? filteredContract.map(e => e.ownersId).flat(2)
      : [];

    const individualOfContract = ownersGroups.find(group =>
      group.members.find(member => member.individual.id === contractOwners[0])
    );

    return individualOfContract
      ? individualOfContract.members
          .filter(
            member => member.individual.profiles[0].type === profiles.OWNER
          )
          .map(member => member.individual)
      : [];
  }
}

export function getRentedTotalPages(state) {
  return state.units.rentedUnitsTotalPages;
}

export function getRentedUnits(state) {
  return state.units.rentedUnits;
}

export function getAssignedUsersToUnit(unit) {
  if (isEmpty(unit)) {
    return [];
  }
  const unitGroup = unit.individualGroups
    .map(individual => individual.members)
    .flat(2);

  return unitGroup.map(member => member.individual);
}

export function getActiveRentalContract(contracts) {
  return contracts.find(
    contract =>
      contract.status === contractStatus.VALID &&
      contract.type !== contractTypes.PROPERTY_TITLE
  );
}

function getActivePropTitleContract(contracts) {
  return contracts.find(
    contract =>
      contract.status === contractStatus.VALID &&
      contract.type === contractTypes.PROPERTY_TITLE
  );
}

export function getAllContractResidents(unit, contracts) {
  const ownersAndCoresidents = getContractOwnersAndCoresidents(unit);
  const tenantsAndCoresidents = getContractTenantsAndCoresidents(unit);

  return {
    ...ownersAndCoresidents,
    ...tenantsAndCoresidents
  };
}

export function getAllIndividualsOnUnit(unit) {
  return unit.individualGroups
    .map(group => group.members.map(member => member.individual))
    .flat(2);
}

export function getDatesOfValidContracts(unit, contracts) {
  if (!isEmpty(contracts)) {
    const sortedContracts = sortBy(contracts, ['startDate']);
    return sortedContracts
      .filter(
        Contract =>
          Contract.type != contractTypes.PROPERTY_TITLE &&
          (Contract.status == contractStatus.CREATED ||
            Contract.status == contractStatus.VALID)
      )
      .map(contract => {
        return {
          contractId: contract.id,
          start: new Date(contract.startDate),
          end: new Date(contract.endDate)
        };
      });
  } else if (!isEmpty(unit?.contracts)) {
    const sortedContracts = sortBy(unit.contracts, ['startDate']);

    return sortedContracts
      .filter(
        Contract =>
          Contract.type != contractTypes.PROPERTY_TITLE &&
          (Contract.status == contractStatus.CREATED ||
            Contract.status == contractStatus.VALID)
      )
      .map(contract => {
        return {
          contractId: contract.id,
          start: new Date(contract.startDate),
          end: new Date(contract.endDate)
        };
      });
  } else {
    return [];
  }
}

export function getContractTenantsAndCoresidents(unit, contracts) {
  if (!unit || isEmpty(unit) || isEmpty(contracts)) {
    return {
      petsOfTenants: [],
      tenants: [],
      tenantsCoresidents: [],
      tenantsIndividualEnabled: false,
      tenantsAndCoresidentsOutOfContract: [],
      tenantsContract: false,
      allContractTenantMembers: [],
      vehiclesOfTenants: [],
      tenantsGroupId: false
    };
  } else {
    const activeRentalContract = isArray(contracts)
      ? getActiveRentalContract(contracts)
      : contracts;

    const individualOfContract = unit.individualGroups.find(
      group => group.contractId === activeRentalContract?.id
    );

    const tenantsAndCoresidents = individualOfContract?.members;

    if (isEmpty(tenantsAndCoresidents)) {
      return {
        petsOfTenants: [],
        tenants: [],
        tenantsCoresidents: [],
        tenantsIndividualEnabled: false,
        tenantsAndCoresidentsOutOfContract: [],
        tenantsContract: false,
        allContractTenantMembers: [],
        vehiclesOfTenants: [],
        tenantsGroupId: false
      };
    }

    const petsOfTenants = individualOfContract.pets;

    const vehiclesOfTenants = individualOfContract.vehicles;

    return {
      tenantsGroupId: individualOfContract.id,
      vehiclesOfTenants,
      petsOfTenants,
      tenants: tenantsAndCoresidents
        .filter(member =>
          activeRentalContract.tenantsId.includes(member.individual.id)
        )
        .map(member => ({
          ...member.individual,
          relationship: member.relationship,
          invitationCode: member.invitationCode
        })),

      tenantsCoresidents: tenantsAndCoresidents
        .filter(member =>
          activeRentalContract.coresidentsId.includes(member.individual.id)
        )
        .map(member => ({
          ...member.individual,
          relationship: member.relationship,
          invitationCode: member.invitationCode
        })),

      tenantsIndividualEnabled:
        individualOfContract.status == individualStatusTypes.ENABLED,

      tenantsAndCoresidentsOutOfContract: tenantsAndCoresidents
        .filter(
          member =>
            !activeRentalContract.coresidentsId.includes(
              member.individual.id
            ) && !activeRentalContract.tenantsId.includes(member.individual.id)
        )
        .map(member => ({
          ...member.individual,
          relationship: member.relationship,
          invitationCode: member.invitationCode
        })),
      tenantsContract: activeRentalContract,
      allContractTenantMembers: tenantsAndCoresidents.map(member => ({
        ...member.individual,
        relationship: member.relationship,
        invitationCode: member.invitationCode
      }))
    };
  }
}

export function getContractOwnersAndCoresidents(unit, contracts) {
  if (!unit || isEmpty(unit) || isEmpty(contracts)) {
    return {
      owners: [],
      ownersCoresidents: [],
      ownersIndividualEnabled: false,
      ownersAndCoresidentsOutOfContract: [],
      ownersContract: false,
      petsOfOwners: [],
      vehiclesOfOwners: [],
      allContractOwnerMembers: []
    };
  } else {
    const activePropTitleContract = isArray(contracts)
      ? getActivePropTitleContract(contracts)
      : contracts;
    const individualOfContract = unit.individualGroups.find(
      group => group.contractId === activePropTitleContract?.id
    );

    const ownersAndCoresidents = individualOfContract?.members;

    if (isEmpty(ownersAndCoresidents)) {
      return {
        owners: [],
        ownersCoresidents: [],
        ownersIndividualEnabled: false,
        ownersAndCoresidentsOutOfContract: [],
        ownersContract: false,
        petsOfOwners: [],
        vehiclesOfOwners: [],
        allContractOwnerMembers: []
      };
    }

    const petsOfOwners = individualOfContract.pets;

    const vehiclesOfOwners = individualOfContract.vehicles;

    return {
      vehiclesOfOwners,
      owners: ownersAndCoresidents
        .filter(member =>
          activePropTitleContract.ownersId.includes(member.individual.id)
        )
        .map(member => ({
          ...member.individual,
          relationship: member.relationship,
          invitationCode: member.invitationCode
        })),
      ownersCoresidents: ownersAndCoresidents
        .filter(member =>
          activePropTitleContract.coresidentsId.includes(member.individual.id)
        )
        .map(member => ({
          ...member.individual,
          relationship: member.relationship,
          invitationCode: member.invitationCode
        })),

      ownersIndividualEnabled:
        individualOfContract.status == individualStatusTypes.ENABLED,

      ownersAndCoresidentsOutOfContract: ownersAndCoresidents
        .filter(
          member =>
            !activePropTitleContract.coresidentsId.includes(
              member.individual.id
            ) &&
            !activePropTitleContract.ownersId.includes(member.individual.id)
        )
        .map(member => ({
          ...member.individual,
          relationship: member.relationship,
          invitationCode: member.invitationCode
        })),
      ownersContract: activePropTitleContract,
      petsOfOwners,
      allContractOwnerMembers: ownersAndCoresidents.map(member => ({
        ...member.individual,
        relationship: member.relationship,
        invitationCode: member.invitationCode
      }))
    };
  }
}

export function getSpecificContractUnitOwners(unit, contract) {
  if (!contract || isEmpty(contract) || !unit || isEmpty(unit)) {
    return [];
  } else {
    const ownersGroups = unit.individualGroups.filter(
      group => group.type === profiles.OWNER
    );

    const individualOfContract = ownersGroups.find(group =>
      group.members.find(
        member => member.individual.id === contract.ownersId[0]
      )
    );

    const response = individualOfContract.members
      .filter(member => contract.ownersId.includes(member.individual.id))
      .map(member => member.individual);

    return response;
  }
}

export function getSpecificContractUnitTenants(unit, contract) {
  if (!contract || isEmpty(contract) || !unit || isEmpty(unit)) {
    return [];
  } else {
    const tenantsGroups = unit.individualGroups.filter(
      group => group.type === profiles.TENANT
    );

    const individualOfContract = tenantsGroups.find(group =>
      group.members.find(
        member => member.individual.id === contract.tenantsId[0]
      )
    );

    const response = individualOfContract.members
      .filter(member => contract.tenantsId.includes(member.individual.id))
      .map(member => member.individual);

    return response;
  }
}

export function getContractUnitTenants(unit, contracts) {
  if (!contracts || isEmpty(contracts) || !unit || isEmpty(unit)) {
    return [];
  } else {
    const tenantsGroups = unit.individualGroups.filter(
      group => group.type === profiles.TENANT
    );

    if (!contracts || isEmpty(contracts)) {
      return;
    } else {
      const filteredContract = contracts.filter(
        contract =>
          contract.status === contractStatus.VALID &&
          contract.type != contractTypes.PROPERTY_TITLE
      );

      const contractTenants = filteredContract
        ? filteredContract.map(contract => contract.tenantsId).flat(2)
        : [];

      const individualOfContract = tenantsGroups.find(group =>
        group.members.find(
          member => member.individual.id === contractTenants[0]
        )
      );

      return individualOfContract
        ? individualOfContract.members.filter(
            member => member.individual.profiles[0].type === profiles.TENANT
          )
        : [];
    }
  }
}

export function getResidentsOfNextPropContractToStart(unit, contracts) {
  //gives the closest contract to start
  if (isEmpty(unit) || isEmpty(contracts)) {
    return {
      residentsOfNextPropContracToStart: [],
      propContractStartDate: false,
      nextPropContracToStart: false
    };
  }

  let i = 0;
  let flag = 0;
  let closestContract = {};

  const filteredContracts = contracts.filter(
    contract =>
      contract.status === contractStatus.CREATED &&
      contract.type === contractTypes.PROPERTY_TITLE
  );

  flag = Math.abs(
    differenceInMinutes(today, new Date(filteredContracts[0]?.startDate))
  );
  closestContract = filteredContracts[0];

  for (i = 0; i < filteredContracts.length; i++) {
    if (
      Math.abs(
        differenceInMinutes(today, new Date(filteredContracts[i]?.startDate))
      ) <= flag
    ) {
      flag = Math.abs(
        differenceInMinutes(today, new Date(filteredContracts[i]?.startDate))
      );
      closestContract = filteredContracts[i];
    }
  }

  const individualOfContract = unit.individualGroups.find(
    group => group.contractId === closestContract?.id
  );

  if (isEmpty(individualOfContract)) {
    return {
      residentsOfNextPropContracToStart: [],
      propContractStartDate: false,
      nextPropContracToStart: false
    };
  }

  return {
    residentsOfNextPropContracToStart: individualOfContract.members.map(
      member => ({
        ...member.individual,
        relationship: member.relationship,
        invitationCode: member.invitationCode
      })
    ),
    propContractStartDate: closestContract?.startDate,
    nextPropContracToStart: closestContract
  };
}

export function getResidentsOfNextRentalContractToStart(unit, contracts) {
  //gives the closest contract to start
  if (isEmpty(unit) || isEmpty(contracts)) {
    return {
      residentsOfNextRentalContracToStart: [],
      rentalContracType: false,
      rentalContractStartDate: false,
      rentalContractEndDate: false,
      nextTenantsGroupId: false
    };
  }

  let i = 0;
  let flag = 0;
  let closestContract = {};

  const filteredContracts = contracts.filter(
    contract =>
      contract.status === contractStatus.CREATED &&
      contract.type !== contractTypes.PROPERTY_TITLE
  );

  flag = Math.abs(
    differenceInMinutes(today, new Date(filteredContracts[0]?.startDate))
  );
  closestContract = filteredContracts[0];

  for (i = 0; i < filteredContracts.length; i++) {
    if (
      Math.abs(
        differenceInMinutes(today, new Date(filteredContracts[i]?.startDate))
      ) <= flag
    ) {
      flag = Math.abs(
        differenceInMinutes(today, new Date(filteredContracts[i]?.startDate))
      );
      closestContract = filteredContracts[i];
    }
  }

  const individualOfContract = unit.individualGroups.find(
    group => group.contractId === closestContract?.id
  );

  if (isEmpty(individualOfContract)) {
    return {
      residentsOfNextRentalContracToStart: [],
      rentalContracType: false,
      rentalContractStartDate: false,
      rentalContractEndDate: false,
      nextRentalContracToStart: false,
      nextTenantsGroupId: false
    };
  }

  return {
    nextTenantsGroupId: individualOfContract.id,
    residentsOfNextRentalContracToStart: individualOfContract.members.map(
      member => ({
        ...member.individual,
        relationship: member.relationship,
        invitationCode: member.invitationCode
      })
    ),
    rentalContractStartDate: closestContract?.startDate,
    rentalContractEndDate:
      closestContract?.type != contractTypes.PROPERTY_TITLE
        ? closestContract?.endDate
        : false,
    rentalContracType: closestContract?.type,
    nextRentalContracToStart: closestContract
  };
}

export function getNextRentalContractToStart(unit) {
  //gives the closest contract to start
  if (!unit || unit == undefined) {
    return null;
  }

  let i = 0;
  let flag = 0;
  let closestContract = {};

  const filteredContracts = unit?.contracts.filter(
    contract =>
      contract.status === contractStatus.CREATED &&
      contract.type != contractTypes.PROPERTY_TITLE
  );

  flag = differenceInMinutes(today, new Date(filteredContracts[0]?.startDate));
  closestContract = filteredContracts[0];

  for (i = 0; i < filteredContracts.length; i++) {
    if (
      differenceInMinutes(today, new Date(filteredContracts[i]?.startDate)) <=
      flag
    ) {
      flag = differenceInMinutes(
        today,
        new Date(filteredContracts[i]?.startDate)
      );
      closestContract = filteredContracts[i];
    }
  }

  return closestContract;
}

export function getContractUnitTenant(unit, contracts) {
  if (!contracts || isEmpty(contracts) || !unit || isEmpty(unit)) {
    return [];
  } else {
    const tenantsGroups = unit.individualGroups.filter(
      group => group.type === profiles.TENANT
    );

    if (!contracts || isEmpty(contracts)) {
      return;
    } else {
      const filteredContract = contracts.filter(
        contract =>
          contract.status === contractStatus.VALID &&
          contract.type != contractTypes.PROPERTY_TITLE
      );

      const contractTenants = filteredContract
        ? filteredContract.map(contract => contract.tenantsId).flat(2)
        : [];

      const individualOfContract = tenantsGroups.find(group =>
        group.members.find(
          member => member.individual.id === contractTenants[0]
        )
      );

      return individualOfContract
        ? individualOfContract.members.filter(
            member => member.individual.profiles[0].type === profiles.TENANT
          )
        : [];
    }
  }
}

export function getContractUnitOwnerResidents(unit, contracts) {
  if (!contracts || isEmpty(contracts) || !unit || isEmpty(unit)) {
    return [];
  } else {
    const ownersGroups = unit.individualGroups.filter(
      group => group.type === profiles.OWNER
    );

    if (!contracts || isEmpty(contracts)) {
      return;
    } else {
      const filteredContract = contracts.filter(
        contract =>
          contract.status === contractStatus.VALID &&
          contract.type === contractTypes.PROPERTY_TITLE
      );

      const contractOwners = filteredContract
        ? filteredContract.map(e => e.coresidentsId).flat(2)
        : [];

      const individualOfContract = ownersGroups.find(group =>
        group.members.find(member => member.individual.id === contractOwners[0])
      );

      return individualOfContract
        ? individualOfContract.members.filter(
            member => member.relationship === 'OTHER'
          )
        : [];
    }
  }
}

export function getContractTenantResidents(unit, contracts) {
  if (!contracts || isEmpty(contracts) || !unit || isEmpty(unit)) {
    return [];
  } else {
    const tenantsGroups = unit.individualGroups.filter(
      group => group.type === profiles.TENANT
    );

    if (!contracts || isEmpty(contracts)) {
      return;
    } else {
      const filteredContract = contracts.filter(
        contract =>
          contract.status === contractStatus.VALID &&
          contract.type != contractTypes.PROPERTY_TITLE
      );

      const contractTenants = filteredContract
        ? filteredContract.map(contract => contract.coresidentsId).flat(2)
        : [];

      const individualOfContract = tenantsGroups.find(group =>
        group.members.find(
          member => member.individual.id === contractTenants[0]
        )
      );

      return individualOfContract
        ? individualOfContract.members.filter(
            member => member.relationship === 'OTHER'
          )
        : [];
    }
  }
}

export function hasCreateUnitFailed(state) {
  return state.units.createUnitHasFailed;
}

export function isDuplicatedLotError(state) {
  const apiErrors = state.units.apiError;
  const duplicatedError = find(apiErrors, ['code', 'resourceAlreadyExists']);
  return duplicatedError != null;
}

export function getCurrentUnitCoordinates(state) {
  const currentCoordinates = state.units.currentUnit.location;
  return currentCoordinates
    ? currentCoordinates
    : {
        latitude: 0,
        longitude: 0
      };
}

export function getUpdatedCurrentUnitCoordinates(state) {
  const coordinates = getCurrentUnitCoordinates(state);
  const updatedCoordinates = state.units.updatedCurrentUnitCoordinates;
  if (updatedCoordinates.latitude === 0 && updatedCoordinates.longitude === 0) {
    return coordinates;
  } else {
    return updatedCoordinates;
  }
}

export function isUnitCoordinatesAlreadySet(state) {
  const updatedCoordinates = getUpdatedCurrentUnitCoordinates(state);
  const location = getCurrentUnitCoordinates(state);
  return (
    (updatedCoordinates.latitude !== 0 && updatedCoordinates.longitude !== 0) ||
    (location.latitude !== 0 && location.longitude)
  );
}

export function getUnitVehiclesForTable(unit) {
  const vehicles = unit.individualGroups.map(group => group.vehicles);
  return flatten(vehicles);
}

export function getValidateContractState(state) {
  return state.contracts.validatedContract;
}

export function findUnitByIdIsInProgress(state) {
  return state.units.findUnitByIdIsInProgress;
}
