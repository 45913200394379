import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import { getUnitById } from './selectors';
import { serialize } from './serializer';
import { isEmpty } from 'lodash';
import userActions from '../users/actions';
import { normalProfileTypes } from '../../constants/profileTypes';
const types = {
  queryRentedContractsInProgress: 'queryRentedContractsInProgress',
  queryRentedContractsSuccess: 'queryRentedContractsSuccess',
  queryRentedContractsFail: 'queryRentedContractsFail',
  queryMultipleUnitsSuccess: 'queryMultipleUnitsSuccess',
  queryMultipleUnitsInProgress: 'queryMultipleUnitsInProgress',
  queryMultipleUnitsFailed: 'queryMultipleUnitsFailed',
  queryUnitsSuccess: 'queryUnitsSuccess',
  queryUnitsInProgress: 'queryUnitsInProgress',
  queryUnitsFailed: 'queryUnitsFailed',
  findUnitByIdSuccess: 'findUnitByIdSuccess',
  findUnitByIdInProgress: 'findUnitByIdInProgress',
  findUnitByIdFailed: 'findUnitByIdFailed',
  updateUnitByIdSuccess: 'updateUnitByIdSuccess',
  updateUnitByIdInProgress: 'updateUnitByIdInProgress',
  updateUnitByIdFailed: 'updateUnitByIdFailed',
  deleteUnitByIdSuccess: 'deleteUnitByIdSuccess',
  deleteUnitByIdInProgress: 'deleteUnitByIdInProgress',
  deleteUnitByIdFailed: 'deleteUnitByIdFailed',
  createUnitSuccess: 'createUnitSuccess',
  createUnitInProgress: 'createUnitInProgress',
  createUnitFailed: 'createUnitFailed',
  setCurrentUnitCoordinates: 'setCurrentUnitCoordinates'
};

const queryRentedContractsInProgress = createAction(
  types.queryRentedContractsInProgress
);
const queryRentedContractsSuccess = createAction(
  types.queryRentedContractsSuccess
);
const queryRentedContractsFail = createAction(types.queryRentedContractsFail);
const queryMultipleUnitsSuccess = createAction(types.queryMultipleUnitsSuccess);
const queryMultipleUnitsInProgress = createAction(
  types.queryMultipleUnitsInProgress
);
const queryMultipleUnitsFailed = createAction(types.queryMultipleUnitsFailed);
const queryUnitsSuccess = createAction(types.queryUnitsSuccess);
const queryUnitsInProgress = createAction(types.queryUnitsInProgress);
const queryUnitsFailed = createAction(types.queryUnitsFailed);
const findUnitByIdSuccess = createAction(types.findUnitByIdSuccess);
const findUnitByIdInProgress = createAction(types.findUnitByIdInProgress);
const findUnitByIdFailed = createAction(types.findUnitByIdFailed);
const updateUnitByIdSuccess = createAction(types.updateUnitByIdSuccess);
const updateUnitByIdInProgress = createAction(types.updateUnitByIdInProgress);
const updateUnitByIdFailed = createAction(types.updateUnitByIdFailed);
const deleteUnitByIdSuccess = createAction(types.deleteUnitByIdSuccess);
const deleteUnitByIdInProgress = createAction(types.deleteUnitByIdInProgress);
const deleteUnitByIdFailed = createAction(types.deleteUnitByIdFailed);
const createUnitSuccess = createAction(types.createUnitSuccess);
const createUnitInProgress = createAction(types.createUnitInProgress);
const createUnitFailed = createAction(types.createUnitFailed);
const setCurrentUnitCoordinates = createAction(types.setCurrentUnitCoordinates);

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const queryUnits = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryUnitsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .queryUnits(query)
      .then(response => {
        dispatch(queryUnitsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryUnitsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const searchUnits = () => {
  return (dispatch, getState, Api) => {
    dispatch(queryUnitsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return {
      action: query => communityModule.queryUnits(query)
    };
  };
};

const findMultipleUnitsByIds = (
  ids,
  contractsArray,
  userToCompleteProfiles
) => {
  return (dispatch, getState, Api) => {
    dispatch(queryUnitsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .queryMultipleUnits(ids, contractsArray.length)
      .then(units => {
        if (isEmpty(userToCompleteProfiles)) {
          let i;
          let unitContractsList = [];
          for (i = 0; i < contractsArray.length; i++) {
            const unit = units.find(
              unit => unit.id === contractsArray[i].unitId
            );
            if (unit) {
              unitContractsList.push({
                ...unit,
                contracts: [contractsArray[i]]
              });
            }
          }

          dispatch(queryMultipleUnitsSuccess(unitContractsList));
        } else {
          const normalProfiles = userToCompleteProfiles.profiles.filter(
            profile => !isEmpty(normalProfileTypes[profile.type])
          );
          const nonNormalProfile = userToCompleteProfiles.profiles.filter(
            profile => isEmpty(normalProfileTypes[profile.type])
          );

          const completedProfiles = normalProfiles.map(profile => ({
            ...profile,
            unit: units.find(unit => unit.id === profile.unit.id)
          }));

          dispatch(
            userActions.findUserByIdSuccess({
              ...userToCompleteProfiles,
              profiles: [...completedProfiles, ...nonNormalProfile]
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          queryMultipleUnitsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const queryRentedUnits = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryUnitsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .getContracts(query)
      .then(contracts => {
        dispatch(queryRentedContractsSuccess(contracts));
        if (contracts.content.length) {
          dispatch(
            findMultipleUnitsByIds(
              contracts.content.map(contract => contract.unitId),
              contracts.content
            )
          );
        } else {
          dispatch(queryMultipleUnitsSuccess([]));
        }
      })
      .catch(err => {});
  };
};

const findUnitById = id => {
  return (dispatch, getState, Api) => {
    dispatch(findUnitByIdInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .findUnitById(id)
      .then(response => {
        dispatch(findUnitByIdSuccess(response));
      })
      .catch(error => {
        dispatch(
          findUnitByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updateUnitById = unit => {
  return (dispatch, getState, Api) => {
    dispatch(updateUnitByIdInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const serializedUnit = serialize(
      unit,
      getState().units.updatedCurrentUnitCoordinates,
      getState().session.profile.community.id
    );

    return communityModule
      .updateUnitById(serializedUnit)
      .then(response => {
        dispatch(updateUnitByIdSuccess(response));
        dispatch(push('/units'));
      })
      .catch(error => {
        dispatch(
          updateUnitByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const deleteUnitById = unit => {
  return (dispatch, getState, Api) => {
    dispatch(deleteUnitByIdInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .deleteUnitById(unit)
      .then(response => {
        dispatch(deleteUnitByIdSuccess(response));
        dispatch(push('/units'));
      })
      .catch(error => {
        dispatch(
          deleteUnitByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const createUnit = unit => {
  return (dispatch, getState, Api) => {
    dispatch(createUnitInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .createUnit(unit)
      .then(response => {
        dispatch(createUnitSuccess(response));
        dispatch(queryUnits({ page: 0, limit: 15 }));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(
          createUnitFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

export { types };
export default {
  queryUnits,
  findUnitById,
  updateUnitById,
  deleteUnitById,
  createUnit,
  setCurrentUnitCoordinates,
  queryRentedUnits,
  findMultipleUnitsByIds,
  searchUnits
};
