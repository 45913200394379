export const types = {
  findAmenitiesSuccess: 'findAmenitiesSuccess',
  findAmenitiesInProgress: 'findAmenitiesInProgress',
  findAmenitiesFailed: 'findAmenitiesFailed',

  findAmenityByIdSuccess: 'findAmenityByIdSuccess',
  findAmenityByIdInProgress: 'findAmenityByIdInProgress',
  findAmenityByIdFailed: 'findAmenityByIdFailed',

  updateAmenityByIdSuccess: 'updateAmenityByIdSuccess',
  updateAmenityByIdInProgress: 'updateAmenityByIdInProgress',
  updateAmenityByIdFailed: 'updateAmenityByIdFailed',

  deleteAmenityByIdSuccess: 'deleteAmenityByIdSuccess',
  deleteAmenityByIdInProgress: 'deleteAmenityByIdInProgress',
  deleteAmenityByIdFailed: 'deleteAmenityByIdFailed',

  createAmenityInProgress: 'createAmenityInProgress',
  createAmenityFailed: 'createAmenityFailed',
  createAmenitySuccess: 'createAmenitySuccess',

  uploadAmenityFileInProgress: 'uploadAmenityFileInProgress',
  uploadAmenityFileSuccess: 'uploadAmenityFileSuccess',
  uploadAmenityFileFailed: 'uploadAmenityFileFailed',

  bookingAmenityInProgress: 'bookingAmenityInProgress',
  bookingAmenityFailed: 'bookingAmenityFailed',
  bookingAmenitySuccess: 'bookingAmenitySuccess',

  findAmenitiesBookedInProgress: 'findAmenitiesBookedInProgress',
  findAmenitiesBookedFailed: 'findAmenitiesBookedFailed',
  findAmenitiesBookedSuccess: 'findAmenitiesBookedSuccess',

  cancelAmenityBookedInProgress: 'cancelAmenityBookedInProgress',
  cancelAmenityBookedFailed: 'cancelAmenityBookedFailed',
  cancelAmenityBookedSuccess: 'cancelAmenityBookedSuccess',

  getAmenitiesCategoriesInProgress: 'getAmenitiesCategoriesInProgress',
  getAmenitiesCategoriesSuccess: 'getAmenitiesCategoriesSuccess',
  getAmenitiesCategoriesFailed: 'getAmenitiesCategoriesFailed',

  getBookingsInProgress: 'getBookingsInProgress',
  getBookingsSuccess: 'getBookingsSuccess',
  getBookingsFailed: 'getBookingsFailed',

  createAmenityCategoryInProgress: 'cretateAmenityCategoryInProgress',
  createAmenityCategorySuccess: 'cretateAmenityCategorySuccess',
  createAmenityCategoryFailed: 'cretateAmenityCategoryFailed',

  addCreditsToUnitInProgress: 'addCreditsToUnitInProgress',
  addCreditsToUnitSuccess: 'addCreditsToUnitSuccess',
  addCreditsToUnitFailed: 'addCreditsToUnitFailed',

  getCreditsAssignmentsInProgress: 'getAmenitiesCreditsAssignmentsInProgress',
  getCreditsAssignmentsSuccess: 'getAmenitiesCreditsAssignmentsSuccess',
  getCreditsAssignmentsFailed: 'getAmenitiesCreditsAssignmentsFailed',

  getDefaultersListInProgress: 'getDefaulterListInProgress',
  getDefaultersListSuccess: 'getDefaulterListSuccess',
  getDefaulterListFailed: 'getDefaulterListFailed',

  getUnitBalanceInProgress: 'getUnitBalanceInProgress',
  getUnitBalanceSuccess: 'getUnitBalanceSuccess',
  getUnitBalanceFailed: 'getUnitBalanceFailed',

  getUnitsDefaltersInProgress: 'getUnitsDefaltersInProgress',
  getUnitsDefaltersSuccess: 'getUnitsDefaltersSuccess',
  getUnitsDefaltersFailed: 'getUnitsDefaltersFailed',

  createBookingInProgress: 'createBookingInProgress',
  createBookingSuccess: 'createBookingSuccess',
  createBookingFailed: 'createBookingFailed'
};
