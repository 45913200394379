import { WIZARD_FIRST_STEP, WIZARD_LAST_STEP } from './formStepTypes';

export function getUnitWizard(state) {
  return state.units.createUnitWizard;
}

export function getWizardStep(state) {
  const wizardState = getUnitWizard(state);
  return wizardState.step;
}

export function getCreateUnitWizard(state) {
  const wizardState = getUnitWizard(state);
  return wizardState.createUnit;
}

export function isWizardInStep(state, step) {
  return step != null && getWizardStep(state) === step;
}

export function isWizardFirstStep(state) {
  return isWizardInStep(state, WIZARD_FIRST_STEP);
}

export function isWizardEndStep(state) {
  return isWizardInStep(state, 'location');
}

export function isWizardLastStep(state) {
  return isWizardInStep(state, WIZARD_LAST_STEP);
}

export function getCreateUnitCoordinates(state) {
  const wizardState = getCreateUnitWizard(state);
  return wizardState.location;
}

export function isUnitCoordinatesAlreadySet(state) {
  const unitCoordinates = getCreateUnitCoordinates(state);
  return unitCoordinates.latitude !== 0 && unitCoordinates.longitude !== 0;
}
