import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';

const initialState = {
  queryVisitorsMovementsIsInProgress: false,
  queryVisitorsMovementsHasFailed: false,

  visitorsMovements: [],
  totalVisitorsPages: -1,
  currentVisitorsPage: 0,

  queryResidentsMovementsIsInProgress: false,
  queryResidentsMovementsHasFailed: false,
  residentsMovements: [],
  totalResidentsPages: -1,
  currentResidentsPage: 0,

  queryCurfewIsInProgress: false,
  queryCurfewHasFailed: true,
  curfew: [],
  totalCurfewPages: -1,
  currentCurfewPage: 0,

  apiError: null,

  queryBlacklistMovementsIsInProgress: false,
  queryBlacklistMovementsHasFailed: false,
  blacklistMovements: [],
  totalBlacklistPages: -1,
  currentBlacklistPage: 0,

  downloadVisitorsMovementsInProgress: false,
  downloadVisitorsMovementsSuccess: false,
  downloadVisitorsMovementsFail: false,
  downloadVisitorsMovementsErrors: null
};

const reducer = handleActions(
  {
    [types.restartDownloadVisitorState]: state => {
      return {
        ...state,
        downloadVisitorsMovementsInProgress: false,
        downloadVisitorsMovementsSuccess: false,
        downloadVisitorsMovementsFail: false
      };
    },
    [types.downloadVisitorsMovementsInProgress]: state => {
      return {
        ...state,
        downloadVisitorsMovementsInProgress: true,
        downloadVisitorsMovementsSuccess: false,
        downloadVisitorsMovementsFail: false
      };
    },
    [types.downloadVisitorsMovementsSuccess]: state => {
      return {
        ...state,
        downloadVisitorsMovementsInProgress: false,
        downloadVisitorsMovementsSuccess: true,
        downloadVisitorsMovementsFail: false
      };
    },
    [types.downloadVisitorsMovementsFail]: (state, action) => {
      return {
        ...state,
        downloadVisitorsMovementsErrors: action.payload,
        downloadVisitorsMovementsInProgress: false,
        downloadVisitorsMovementsSuccess: false,
        downloadVisitorsMovementsFail: true
      };
    },
    [types.queryVisitorsMovementsSuccess]: (state, action) => {
      const movements = action.payload;
      return {
        ...state,
        queryVisitorsMovementsIsInProgress: false,
        queryVisitorsMovementsHasFailed: false,
        visitorsMovements: movements.content,
        totalVisitorsPages: movements.totalPages,
        currentVisitorsPage: movements.number,
        apiError: null
      };
    },
    [types.queryVisitorsMovementsFailed]: (state, action) => {
      return {
        ...state,
        queryVisitorsMovementsIsInProgress: false,
        queryVisitorsMovementsHasFailed: true,
        visitorsMovements: [],
        totalVisitorsPages: -1,
        currentVisitorsPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryVisitorsMovementsInProgress]: state => {
      return {
        ...state,
        queryVisitorsMovementsIsInProgress: true,
        queryVisitorsMovementsHasFailed: false,
        apiError: null
      };
    },
    [types.queryResidentsMovementsSuccess]: (state, action) => {
      const movements = action.payload;
      return {
        ...state,
        queryResidentsMovementsIsInProgress: false,
        queryResidentsMovementsHasFailed: false,
        residentsMovements: movements.content,
        totalResidentsPages: movements.totalPages,
        currentResidentsPage: movements.number,
        apiError: null
      };
    },
    [types.queryResidentsMovementsFailed]: (state, action) => {
      return {
        ...state,
        queryResidentsMovementsIsInProgress: false,
        queryResidentsMovementsHasFailed: true,
        residentsMovements: [],
        totalResidentsPages: -1,
        currentResidentsPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryResidentsMovementsInProgress]: state => {
      return {
        ...state,
        queryResidentsMovementsIsInProgress: true,
        queryResidentsMovementsHasFailed: false,
        apiError: null
      };
    },
    [types.queryCurfewSuccess]: (state, action) => {
      const movements = action.payload;

      return {
        ...state,
        queryCurfewIsInProgress: false,
        queryCurfewHasFailed: false,
        curfew: movements.content,
        totalCurfewPages: movements.totalPages,
        currentCurfewPage: movements.number,
        apiError: null,
        totalElements: movements.totalElements
      };
    },
    [types.queryCurfewFailed]: (state, action) => {
      return {
        ...state,
        queryCurfewIsInProgress: false,
        queryCurfewHasFailed: true,
        curfew: [],
        totalCurfewPages: -1,
        currentCurfewPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryCurfewInProgress]: state => {
      return {
        ...state,
        queryCurfewIsInProgress: true,
        queryCurfewHasFailed: false,
        apiError: null
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    },

    [types.queryBlacklistMovementsSuccess]: (state, action) => {
      const movements = action.payload;

      return {
        ...state,
        queryBlacklistMovementsIsInProgress: false,
        queryBlacklistMovementsHasFailed: false,
        blacklistMovements: movements.content,
        totalBlacklistPages: movements.totalPages,
        currentBlacklistPage: movements.number,
        apiError: null
      };
    },
    [types.queryBlacklistMovementsFailed]: (state, action) => {
      return {
        ...state,
        queryBlacklistMovementsIsInProgress: false,
        queryBlacklistMovementsHasFailed: true,
        blacklistMovements: [],
        totalBlacklistPages: -1,
        currentBlacklistPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryBlacklistMovementsInProgress]: state => {
      return {
        ...state,
        queryBlacklistMovementsIsInProgress: true,
        queryBlacklistMovementsHasFailed: false,
        apiError: null
      };
    }
  },
  initialState
);

export default reducer;
