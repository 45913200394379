import { types } from './actions';
import { WIZARD_FIRST_STEP } from './formStepTypes';

export const initialState = {
  step: null,
  createUnit: {
    id: 0,
    unitNumber: '',
    lotNumber: '',
    block: 0,
    address: {
      street: '',
      number: ''
    },
    //(0, 0) is atlantic ocean
    //so we can use 0 to check weather the UF has
    //coordinates set or not
    location: {
      latitude: 0,
      longitude: 0
    },
    type: ''
  }
};

const reducer = {
  [types.startCreateUnitWizard]: state => {
    const createWizardState = state.createUnitWizard;
    return {
      ...state,
      createUnitWizard: {
        ...createWizardState,
        step: WIZARD_FIRST_STEP
      }
    };
  },
  [types.setCurrentUnitWizardStep]: (state, action) => {
    const createWizardState = state.createUnitWizard;
    return {
      ...state,
      createUnitWizard: {
        ...createWizardState,
        step: action.payload
      }
    };
  },
  [types.updateWizardUnit]: (state, action) => {
    const createWizardState = state.createUnitWizard;
    return {
      ...state,
      createUnitWizard: {
        ...createWizardState,
        createUnit: {
          ...action.payload,
          // to avoid erasing changes performed with
          // setCreateUnitCoordinate action
          location: createWizardState.createUnit.location
        }
      }
    };
  },
  [types.setCreateUnitCoordinates]: (state, action) => {
    const createWizardState = state.createUnitWizard;
    return {
      ...state,
      createUnitWizard: {
        ...createWizardState,
        createUnit: {
          ...createWizardState.createUnit,
          location: action.payload
        }
      }
    };
  }
};

export default reducer;
