import { types } from './actions';

export const initialState = {
  step: null,
  createBlacklist: null
};

const reducer = {
  [types.startCreateBlacklistWizard]: (state, action) => {
    const createWizardState = state.createWizard;

    return {
      ...state,
      createWizard: {
        ...createWizardState,
        step: 'identity',
        type: action.payload
      }
    };
  },
  [types.setCurrentStep]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        step: action.payload
      }
    };
  },
  [types.updateWizardBlacklist]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        createBlacklist: action.payload
      }
    };
  }
};

export default reducer;
