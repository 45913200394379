import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import Router from '../../router';
import Header from '../../components/header';
import KVBreadcrumb from '../../components/breadcrumb';
import Loading from '../../components/loading';
import OffCanvasNavigation, {
  OffCanvasItem
} from '../../components/offCanvasNavigation';
import ApplicationActions from '../../store/application/actions';
import SessionActions from '../../store/session/actions';
import 'font-awesome/css/font-awesome.css';
import './styles.css';
import SupportToolsActions from '../../store/supportTools/actions';
import { supportToolsEnabled } from '../../store/supportTools/selector';

const LoadableLogin = Loadable({
  loader: () => import('../login'),
  loading() {
    return <Loading />;
  }
});

const LoadableForgotPassword = Loadable({
  loader: () => import('../forgotPassword'),
  loading() {
    return <Loading legend="Cargando formulario" />;
  }
});

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandOffCanvas: false
    };
  }
  /**
   * This will be called with any uncaught error that bubbles up from
   * the component’s children’s lifecycle methods, constructors, or
   * render methods (but not the component’s own errors).
   *
   * Link: https://blog.sentry.io/2017/09/28/react-16-error-boundaries
   */
  componentDidCatch(error, errorInfo) {
    Raven.captureException(error, {
      extra: errorInfo
    });
  }

  handleKeyDown = event => {
    if (event.ctrlKey && event.shiftKey && event.key === 'K') {
      if (this.props.supportToolsEnabled) {
        this.props.disableSupportTools();
      } else {
        this.props.enableSupportTools();
      }
    }
  };

  componentWillMount() {
    this.props.initializeApp(this.props.store);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.props.terminateApp(this.props.isLoggedIn);
  }

  toggleOffCanvas = () => {
    this.setState({
      expandOffCanvas: !this.state.expandOffCanvas
    });
  };

  /**
   * Application loading screen. Rendered before
   * `appHasBooted` is fired.
   */
  renderLoadingScreen() {
    return <section className="application--loading">Cargando</section>;
  }

  renderLogin() {
    return (
      <section className="application">
        <LoadableLogin />
      </section>
    );
  }

  renderForgotPassword() {
    return (
      <section className="application">
        <LoadableForgotPassword />
      </section>
    );
  }

  handleChangeActiveProfile = community => {
    const { updateActiveProfile, history } = this.props;
    updateActiveProfile(community);
    history.push('/');
  };

  renderApp() {
    const {
      hasValidSession,
      location,
      communities,
      currentCommunity,
      avatar,
      userName,
      logOff,
      store,
      supportToolsEnabled
    } = this.props;
    const currentPath = location.pathname;
    return (
      <section className="application">
        {supportToolsEnabled ? (
          <div className="supportToolsIndicator">
            HERRAMIENTAS DE SOPORTE ACTIVAS
          </div>
        ) : null}
        <Header
          communities={communities}
          currentCommunity={currentCommunity}
          avatar={avatar}
          userName={userName}
          onLogOff={logOff}
          onCommunitySelection={this.handleChangeActiveProfile}
        />
        <section className="application__container">
          <aside>
            <OffCanvasNavigation expanded={this.state.expandOffCanvas}>
              <OffCanvasItem
                label="Usuarios"
                icon="users"
                linkTo="/users"
                isActive={currentPath.includes('users')}
              />
              <OffCanvasItem
                label="Unidades Funcionales"
                icon="home"
                linkTo="/units"
                isActive={currentPath.includes('units')}
              />
              <OffCanvasItem
                label="Mensajes"
                icon="chat"
                linkTo="/communications"
                isActive={currentPath.includes('communications')}
              />
              <OffCanvasItem
                label="Movimientos"
                icon="arrows"
                linkTo="/movements"
                isActive={currentPath.includes('movements')}
              />
              <OffCanvasItem
                label="Segmentos"
                icon="box"
                linkTo="/segments"
                isActive={currentPath.includes('segments')}
              />
              <OffCanvasItem
                label="Comodidades"
                icon="calendar"
                linkTo="/amenities"
                isActive={currentPath.includes('amenities')}
              />
              <OffCanvasItem
                label="Lista Negra"
                icon="blacklist"
                linkTo="/blacklist"
                isActive={currentPath.includes('blacklist')}
              />
              <OffCanvasItem
                label="Configuracion"
                icon="settings"
                linkTo="/settings"
                isActive={currentPath.includes('settings')}
              />
            </OffCanvasNavigation>
          </aside>
          <main>
            <KVBreadcrumb handleHamburguerClick={this.toggleOffCanvas} />
            {/*router personalizado por keenvil */}
            <div className="application__container__content">
              <Router
                store={store}
                hasValidSession={hasValidSession}
                location={location}
              />
            </div>
          </main>
        </section>
      </section>
    );
  }

  render() {
    const { appHasBooted, hasValidSession, location } = this.props;

    if (!appHasBooted) {
      return this.renderLoadingScreen();
    } else {
      if (hasValidSession) {
        return this.renderApp();
      } else {
        if (location.pathname.includes('forgot-password')) {
          return this.renderForgotPassword();
        } else {
          return this.renderLogin();
        }
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    appHasBooted: state.application.appHasBooted,
    hasValidSession:
      state.session.user && SessionActions.isValidSession(state.session.user),
    currentCommunity: state.session.profile
      ? state.session.profile.community
      : {},
    communities:
      state.session.profiles != null
        ? state.session.profiles.map(profile => profile.community)
        : [],
    avatar: state.session.user ? state.session.user.avatarUri : null,
    userName: state.session.user
      ? `${state.session.user.firstName} ${state.session.user.lastName}`
      : null,
    supportToolsEnabled: supportToolsEnabled(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initializeApp: store => {
      dispatch(ApplicationActions.initializeApp(store));
    },

    terminateApp: isLoggedIn => {
      dispatch(ApplicationActions.terminateApp(isLoggedIn));
    },

    logOff: () => {
      dispatch(SessionActions.logOffUser());
    },

    updateActiveProfile: community => {
      dispatch(SessionActions.updateActiveProfile(community));
    },

    enableSupportTools: () => {
      dispatch(SupportToolsActions.enableSupportTools());
    },

    disableSupportTools: () => {
      dispatch(SupportToolsActions.disableSupportTools());
    }
  };
};

const ConnectedApplication = connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);

// By wrapping Application with withRouter we get the location prop,
// which is then passed down to Router to make it aware of route changes
export default withRouter(ConnectedApplication);

/**
 * 
 *  <OffCanvasItem
                label="Configuracion"
                icon="settings"
                linkTo="/settings"
                isActive={currentPath.includes('settings')}
              />
 */
