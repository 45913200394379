import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getRoutes from '../../routes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Menu } from 'react-feather';

import './styles.css';

// Regex to detect params in a route string
const paramRegex = /:(\w+)/g;

class KVBreadcrumb extends Component {
  constructor(props) {
    super(props);
    this.params = {};
  }

  /**
   * Detects if a given route is the active one
   */
  isActive = (route, currentRoute) => {
    const routePattern = this.buildRoutePattern(route);
    const exactPattern = `^${routePattern}$`;
    const routeRegex = new RegExp(exactPattern);
    return routeRegex.test(currentRoute);
  };

  /**
   * Determines if a certain route is prefix of the current one
   */
  isPrefix = (route, currentRoute) => {
    const routePattern = this.buildRoutePattern(route);
    const routeRegex = new RegExp(routePattern);
    return routeRegex.test(currentRoute);
  };

  /**
   * Given a route, it creates the pattern to build the regex
   */
  buildRoutePattern = route => {
    const path = route.path;
    return path.replace(paramRegex, '(\\w+)');
  };

  /**
   * Takes the current matched route and extracts the matched params
   */
  saveParams = (route, currentRoute) => {
    const splittedRoute = route.split('/');
    const splittedCurrentRoute = currentRoute.split('/');
    const routeLength = splittedRoute.length;
    const params = {};
    for (let i = 0; i < routeLength; i++) {
      const subroute = splittedRoute[i];
      const matched = paramRegex.exec(subroute);
      if (matched) {
        params[matched[1]] = splittedCurrentRoute[i];
      }
    }
    this.params = params;
  };

  buildName = route => {
    if (typeof route.name === 'function') {
      return route.name(this.params);
    } else {
      return route.name;
    }
  };

  buildBreadcrumbItems = () => {
    const routes = getRoutes(this.props.store);
    const currentRoute = this.props.routing.location.pathname;
    const matchedRoutes = routes.filter(route => {
      return this.isPrefix(route, currentRoute);
    });
    return matchedRoutes.map((route, index) => {
      const isActive = this.isActive(route, currentRoute);
      const breadcrumTextClass = classnames('kv-breadcrumb__text', {
        'kv-breadcrumb__text--active': isActive
      });
      if (isActive) {
        this.saveParams(route.path, currentRoute);
      }
      const key = `route${index}`;
      return (
        <li className="kv-breadcrumb__item" key={key}>
          <Link className={breadcrumTextClass} to={route.path}>
            {this.buildName(route)}
          </Link>
        </li>
      );
    });
  };

  render() {
    return (
      <div className="kv-breadcrumb">
        <div
          className="kv-breadcrumb__nav"
          onClick={this.props.handleHamburguerClick}
        >
          <Menu />
        </div>
        <ul className="kv-breadcrumb__items">{this.buildBreadcrumbItems()}</ul>
        <Link to="/tutorials" className="kv-breadcrumb__tutorialsBtn">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <labe>Tutoriales</labe>
        </Link>
      </div>
    );
  }
}

KVBreadcrumb.propTypes = {
  routing: PropTypes.object
};

const mapStateToProps = state => {
  return {
    routing: state.routing,
    store: state
  };
};

const ConnectedBreadcrumbs = connect(mapStateToProps, null)(KVBreadcrumb);

export default ConnectedBreadcrumbs;
