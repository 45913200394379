/**
 * Transforms the stored blacklist item in the redux store
 * into a suitable representation to send to API.
 * Caveats:
 * - Blacklist Update API expects a personalId object
 * - Blacklist Create API expects idType and idNumber fields
 */
export function serialize(storedBlacklist) {
  const blacklist = {
    idType: storedBlacklist.idType,
    idNumber: storedBlacklist.idNumber,
    type: storedBlacklist.type,
    creationDate: storedBlacklist.creationDate,
    reason: storedBlacklist.reason
  };
  if (storedBlacklist.visitor != null) {
    blacklist.name = storedBlacklist.visitor.name;
  } else if (storedBlacklist.name != null) {
    blacklist.name = storedBlacklist.name;
  }
  if (storedBlacklist.id != null) {
    blacklist.id = storedBlacklist.id;
  }
  return blacklist;
}
