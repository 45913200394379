import PropTypes from 'prop-types';

export const UNIT_WIZARD_STEPS = [
  'lotInformation',
  'location',
  'unitCreationSuccess'
];

export const WIZARD_FIRST_STEP = UNIT_WIZARD_STEPS[0];
export const WIZARD_LAST_STEP = UNIT_WIZARD_STEPS[UNIT_WIZARD_STEPS.length - 1];

export const formStepType = PropTypes.oneOf(UNIT_WIZARD_STEPS);
