import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Loading = ({ legend }) => {
  return <p className="loading">{legend}</p>;
};

Loading.propTypes = {
  legend: PropTypes.string
};

Loading.defaultProps = {
  legend: 'Cargando...'
};

export default Loading;
