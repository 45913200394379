export const allProfileTypes = {
  OWNER: 'OWNER',
  CORESIDENT: 'CORESIDENT',
  TENANT: 'TENANT',
  ADMIN: 'ADMIN',
  SUPERVISOR: 'SUPERVISOR',
  GUARD: 'GUARD',
  RESIDENT: 'RESIDENT',
  SECURITY: 'SECURITY'
};

export const readableAllProfileTypes = {
  OWNER: 'Propietario',
  CORESIDENT: 'Coresidente',
  TENANT: 'Inquilino',
  ADMIN: 'Admin',
  SUPERVISOR: 'Supervisor',
  GUARD: 'Guardia',
  RESIDENT: 'Residente',
  SECURITY: 'Seguridad'
};

export const normalProfileTypes = {
  OWNER: 'OWNER',
  TENANT: 'TENANT',
  CORESIDENT: 'CORESIDENT'
};

export const accountTypes = {
  resident: 'resident',
  guard: 'guard'
};

export const statusTypes = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  DELETED: 'DELETED',
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  VALIDATED: 'VALIDATED'
};

export const readableStatusTypes = {
  ENABLED: 'Habilitado',
  DISABLED: 'Deshabilitado',
  DELETED: 'Eliminado',
  ACTIVE: 'Activo',
  NOT_ACTIVE: 'Inactivo'
};

export const readablePropertyProfiles = {
  OWNER: 'Propietario',
  TENANT: 'Inquilino',
  CORESIDENT: 'coresidente'
};

export const readableGuardProfiles = {
  GUARD: 'Guardia',
  SUPERVISOR: 'Supervisor'
};

export const relationshipsTypes = {
  EMPLOYEE: 'EMPLOYEE',
  DELIVERY: 'DELIVERY',
  FAMILY: 'FAMILY',
  SERVICE: 'SERVICE'
};

export const invitationTypes = {
  ACCESS: 'ACCESS',
  EXIT: 'EXIT'
};

export const invitationStatus = {
  SUCCESS: 'SUCCESS'
};

export const cardStatus = {
  ACTIVE: 'ACTIVE'
};
