import { createAction } from 'redux-actions';

const types = {
  getProvidersInProgress: 'getProvidersInProgress',
  getProvidersFail: 'getProvidersFail',
  getProvidersSuccess: 'getProvidersSuccess',
  setSettingsInProgress: 'setSettingsInProgress',
  setSettingsFail: 'setSettingsFail',
  setSettingsSuccess: 'setSettingsSuccess'
};

const getProvidersInProgress = createAction(types.getProvidersInProgress);
const getProvidersSuccess = createAction(types.getProvidersSuccess);
const getProvidersFail = createAction(types.getProvidersFail);

const setSettingsInProgress = createAction(types.setSettingsInProgress);
const setSettingsFail = createAction(types.setSettingsFail);
const setSettingsSuccess = createAction(types.setSettingsSuccess);

const getProviders = (id) => {
  return (dispatch, getState, Api) => {
    const crowdModule = Api.getModule('crowd');
    crowdModule.adapter.token = getState().session.user.token;

    crowdModule.adapter.communityId = getState().session.profile.community.id;

    const communityId = crowdModule.adapter.communityId;

    dispatch(getProvidersInProgress());

    return crowdModule
      .getProviders(communityId)
      .then((crowdData) => {
        dispatch(getProvidersSuccess(crowdData));
      })
      .catch((error) => {
        dispatch(
          getProvidersFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export const setSettings = (settings) => {
  return (dispatch, getState, Api) => {
    dispatch(setSettingsInProgress());

    const communityModule = Api.getModule('crowd');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    const communityId = communityModule.adapter.communityId;

    return communityModule
      .setSettings(communityId, settings)
      .then((response) => {
        dispatch(setSettingsSuccess(response));
      })
      .catch((error) => {
        dispatch(
          setSettingsFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };

export default {
  types,
  getProviders
};
