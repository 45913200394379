import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Guard Module endpoints
 */
class GuardModule extends ApiModule {
  /**
   * Queries the visitors movements lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryVisitorsMovements(query) {
    return this.adapter.query('guards/accesses', query);
  }

  queryBlacklistMovements(query) {
    return this.adapter.query('guards/accesses', query);
  }

  /**
   * Queries the visitors movements lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  downloadVisitorsMovements(query) {
    return this.adapter.query('guards/accesses', query, false);
  }

  openGateWithManualMovement({
    personalId,
    pin,
    gate,
    lane,
    device = 'KEENVIL'
  }) {
    return this.adapter.save('guards/residents/access', {
      device,
      gate,
      lane,
      number: personalId.number,
      pin,
      type: personalId.type
    });
  }

  /**
   * Queries the residents movements lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryResidentsMovements(query) {
    return this.adapter.query('residents/accesses', query);
  }

  /**
   * Queries the residents movements lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  downloadResidentsMovements(query) {
    return this.adapter.query('residents/accesses', query, false);
  }

  /**
   * Queries the residents movements lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryCurfew(query) {
    return this.adapter.query('guards/accesses/without-exit', query);
  }
}

export default new GuardModule('guard', ApiConfig);
