import { createAction } from 'redux-actions';
import { push } from 'react-router-redux';
import { getCommunicationById } from './selectors';

import serializer from './serializer';

const types = {
  queryCommunicationsSuccess: 'queryCommunicationsSuccess',
  queryCommunicationsInProgress: 'queryCommunicationsInProgress',
  queryCommunicationsFailed: 'queryCommunicationsFailed',
  findCommunicationByIdSuccess: 'findCommunicationByIdSuccess',
  findCommunicationByIdInProgress: 'findCommunicationByIdInProgress',
  findCommunicationByIdFailed: 'findCommunicationByIdFailed',
  updateCommunicationByIdSuccess: 'updateCommunicationByIdSuccess',
  updateCommunicationByIdInProgress: 'updateCommunicationByIdInProgress',
  updateCommunicationByIdFailed: 'updateCommunicationByIdFailed',
  deleteCommunicationByIdSuccess: 'deleteCommunicationByIdSuccess',
  deleteCommunicationByIdInProgress: 'deleteCommunicationByIdInProgress',
  deleteCommunicationByIdFailed: 'deleteCommunicationByIdFailed',
  createCommunicationInProgress: 'createCommunicationInProgress',
  createCommunicationFailed: 'createCommunicationFailed',
  createCommunicationSuccess: 'createCommunicationSuccess',
  uploadArticleFileInProgress: 'uploadArticleFileInProgress',
  uploadArticleFileSuccess: 'uploadArticleFileSuccess',
  uploadArticleFileFailed: 'uploadArticleFileFailed'
};

const queryCommunicationsSuccess = createAction(
  types.queryCommunicationsSuccess
);
const queryCommunicationsInProgress = createAction(
  types.queryCommunicationsInProgress
);
const queryCommunicationsFailed = createAction(types.queryCommunicationsFailed);
const findCommunicationByIdSuccess = createAction(
  types.findCommunicationByIdSuccess
);
const findCommunicationByIdInProgress = createAction(
  types.findCommunicationByIdInProgress
);
const findCommunicationByIdFailed = createAction(
  types.findCommunicationByIdFailed
);
const updateCommunicationByIdSuccess = createAction(
  types.updateCommunicationByIdSuccess
);
const updateCommunicationByIdInProgress = createAction(
  types.updateCommunicationByIdInProgress
);
const updateCommunicationByIdFailed = createAction(
  types.updateCommunicationByIdFailed
);
const deleteCommunicationByIdSuccess = createAction(
  types.deleteCommunicationByIdSuccess
);
const deleteCommunicationByIdInProgress = createAction(
  types.deleteCommunicationByIdInProgress
);
const deleteCommunicationByIdFailed = createAction(
  types.deleteCommunicationByIdFailed
);
const createCommunicationInProgress = createAction(
  types.createCommunicationInProgress
);
const createCommunicationFailed = createAction(types.createCommunicationFailed);

const createCommunicationSuccess = createAction(
  types.createCommunicationSuccess
);

const uploadArticleFileInProgress = createAction(
  types.uploadArticleFileInProgress
);
const uploadArticleFileSuccess = createAction(types.uploadArticleFileSuccess);

const uploadArticleFileFailed = createAction(types.uploadArticleFileFailed);

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const queryCommunications = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryCommunicationsInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    query['all'] = true;

    return townhallModule
      .queryMessages(query)
      .then(response => {
        dispatch(queryCommunicationsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryCommunicationsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findCommunicationById = (id, type) => {
  return (dispatch, getState, Api) => {
    dispatch(findCommunicationByIdInProgress());

    const localCommunication = getCommunicationById(
      getState().communications,
      id,
      type
    );

    if (localCommunication) {
      dispatch(findCommunicationByIdSuccess(localCommunication));
    } else {
      switch (type) {
        case 'article':
          dispatch(findArticleById(id));
          break;
        case 'poll':
          dispatch(findPollById(id));
          break;
        default:
          break;
      }
    }
  };
};

const findArticleById = id => {
  return (dispatch, getState, Api) => {
    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .findArticleById(id)
      .then(response => {
        dispatch(findCommunicationByIdSuccess(response));
      })
      .catch(error => {
        dispatch(
          findCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const createCommunication = communication => {
  return (dispatch, getState, Api) => {
    dispatch(createCommunicationInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;
    const articleImage = getState().communications.articleFilePath;
    const serializedCommunication = serializer.serializeArticle(
      communication,
      articleImage
    );

    return townhallModule
      .createArticle(serializedCommunication)
      .then(response => {
        dispatch(createCommunicationSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          createCommunicationFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const uploadFile = articleFile => {
  return (dispatch, getState, Api) => {
    dispatch(uploadArticleFileInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .uploadArticleFile(articleFile)
      .then(response => {
        dispatch(uploadArticleFileSuccess(response));
        return Promise.resolve(response.fileUri);
      })
      .catch(error => {
        dispatch(
          uploadArticleFileFailed({
            apiError: error.apiErrors
          })
        );
        throw new Error(error);
      });
  };
};

const updateCommunicationById = communication => {
  return (dispatch, getState, Api) => {
    dispatch(updateCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;
    const articleImage = getState().communications.articleFilePath;

    const serializedCommunication = serializer.serializeArticle(
      communication,
      articleImage
    );

    return townhallModule
      .updateArticleById(serializedCommunication)
      .then(response => {
        dispatch(updateCommunicationByIdSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          updateCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const deleteCommunication = (type, id) => {
  return (dispatch, getState, Api) => {
    dispatch(deleteCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .deleteArticleById(type, id)
      .then(response => {
        dispatch(deleteCommunicationByIdSuccess(id));
        Promise.resolve();
      })
      .catch(error => {
        dispatch(
          deleteCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

/** Poll Actions **/

const createPoll = communication => {
  return (dispatch, getState, Api) => {
    dispatch(createCommunicationInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;
    const serializedCommunication = serializer.serializePoll(communication);

    return townhallModule
      .createPoll(serializedCommunication)
      .then(response => {
        dispatch(createCommunicationSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          createCommunicationFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findPollById = id => {
  return (dispatch, getState, Api) => {
    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .findPollById(id)
      .then(response => {
        dispatch(findCommunicationByIdSuccess(response));
      })
      .catch(error => {
        dispatch(
          findCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updatePollById = communication => {
  return (dispatch, getState, Api) => {
    dispatch(updateCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    const serializedCommunication = serializer.serializePoll(communication);

    return townhallModule
      .updatePollById(serializedCommunication)
      .then(response => {
        dispatch(updateCommunicationByIdSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          updateCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const deletePoll = id => {
  return (dispatch, getState, Api) => {
    dispatch(deleteCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .deletePollById(id)
      .then(() => {
        dispatch(deleteCommunicationByIdSuccess(id));
        Promise.resolve();
      })
      .catch(error => {
        dispatch(
          deleteCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

/** Event Actions **/

const createEvent = communication => {
  return (dispatch, getState, Api) => {
    dispatch(createCommunicationInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;
    const serializedCommunication = serializer.serializeEvent(communication);

    return townhallModule
      .createEvent(serializedCommunication)
      .then(response => {
        dispatch(createCommunicationSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          createCommunicationFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updateEventById = communication => {
  return (dispatch, getState, Api) => {
    dispatch(updateCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    const serializedCommunication = serializer.serializeEvent(communication);

    return townhallModule
      .updateEventById(serializedCommunication)
      .then(response => {
        dispatch(updateCommunicationByIdSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          updateCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const deleteEvent = id => {
  return (dispatch, getState, Api) => {
    dispatch(deleteCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .deleteEventById(id)
      .then(() => {
        dispatch(deleteCommunicationByIdSuccess(id));
        Promise.resolve();
      })
      .catch(error => {
        dispatch(
          deleteCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

/** Poll Actions **/

const createQuiz = communication => {
  return (dispatch, getState, Api) => {
    dispatch(createCommunicationInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;
    const serializedCommunication = serializer.serializeQuiz(communication);

    return townhallModule
      .createQuiz(serializedCommunication)
      .then(response => {
        dispatch(createCommunicationSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          createCommunicationFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findQuizById = id => {
  return (dispatch, getState, Api) => {
    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .findQuizById(id)
      .then(response => {
        dispatch(findCommunicationByIdSuccess(response));
      })
      .catch(error => {
        dispatch(
          findCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updateQuiz = communication => {
  return (dispatch, getState, Api) => {
    dispatch(updateCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    const serializedCommunication = serializer.serializeQuiz(communication);

    return townhallModule
      .updateQuiz(serializedCommunication)
      .then(response => {
        dispatch(updateCommunicationByIdSuccess(response));
        dispatch(push('/communications'));
      })
      .catch(error => {
        dispatch(
          updateCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const deleteQuiz = id => {
  return (dispatch, getState, Api) => {
    dispatch(deleteCommunicationByIdInProgress());

    const townhallModule = Api.getModule('townhall');
    townhallModule.adapter.token = getState().session.user.token;
    townhallModule.adapter.communityId = getState().session.profile.community.id;

    return townhallModule
      .deleteQuiz(id)
      .then(() => {
        dispatch(deleteCommunicationByIdSuccess(id));
        Promise.resolve();
      })
      .catch(error => {
        dispatch(
          deleteCommunicationByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  queryCommunications,
  findCommunicationById,
  updateCommunicationById,
  deleteCommunication,
  createCommunication,
  uploadFile,
  createPoll,
  updatePollById,
  deletePoll,
  createEvent,
  updateEventById,
  deleteEvent,
  createQuiz,
  updateQuiz,
  deleteQuiz,
  findQuizById
};
