import { createAction } from 'redux-actions';

const types = {
  searchUnitsSuccess: 'searchUnitsSuccess',
  searchUsersSuccess: 'searchUsersSuccess',
  searchUsersInProgress: 'searchUsersInProgress',
  searchUsersFailed: 'searchUsersFailed',
  clearUsersSearch: 'clearUsersSearch',
  searchContractUsersSuccess: 'searchContractUsersSuccess',
  searchContractUsersInProgress: 'searchContractUsersInProgress',
  searchContractUsersFailed: 'searchContractUsersFailed'
};

const searchUnitsSuccess = createAction(types.searchUnitsSuccess);
const searchUsersSuccess = createAction(types.searchUsersSuccess);
const searchUsersInProgress = createAction(types.searchUsersInProgress);
const searchUsersFailed = createAction(types.searchUsersFailed);
const clearUsersSearch = createAction(types.clearUsersSearch);

const searchContractUsersSuccess = createAction(
  types.searchContractUsersSuccess
);
const searchContractUsersInProgress = createAction(
  types.searchContractUsersInProgress
);
const searchContractUsersFailed = createAction(types.searchContractUsersFailed);

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const search = query => {
  return (dispatch, getState, Api) => {
    dispatch(searchUsersInProgress(query));

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    communityModule
      .queryUsers({
        q: query,
        limit: 500
      })
      .then(response => {
        dispatch(searchUsersSuccess(response));
      })
      .catch(error => {
        dispatch(
          searchUsersFailed({
            apiError: error.apiErrors
          })
        );
      });
    communityModule
      .queryUnits({
        q: query,
        limit: 500
      })
      .then(result => {
        dispatch(searchUnitsSuccess(result));
      });
  };
};

const searchUsers = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return {
      action: query => communityModule.queryUsers(query)
    };
  };
};

const searchContractUser = query => {
  return (dispatch, getState, Api) => {
    dispatch(searchContractUsersInProgress(query));

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .queryUsers({
        q: query,
        limit: 100
      })
      .then(response => {
        dispatch(searchContractUsersSuccess(response));
      })
      .catch(error => {
        dispatch(
          searchContractUsersFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  search,
  searchUsers,
  clearUsersSearch,
  searchContractUser
};
