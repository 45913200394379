import { handleActions } from 'redux-actions';
import { types } from './actions';
import assign from 'lodash/assign';
import find from 'lodash/find';
import { getUserProfilesByType } from '../users/selectors';

const initialState = {
  isAuthenticationInProgress: false,
  isAuthenticated: false,
  hasAuthenticationFailed: false,
  apiErrors: null,
  user: null,
  // Current profile for the logged user
  // Is used to get the community id to perform
  // calls to the API
  profile: null,
  // ADMIN profiles for the logged user
  profiles: []
};

function hasValidProfile(profileType) {
  return ['ADMIN', 'SUPERVISOR', 'GUARD'].includes(profileType);
}

function getAdminProfile(userSession) {
  return find(userSession.profiles, profile => {
    return profile.status === 'ENABLED' && hasValidProfile(profile.type);
  });
}

const reducer = handleActions(
  {
    [types.authenticationInProgress]: state => {
      return {
        ...state,
        isAuthenticationInProgress: true,
        isAuthenticated: false,
        hasAuthenticationFailed: false,
        apiErrors: null,
        user: null,
        profile: null,
        profiles: []
      };
    },
    [types.authenticationFail]: (state, action) => {
      return {
        ...state,
        isAuthenticationInProgress: false,
        isAuthenticated: false,
        hasAuthenticationFailed: true,
        apiErrors: action.payload.apiErrors,
        user: null,
        profile: null,
        profiles: []
      };
    },
    [types.authenticationSuccess]: (state, action) => {
      return {
        ...state,
        isAuthenticationInProgress: false,
        isAuthenticated: true,
        hasAuthenticationFailed: false,
        apiErrors: null,
        user: action.payload.session,
        profile: getAdminProfile(action.payload.session),
        profiles: getUserProfilesByType(action.payload.session, 'ADMIN')
      };
    },
    [types.refreshTokenSuccess]: (state, action) => {
      const session = state.user;
      return {
        ...state,
        user: assign(session, action.payload)
      };
    },
    [types.updateActiveProfile]: (state, action) => {
      const selectedCommunity = action.payload;
      const profiles = state.profiles;
      const newProfile = find(
        profiles,
        profile => profile.community.id === selectedCommunity.id
      );
      return {
        ...state,
        profile: newProfile
      };
    },
    [types.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
