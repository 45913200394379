import React, { PureComponent } from 'react';
import { Switch, Redirect } from 'react-router';
import getRoutes from './routes';
import PrivateRoute from './components/privateRoute';

/**
 * Crappy solution to ReactRouter problem
 * to update rendering of routes when url changes.
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
 */
class UpdateBlocker extends React.PureComponent {
  render() {
    return this.props.children;
  }
}

class Router extends PureComponent {
  render() {
    const { store, location, hasValidSession } = this.props;
    const routes = getRoutes(store);
    return (
      <UpdateBlocker location={location}>
        <Switch>
          {routes.map(route => {
            return (
              <PrivateRoute
                key={route.path}
                hasValidSession={hasValidSession}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          })}
          <Redirect from="/" exact to="/units" />
        </Switch>
      </UpdateBlocker>
    );
  }
}

export default Router;
