import { createAction } from 'redux-actions';
import ProviderActions from '../providers/actions';
import { delay } from 'lodash';

const types = {
  queryVisitorsMovementsSuccess: 'queryVisitorsMovementsSuccess',
  queryVisitorsMovementsInProgress: 'queryVisitorsMovementsInProgress',
  queryVisitorsMovementsFailed: 'queryVisitorsMovementsFailed',
  queryBlacklistMovementsSuccess: 'queryBlacklistMovementsSuccess',
  queryBlacklistMovementsInProgress: 'queryBlacklistMovementsInProgress',
  queryBlacklistMovementsFailed: 'queryBlacklistMovementsFailed',

  queryResidentsMovementsSuccess: 'queryResidentsMovementsSuccess',
  queryResidentsMovementsInProgress: 'queryResidentsMovementsInProgress',
  queryResidentsMovementsFailed: 'queryResidentsMovementsFailed',
  queryCurfewSuccess: 'queryCurfewSuccess',
  queryCurfewInProgress: 'queryCurfewInProgress',
  queryCurfewFailed: 'queryCurfewFailed',

  downloadVisitorsMovementsInProgress: 'downloadVisitorsMovementsInProgress',
  downloadVisitorsMovementsSuccess: 'downloadVisitorsMovementsSuccess',
  downloadVisitorsMovementsFail: 'downloadVisitorsMovementsFail',
  restartDownloadVisitorState: 'restartDownloadVisitorState'
};

const queryBlacklistMovementsSuccess = createAction(
  types.queryBlacklistMovementsSuccess
);
const queryBlacklistMovementsInProgress = createAction(
  types.queryBlacklistMovementsInProgress
);
const queryBlacklistMovementsFailed = createAction(
  types.queryBlacklistMovementsFailed
);

const queryVisitorsMovementsSuccess = createAction(
  types.queryVisitorsMovementsSuccess
);
const queryVisitorsMovementsInProgress = createAction(
  types.queryVisitorsMovementsInProgress
);
const queryVisitorsMovementsFailed = createAction(
  types.queryVisitorsMovementsFailed
);

const queryResidentsMovementsSuccess = createAction(
  types.queryResidentsMovementsSuccess
);
const queryResidentsMovementsInProgress = createAction(
  types.queryResidentsMovementsInProgress
);
const queryResidentsMovementsFailed = createAction(
  types.queryResidentsMovementsFailed
);

const queryCurfewSuccess = createAction(types.queryCurfewSuccess);
const queryCurfewInProgress = createAction(types.queryCurfewInProgress);
const queryCurfewFailed = createAction(types.queryCurfewFailed);

const downloadVisitorsMovementsInProgress = createAction(
  types.downloadVisitorsMovementsInProgress
);
const downloadVisitorsMovementsSuccess = createAction(
  types.downloadVisitorsMovementsSuccess
);
const downloadVisitorsMovementsFail = createAction(
  types.downloadVisitorsMovementsFail
);
const resetDownloadVisitorState = createAction(
  types.restartDownloadVisitorState
);

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const queryVisitorsMovements = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryVisitorsMovementsInProgress());

    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .queryVisitorsMovements(query)
      .then(response => {
        dispatch(queryVisitorsMovementsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryVisitorsMovementsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const queryBlacklistMovements = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryBlacklistMovementsInProgress());

    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .queryBlacklistMovements(query)
      .then(response => {
        dispatch(queryBlacklistMovementsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryBlacklistMovementsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const openGateWithManualMovement = query => {
  //personalId, pattern, gate, lane, deviceType
  return (dispatch, getState, Api) => {
    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .openGateWithManualMovement(query) //personalId, pattern, gate, lane, deviceType
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
};

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const downloadVisitorsMovements = query => {
  return (dispatch, getState, Api) => {
    dispatch(downloadVisitorsMovementsInProgress());
    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .downloadVisitorsMovements(query)
      .then(response => {
        dispatch(downloadVisitorsMovementsSuccess());
        delay(() => dispatch(resetDownloadVisitorState()), 500);
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(downloadVisitorsMovementsFail());
        delay(() => dispatch(resetDownloadVisitorState()), 3000);
        return Promise.reject(error);
      });
  };
};

const queryResidentsMovements = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryResidentsMovementsInProgress());

    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .queryResidentsMovements(query)
      .then(response => {
        dispatch(queryResidentsMovementsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryResidentsMovementsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const downloadResidentsMovements = query => {
  return (dispatch, getState, Api) => {
    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .downloadResidentsMovements(query)
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
};

const queryCurfew = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryCurfewInProgress());
    dispatch(ProviderActions.getProviders());

    const guardModule = Api.getModule('guard');
    guardModule.adapter.token = getState().session.user.token;
    guardModule.adapter.communityId = getState().session.profile.community.id;

    return guardModule
      .queryCurfew({ ...query, excludeHiddenFromCurfew: true, sort: 'ASC' })
      .then(response => {
        dispatch(queryCurfewSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryCurfewFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  openGateWithManualMovement,
  queryVisitorsMovements,
  queryResidentsMovements,
  queryCurfew,
  downloadVisitorsMovements,
  downloadResidentsMovements,
  queryBlacklistMovements
};
