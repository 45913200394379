import { handleActions } from 'redux-actions';
import { types } from './actions';

const initialState = {
  appHasBooted: false
};

const reducer = handleActions(
  {
    [types.appHasBooted]: state => {
      return {
        ...state,
        appHasBooted: true
      };
    }
  },
  initialState
);

export default reducer;
