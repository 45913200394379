import isString from 'lodash/isString';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';

//BLACKLIST FUNCTIONS
export function getBlacklistFullName(blacklist) {
  return `${blacklist.firstName} ${blacklist.lastName}`;
}

export function getUserRoles(profiles) {
  return uniq(map(profiles, profile => profile.type));
}

export function isUserAuthorized(profiles, allowedRoles) {
  const roles = getUserRoles(profiles);
  return intersection(allowedRoles, roles).length > 0;
}

//STATE SELECTORS

export function getBlacklist(state) {
  return state.blacklist.list;
}

export function getBlacklistById(state, id) {
  let blacklistId = id;
  if (isString(id)) {
    blacklistId = parseInt(id, 10);
  }
  const blacklist = state.list;
  const result = blacklist.filter(blacklist => blacklist.id === blacklistId);
  return result.length > 0 ? result[0] : null;
}

export function getCurrentBlacklist(state) {
  return state.blacklist.currentBlacklist;
}

export function isQueryBlacklistInProgress(state) {
  return state.blacklist.queryBlacklistIsInProgress;
}

export function getQueryBlacklistTotalPages(state) {
  return state.blacklist.totalPages;
}

export function getQueryBlacklistCurrentPage(state) {
  return state.blacklist.currentPage;
}

export function isDeleteBlacklistByIdInProgress(state) {
  return state.blacklist.deleteBlacklistByIdIsInProgress;
}

export function hasCreateBlacklistFailed(state) {
  return state.blacklist.createBlacklistHasFailed;
}

// Selectors for units assignment

export function getCreateBlacklistFullName(state) {
  const blacklist = getCurrentBlacklist(state);
  return blacklist != null
    ? `${blacklist.firstName} ${blacklist.lastName}`
    : '';
}

export function getBlacklistApiError(state) {
  return state.blacklist.apiError;
}

export function hasUpdateBlacklistByIdHasFailed(state) {
  return state.blacklist.updateBlacklistByIdHasFailed;
}
