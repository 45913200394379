import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Crowd Module endpoints
 */
class CrowdModule extends ApiModule {
  /**
   * Blacklist queries
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   */
  queryBlacklist(query) {
    return this.adapter.query('blacklist/list', query);
  }

  findBlacklistById(id) {
    return this.adapter.find(`blacklist/${id}/info`);
  }

  updateBlacklistById(unit) {
    return this.adapter.update(`blacklist/${unit.id}`, unit);
  }

  createBlacklist(unit) {
    return this.adapter.save(`blacklist`, unit);
  }

  deleteBlacklistById(id) {
    return this.adapter.delete(`blacklist/${id}`, false);
  }

  getProviders() {
    const query = {
      all: true
    };
    return this.adapter.query(`visitors/providers`, query);
  }

  setSettings(community, settings) {
    const providers =
      settings.nullProvidersSelected || settings.curfewProvidersSelected;

    const path = `visitors/providers/time/${providers}`;
    return this.adapter.update(path, settings.providerPayloads);
  }
}

export default new CrowdModule('crowd', ApiConfig);
