import { handleActions } from 'redux-actions';
import unionBy from 'lodash/unionBy';
import { types } from './actions';

const initialState = {
  findNationalitiesInProgress: false,
  findNationalitiesHasFailed: false,
  apiErrors: null,
  list: []
};

const reducer = handleActions(
  {
    [types.findNationalitiesInProgress]: state => {
      return {
        ...state,
        findNationalitiesInProgress: true,
        findNationalitiesHasFailed: false,
        apiErrors: null
      };
    },
    [types.findNationalitiesFail]: (state, action) => {
      return {
        ...state,
        findNationalitiesInProgress: false,
        findNationalitiesHasFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.findNationalitiesSuccess]: (state, action) => {
      return {
        ...state,
        findNationalitiesInProgress: false,
        findNationalitiesHasFailed: false,
        apiErrors: null,
        list: unionBy(state.list, action.payload.content, 'countryIsoName')
      };
    }
  },
  initialState
);

export default reducer;
