import { ModuleRegistry } from '@keenvil/littlefinger';
import SecurityModule from './modules/security';
import CommunityModule from './modules/community';
import TownhallModule from './modules/townhall';
import GuardModule from './modules/guard';
import SegmentModule from './modules/segment';
import CrowdModule from './modules/crowd';
import AmenitiesModule from './modules/amenity';

/**
 * Registry object to be inserted in the redux store.
 */
const Api = new ModuleRegistry();
Api.addModule('amenities', AmenitiesModule);
Api.addModule('security', SecurityModule);
Api.addModule('community', CommunityModule);
Api.addModule('townhall', TownhallModule);
Api.addModule('guard', GuardModule);
Api.addModule('segment', SegmentModule);
Api.addModule('crowd', CrowdModule);

export default Api;
