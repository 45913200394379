import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';
import { types as CreateWizardActionsTypes } from './createWizard/actions';

import createWizardReducer, {
  initialState as createWizardInitialState
} from './createWizard/reducers';

const initialState = {
  updateBlacklistByIdIsInProgress: false,
  updateBlacklistByIdHasFailed: false,
  updateBlacklistByIdSuccess: false,
  deleteBlacklistByIdIsInProgress: false,
  deleteBlacklistByIdHasFailed: false,
  deleteBlacklistByIdSuccess: false,
  createBlacklistIsInProgress: false,
  createBlacklistHasFailed: false,
  createBlacklistSuccess: false,
  findBlacklistByIdIsInProgress: false,
  findBlacklistByIdHasFailed: false,
  currentBlacklist: null,
  queryBlacklistIsInProgress: false,
  queryBlacklistHasFailed: false,
  list: [],
  totalPages: -1,
  currentPage: 0,
  apiError: null,
  createWizard: createWizardInitialState
};

const reducer = handleActions(
  {
    ...createWizardReducer,
    [types.deleteBlacklistByIdInProgress]: state => {
      return {
        ...state,
        deleteBlacklistByIdIsInProgress: true,
        deleteBlacklistByIdHasFailed: false,
        deleteBlacklistByIdSuccess: false,
        apiError: null
      };
    },
    [types.deleteBlacklistByIdSuccess]: state => {
      return {
        ...state,
        deleteBlacklistByIdIsInProgress: false,
        deleteBlacklistByIdHasFailed: false,
        deleteBlacklistByIdSuccess: true,
        apiError: null
      };
    },
    [types.deleteBlacklistByIdFailed]: (state, action) => {
      return {
        ...state,
        deleteBlacklistByIdIsInProgress: false,
        deleteBlacklistByIdHasFailed: true,
        deleteBlacklistByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.createBlacklistInProgress]: state => {
      return {
        ...state,
        createBlacklistIsInProgress: true,
        createBlacklistHasFailed: false,
        createBlacklistSuccess: false,
        apiError: null
      };
    },
    [types.createBlacklistSuccess]: state => {
      return {
        ...state,
        createBlacklistIsInProgress: false,
        createBlacklistHasFailed: false,
        createBlacklistSuccess: true,
        apiError: null
      };
    },
    [types.createBlacklistFailed]: (state, action) => {
      return {
        ...state,
        createBlacklistIsInProgress: false,
        createBlacklistHasFailed: true,
        createBlacklistSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.updateBlacklistByIdInProgress]: state => {
      return {
        ...state,
        updateBlacklistByIdIsInProgress: true,
        updateBlacklistByIdHasFailed: false,
        updateBlacklistByIdSuccess: false,
        apiError: null
      };
    },
    [types.updateBlacklistByIdSuccess]: (state, action) => {
      const updatedBlacklist = action.payload;
      const blacklistList = state.list;

      const updatedBlacklistList = blacklistList.map(blacklist => {
        if (blacklist.id === updatedBlacklist) {
          return updatedBlacklist;
        } else {
          return blacklist;
        }
      });

      return {
        ...state,
        updateBlacklistByIdIsInProgress: false,
        updateBlacklistByIdHasFailed: false,
        updateBlacklistByIdSuccess: true,
        list: updatedBlacklistList,
        apiError: null
      };
    },
    [types.updateBlacklistByIdFailed]: (state, action) => {
      return {
        ...state,
        updateBlacklistByIdIsInProgress: false,
        updateBlacklistByIdHasFailed: true,
        updateBlacklistByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.queryBlacklistSuccess]: (state, action) => {
      const blacklist = action.payload;
      return {
        ...state,
        queryBlacklistIsInProgress: false,
        queryBlacklistHasFailed: false,
        list: blacklist.content,
        totalPages: blacklist.totalPages,
        currentPage: blacklist.number,
        apiError: null
      };
    },
    [types.queryBlacklistFailed]: (state, action) => {
      return {
        ...state,
        queryBlacklistIsInProgress: false,
        queryBlacklistHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryBlacklistInProgress]: state => {
      return {
        ...state,
        queryBlacklistIsInProgress: true,
        queryBlacklistHasFailed: false,
        apiError: null
      };
    },
    [types.findBlacklistByIdSuccess]: (state, action) => {
      return {
        ...state,
        findBlacklistByIdIsInProgress: false,
        findBlacklistByIdHasFailed: false,
        currentBlacklist: action.payload,
        apiError: null
      };
    },
    [types.findBlacklistByIdIsInProgress]: state => {
      return {
        ...state,
        findBlacklistByIdIsInProgress: true,
        findBlacklistByIdHasFailed: false,
        apiError: null
      };
    },
    [types.findBlacklistByIdHasFailed]: (state, action) => {
      return {
        ...state,
        findBlacklistByIdIsInProgress: false,
        findBlacklistByIdHasFailed: true,
        currentBlacklist: null,
        apiError: action.payload.apiError
      };
    },
    [CreateWizardActionsTypes.stopCreateBlacklistWizard]: state => {
      return {
        ...state,
        createWizard: createWizardInitialState,
        createBlacklistIsInProgress: false,
        createBlacklistHasFailed: false,
        createBlacklistSuccess: false,
        apiError: null
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
