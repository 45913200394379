import { createAction } from 'redux-actions';
import {
  getUserById,
  getDisabledProfile,
  getUserProfile,
  getCurrentUser,
  getResidentProfiles,
  getUserUnits,
  getUserFullName
} from './selectors';
import { serialize } from './serializer';
import userWizzardAction from '../users/createWizard/actions';
import { isEmpty } from 'lodash';
import unitsActions from '../units/actions';
import {
  allProfileTypes,
  normalProfileTypes,
  statusTypes
} from '../../constants/profileTypes';

const types = {
  queryUsersSuccess: 'queryUsersSuccess',
  queryUsersInProgress: 'queryUsersInProgress',
  queryUsersFailed: 'queryUsersFailed',
  findUserByIdSuccess: 'findUserByIdSuccess',
  findUserByIdInProgress: 'findUserByIdInProgress',
  findUserByIdFailed: 'findUserByIdFailed',
  updateUserByIdSuccess: 'updateUserByIdSuccess',
  updateUserByIdInProgress: 'updateUserByIdInProgress',
  updateUserByIdFailed: 'updateUserByIdFailed',
  deleteUserByIdSuccess: 'deleteUserByIdSuccess',
  deleteUserByIdInProgress: 'deleteUserByIdInProgress',
  deleteUserByIdFailed: 'deleteUserByIdFailed',
  createUserSuccess: 'createUserSuccess',
  createUserInProgress: 'createUserInProgress',
  createUserFailed: 'createUserFailed',
  sendWelcomeEmailInProgress: 'sendWelcomeEmailInProgress',
  sendWelcomeEmailSuccess: 'sendWelcomeEmailSuccess',
  sendWelcomeEmailFailed: 'sendWelcomeEmailFailed',
  resendInvitationEmailInProgress: 'resendInvitationEmailInProgress',
  resendInvitationEmailSuccess: 'resendInvitationEmailSuccess',
  resendInvitationEmailFailed: 'resendInvitationEmailFailed',
  clearEmailSuccess: 'clearEmailSuccess',
  queryUnitsSuccess: 'queryUnitsSuccess',
  queryUnitsInProgress: 'queryUnitsInProgress',
  queryUnitsFailed: 'queryUnitsFailed',
  clearUnits: 'clearUnits',
  addUnitToSelection: 'addUnitToSelection',
  removeUnitFromSelection: 'removeUnitFromSelection',
  updateSelectedUnitRelationship: 'updateSelectedUnitRelationship',
  assignUnitsToUserSuccess: 'assignUnitsToUserSuccess',
  assignUnitsToUserInProgress: 'assignUnitsToUserInProgress',
  assignUnitsToUserFailed: 'assignUnitsToUserFailed',
  unassignUnitFromUserSuccess: 'unassignUnitFromUserSuccess',
  unassignUnitFromUserInProgress: 'unassignUnitFromUserInProgress',
  unassignUnitFromUserFailed: 'unassignUnitFromUserFailed',
  changeIndividualStatusInCommunityInProgress:
    'changeIndividualStatusInCommunityInProgress',
  changeIndividualStatusInCommunitySuccess:
    'changeIndividualStatusInCommunitySuccess',
  changeIndividualStatusInCommunityFailed:
    'changeIndividualStatusInCommunityFailed',
  isAssignVehicleInProgress: 'isAssignVehicleInProgress',
  assignVehicleToUserSuccess: 'assignVehicleToUserSuccess',
  hasAssignVehicleFailed: 'hasAssignVehicleFailed',
  hasUnassignVehicleFromUserFailed: 'hasUnassignVehicleFromUserFailed',
  isUnassignVehicleFromUserInProgress: 'isUnassignVehicleFromUserInProgress',
  unassignVehicleToUserSuccess: 'unassignVehicleToUserSuccess',
  editVehicle: 'editVehicle',
  editVehicleInProgress: 'editVehicleInProgress',
  editVehicleSuccess: 'editVehicleSuccess',
  editVehicleFailed: 'editVehicleFailed',
  uploadPetFileFail: 'uploadPetFileFail',
  uploadPetFileInProgress: 'uploadPetFileInProgress',
  uploadPetFileSuccess: 'uploadPetFileSuccess',
  createNewPetFail: 'createNewPetFail',
  createNewPetinProgress: 'createNewPetinProgress',
  createNewPetSuccess: 'createNewPetSuccess',
  updatePetInProgress: 'updatePetInProgress',
  updatePetSuccess: 'updatePetSuccess',
  updatePetFail: 'updatePetFail',
  setIndividualForPet: 'setIndividualForPet',
  setTotalGuards: 'setTotalGuards',
  setTotalEnabledUsers: 'setTotalEnabledUsers',
  setTotalDisabledUsers: 'setTotalDisabledUsers',
  assingExistingUserInProgress: 'assingExistingUserInProgress',
  assingExistingUserSuccess: 'assingExistingUserSuccess',
  assingExistingUserFail: 'assingExistingUserFail'
};

const createNewPetFail = createAction(types.createNewPetFail);
const createNewPetinProgress = createAction(types.createNewPetinProgress);
const createNewPetSuccess = createAction(types.createNewPetSuccess);

const updatePetInProgress = createAction(types.updatePetInProgress);
const updatePetSuccess = createAction(types.updatePetSuccess);
const updatePetFail = createAction(types.updatePetFail);

const editVehicleInProgress = createAction(types.editVehicleInProgress);
const editVehicleSuccess = createAction(types.editVehicleSuccess);
const editVehicleFailed = createAction(types.editVehicleFailed);

const uploadPetFileFail = createAction(types.uploadPetFileFail);
const uploadPetFileInProgress = createAction(types.uploadPetFileInProgress);
const uploadPetFileSuccess = createAction(types.uploadPetFileSuccess);
const queryUsersSuccess = createAction(types.queryUsersSuccess);
const queryUsersInProgress = createAction(types.queryUsersInProgress);
const queryUsersFailed = createAction(types.queryUsersFailed);
const findUserByIdSuccess = createAction(types.findUserByIdSuccess);
const findUserByIdInProgress = createAction(types.findUserByIdInProgress);
const findUserByIdFailed = createAction(types.findUserByIdFailed);
const updateUserByIdSuccess = createAction(types.updateUserByIdSuccess);
const updateUserByIdInProgress = createAction(types.updateUserByIdInProgress);
const updateUserByIdFailed = createAction(types.updateUserByIdFailed);
const deleteUserByIdSuccess = createAction(types.deleteUserByIdSuccess);
const deleteUserByIdInProgress = createAction(types.deleteUserByIdInProgress);
const deleteUserByIdFailed = createAction(types.deleteUserByIdFailed);
const createUserSuccess = createAction(types.createUserSuccess);
const createUserInProgress = createAction(types.createUserInProgress);
const createUserFailed = createAction(types.createUserFailed);
const sendWelcomeEmailInProgress = createAction(
  types.sendWelcomeEmailInProgress
);
const sendWelcomeEmailSuccess = createAction(types.sendWelcomeEmailSuccess);
const sendWelcomeEmailFailed = createAction(types.sendWelcomeEmailFailed);
const resendInvitationEmailInProgress = createAction(
  types.resendInvitationEmailInProgress
);
const resendInvitationEmailSuccess = createAction(
  types.resendInvitationEmailSuccess
);
const resendInvitationEmailFailed = createAction(
  types.resendInvitationEmailFailed
);
const clearEmailSuccess = createAction(types.clearEmailSuccess);
const queryUnitsSuccess = createAction(types.queryUnitsSuccess);
const queryUnitsInProgress = createAction(types.queryUnitsInProgress);
const queryUnitsFailed = createAction(types.queryUnitsFailed);
const clearUnits = createAction(types.clearUnits);
const addUnitToSelection = createAction(types.addUnitToSelection);
const removeUnitFromSelection = createAction(types.removeUnitFromSelection);
const updateSelectedUnitRelationship = createAction(
  types.updateSelectedUnitRelationship
);
const assignUnitsToUserSuccess = createAction(types.assignUnitsToUserSuccess);
const assignUnitsToUserInProgress = createAction(
  types.assignUnitsToUserInProgress
);
const assignUnitsToUserFailed = createAction(types.assignUnitsToUserFailed);
const unassignUnitFromUserSuccess = createAction(
  types.unassignUnitFromUserSuccess
);
const unassignUnitFromUserInProgress = createAction(
  types.unassignUnitFromUserInProgress
);
const unassignUnitFromUserFailed = createAction(
  types.unassignUnitFromUserFailed
);

const changeIndividualStatusInCommunityInProgress = createAction(
  types.changeIndividualStatusInCommunityInProgress
);

const changeIndividualStatusInCommunitySuccess = createAction(
  types.changeIndividualStatusInCommunitySuccess
);

const changeIndividualStatusInCommunityFailed = createAction(
  types.changeIndividualStatusInCommunityFailed
);

const isUnassignVehicleFromUserInProgress = createAction(
  types.isUnassignVehicleFromUserInProgress
);
const assignVehicleToUserSuccess = createAction(
  types.assignVehicleToUserSuccess
);
const hasAssignVehicleFailed = createAction(types.hasAssignVehicleFailed);

const isAssignVehicleInProgress = createAction(types.isAssignVehicleInProgress);
const unassignVehicleToUserSuccess = createAction(
  types.unassignVehicleToUserSuccess
);
const hasUnassignVehicleFromUserFailed = createAction(
  types.hasUnassignVehicleFromUserFailed
);

const assingExistingUserInProgress = createAction(
  types.assingExistingUserInProgress
);
const assingExistingUserSuccess = createAction(types.assingExistingUserSuccess);
const assingExistingUserFail = createAction(types.assingExistingUserFail);

const setTotalGuards = createAction(types.setTotalGuards);
const setTotalEnabledUsers = createAction(types.setTotalEnabledUsers);
const setTotalDisabledUsers = createAction(types.setTotalDisabledUsers);

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const countUsers = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    communityModule
      .queryUsers({
        type: allProfileTypes.SECURITY,
        status: statusTypes.ENABLED
      })
      .then(response => {
        const totalElements = response.individuals.totalElements;
        dispatch(setTotalGuards(totalElements));
      });

    communityModule
      .queryUsers({ status: statusTypes.DISABLED })
      .then(response => {
        const totalElements = response.individuals.totalElements;
        dispatch(setTotalDisabledUsers(totalElements));
      });

    communityModule
      .queryUsers({
        type: allProfileTypes.RESIDENT,
        status: statusTypes.ENABLED
      })
      .then(response => {
        const totalElements = response.individuals.totalElements;
        dispatch(setTotalEnabledUsers(totalElements));
      });

    return Promise.resolve();
  };
};

const queryUsers = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryUsersInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .queryUsers(query)
      .then(response => {
        dispatch(queryUsersSuccess(response));
        const type = query.type;
        const status = query.status;

        const totalElements = response.individuals.totalElements;

        if (status === statusTypes.ENABLED) {
          if (type === allProfileTypes.SECURITY) {
            dispatch(setTotalGuards(totalElements));
          } else {
            dispatch(setTotalEnabledUsers(totalElements));
          }
        } else {
          dispatch(setTotalDisabledUsers(totalElements));
        }
      })
      .catch(error => {
        dispatch(
          queryUsersFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findUserById = id => {
  return (dispatch, getState, Api) => {
    dispatch(findUserByIdInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .findUserById(id)
      .then(response => {
        const normalProfiles = response.profiles.filter(
          profile => !isEmpty(normalProfileTypes[profile.type])
        );
        const unitsIds = normalProfiles.map(profile => profile?.unit?.id);

        if (!isEmpty(unitsIds)) {
          dispatch(
            unitsActions.findMultipleUnitsByIds(unitsIds, unitsIds, response)
          );
        } else {
          dispatch(findUserByIdSuccess(response));
        }
      })
      .catch(error => {
        dispatch(
          findUserByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const updateUserById = user => {
  return (dispatch, getState, Api) => {
    dispatch(updateUserByIdInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const serializedUser = serialize(user);

    return communityModule
      .updateUserById(serializedUser)
      .then(response => {
        dispatch(updateUserByIdSuccess(response));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          updateUserByIdFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject(error);
      });
  };
};

const deleteUserById = user => {
  return (dispatch, getState, Api) => {
    dispatch(deleteUserByIdInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .deleteUserById(user)
      .then(response => {
        dispatch(deleteUserByIdSuccess(response));
      })
      .catch(error => {
        dispatch(
          deleteUserByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const uploadPetImage = image => {
  return (dispatch, getState, Api) => {
    dispatch(uploadPetFileInProgress());

    const securityModule = Api.getModule('security');
    securityModule.adapter.token = getState().session.user.token;
    securityModule.adapter.communityId = getState().session.profile.community.id;

    return securityModule
      .uploadFile({
        name: image.name,
        bytes: image.image
      })
      .then(response => {
        dispatch(uploadPetFileSuccess(response));
      })
      .catch(error => {
        dispatch(
          uploadPetFileFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const createNewPet = petObject => {
  return (dispatch, getState, Api) => {
    dispatch(createNewPetinProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .addNewPetToUser(petObject)
      .then(response => {
        dispatch(createNewPetSuccess(response));
        dispatch(findUserById(petObject.userId));
      })
      .catch(error => {
        dispatch(
          createNewPetFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const createUser = (
  user,
  type,
  units,
  guardProfile,
  contractsPerUnits,
  specificUnit = false
) => {
  return (dispatch, getState, Api) => {
    dispatch(createUserInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const serializedUser = serialize(
      user,
      type,
      units,
      guardProfile,
      contractsPerUnits
    );

    const queryType = type != 'resident' ? 'SECURITY' : 'RESIDENT';

    return communityModule
      .createUser(serializedUser)
      .then(response => {
        console.warn('specificUnit ', specificUnit);
        if (specificUnit) {
          const unit = getState().users.createWizard.selectedUnits[0];
          dispatch(unitsActions.findUnitById(unit.id)).then(res => {
            dispatch(userWizzardAction.closeCreateUserWizard());
          });
        } else {
          dispatch(
            queryUsers({
              page: 0,
              limit: 25,
              type: queryType,
              status: statusTypes.ENABLED
            })
          );
        }
        dispatch(createUserSuccess(response));
        dispatch(userWizzardAction.closeCreateUserWizard());
        return response;
      })
      .catch(error => {
        dispatch(
          createUserFailed({
            apiError: error.apiErrors
          })
        );
        throw error;
      });
  };
};

const sendWelcomeEmail = userId => {
  return (dispatch, getState, Api) => {
    dispatch(sendWelcomeEmailInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .sendWelcomeEmail(userId)
      .then(response => {
        dispatch(sendWelcomeEmailSuccess(response));
        return response;
      })
      .catch(error => {
        dispatch(
          sendWelcomeEmailFailed({
            apiError: error.apiErrors
          })
        );
        throw error;
      });
  };
};

const resendInvitationEmail = () => {
  return (dispatch, getState, Api) => {
    dispatch(resendInvitationEmailInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .resendInvitationEmail()
      .then(response => {
        dispatch(resendInvitationEmailSuccess(response));
        return response;
      })
      .catch(error => {
        dispatch(
          resendInvitationEmailFailed({
            apiError: error.apiErrors
          })
        );
        throw error;
      });
  };
};

/**
 * Query the units of the active community.
 * @param {Object} queryObject is the query sent by the auto-complete
 *                              component
 */
const queryUnits = queryObject => {
  return (dispatch, getState, Api) => {
    dispatch(queryUnitsInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const query = {
      q: queryObject.value,
      empty: false
    };

    return communityModule
      .queryUnits(query)
      .then(response => {
        dispatch(queryUnitsSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryUnitsFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const assignUnitsToUser = () => {
  return (dispatch, getState, Api) => {
    dispatch(assignUnitsToUserInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const { users } = getState();

    const { currentUser, currentUserSelectedUnits } = users;

    const serializedUnits = currentUserSelectedUnits.map(unit => {
      return {
        id: unit.id,
        profileType: unit.relationship
      };
    });

    return communityModule
      .assignUnitsToUser(currentUser.id, serializedUnits)
      .then(response => {
        dispatch(assignUnitsToUserSuccess(response));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          assignUnitsToUserFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const assignUnitsWithContracts = (
  user,
  units,
  contractsPerUnits,
  withSpecificUnit = false
) => {
  return (dispatch, getState, Api) => {
    dispatch(assignUnitsToUserInProgress());

    const contracts = contractsPerUnits.map(contract => contract.contractData);

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const serializedUser = serialize(user, 'resident', units, null, contracts);

    return communityModule
      .createUser(serializedUser)
      .then(response => {
        dispatch(assignUnitsToUserSuccess(response));
        if (withSpecificUnit) {
          const unit = getState().users.createWizard.selectedUnits[0];
          dispatch(unitsActions.findUnitById(unit.id)).then(res => {
            dispatch(userWizzardAction.closeCreateUserWizard());
          });
        }
        dispatch(findUserById(user.id, true));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          assignUnitsToUserFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject(error);
      });
  };
};

const unassignUnitFromUser = (userId, unitId) => {
  return (dispatch, getState, Api) => {
    dispatch(unassignUnitFromUserInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .unassignUnitFromUser(userId, unitId)
      .then(() => {
        dispatch(unassignUnitFromUserSuccess());
        //Fetches user again to bring the updated profile...
        dispatch(findUserById(userId, true));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(
          unassignUnitFromUserFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const editVehicle = (ids, newData) => {
  return (dispatch, getState, Api) => {
    dispatch(editVehicleInProgress());
    //ids contain {user, vehicle, unit}
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .editVehicle(ids.user, ids.unit, ids.vehicle, newData)
      .then(res => {
        dispatch(editVehicleSuccess(res));
        dispatch(findUserById(ids.user));
      })
      .catch(err => {
        dispatch(editVehicleFailed(err.apiError));
      });
  };
};

const changeIndividualStatusInCommunity = (userId, status) => {
  return (dispatch, getState, Api) => {
    dispatch(changeIndividualStatusInCommunityInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    let user = getUserById(getState().users, userId);
    const currentUser = getCurrentUser(getState());

    if (!user) {
      if (currentUser.id !== userId) {
        return Promise.resolve();
      } else {
        user = currentUser;
      }
    }

    let profile;

    if (status === 'DISABLED') {
      profile = getUserProfile(user);
    } else {
      profile = getDisabledProfile(user);
    }

    return communityModule
      .changeIndividualStatus(user.id, profile.id, { status })
      .then(response => {
        dispatch(
          changeIndividualStatusInCommunitySuccess({
            individual: response
          })
        );
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(
          changeIndividualStatusInCommunityFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const enableUnitStatusByProfile = (userId, profileId) => {
  return (dispatch, getState, Api) => {
    dispatch(changeIndividualStatusInCommunityInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const currentUser = getCurrentUser(getState());
    const accountActive = currentUser.accountInfo.status === statusTypes.ACTIVE;

    return communityModule
      .changeIndividualStatus(userId, profileId, {
        status: statusTypes.ENABLED
      })
      .then(response => {
        dispatch(
          changeIndividualStatusInCommunitySuccess({
            individual: response
          })
        );
        if (!accountActive) {
          dispatch(sendWelcomeEmail(userId));
        }
        dispatch(findUserById(currentUser.id));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(
          changeIndividualStatusInCommunityFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const disableUnitStatusByProfile = (userId, profileId) => {
  return (dispatch, getState, Api) => {
    dispatch(changeIndividualStatusInCommunityInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const currentUser = getCurrentUser(getState());

    return communityModule
      .changeIndividualStatus(userId, profileId, {
        status: statusTypes.DISABLED
      })
      .then(response => {
        dispatch(
          changeIndividualStatusInCommunitySuccess({
            individual: response
          })
        );
        dispatch(findUserById(currentUser.id));
        return Promise.resolve();
      })
      .catch(error => {
        dispatch(
          changeIndividualStatusInCommunityFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const assignVehicleToUser = (unitId, vehicle, currentUser) => {
  return (dispatch, getState, Api) => {
    dispatch(isAssignVehicleInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .assignVehicle(currentUser.id, unitId, vehicle)
      .then(response => {
        dispatch(assignVehicleToUserSuccess(response));
        dispatch(findUserById(currentUser.id));
      })
      .catch(error => {
        dispatch(
          hasAssignVehicleFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const unassignVehicleFromUser = (userId, vehicle) => {
  return async (dispatch, getState, Api) => {
    dispatch(isUnassignVehicleFromUserInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const currentUser = getCurrentUser(getState());
    const units = getUserUnits(getResidentProfiles(currentUser.profiles));
    if (units.length === 0) {
      Promise.reject();
    }
    const vehicleUnit = await communityModule.getVehicleUnit(
      vehicle.individualGroup
    );
    await communityModule.unassignVehicle(
      currentUser.id,
      vehicleUnit.unit,
      vehicle.id
    );
    try {
      const response = await communityModule.getUserData(currentUser.id);
      if (response) {
        dispatch(unassignVehicleToUserSuccess(response));
        dispatch(findUserById(userId));
      }
    } catch (error) {
      dispatch(
        hasUnassignVehicleFromUserFailed({
          apiError: error.apiErrors
        })
      );
    }
  };
};

const changePasswordByAdmin = (accountId, newPassword) => {
  return (dispatch, getState, Api) => {
    const securityModule = Api.getModule('security');
    securityModule.adapter.token = getState().session.user.token;
    securityModule.adapter.communityId = getState().session.profile.community.id;

    return securityModule
      .changeUserPasswordByAdmin(accountId, newPassword)
      .then(res => {})
      .catch(err => {});
  };
};

const updatePet = pet => {
  return (dispatch, getState, Api) => {
    dispatch(updatePetInProgress());
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .updatePet(pet)
      .then(res => {
        dispatch(updatePetSuccess(res));
        dispatch(findUserById(pet.userId));
      })
      .catch(err => {
        dispatch(updatePetFail(err.apiErrors));
      });
  };
};

const getIndividual = individualId => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule.getIndividual(individualId);
  };
};

const deletePet = (petId, userId, individualGroup) => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;
    const aux = group => dispatch(getIndividual(group));
    aux(individualGroup)
      .then(res => {
        return communityModule
          .deletePet(petId, userId, res?.unit)
          .then(() => {
            dispatch(findUserById(userId));
          })
          .catch(err => {});
      })
      .catch(() => false);
  };
};

export { types };
export default {
  deletePet,
  getIndividual,
  updatePet,
  queryUsers,
  findUserById,
  assignUnitsWithContracts,
  updateUserById,
  deleteUserById,
  createUser,
  sendWelcomeEmail,
  clearEmailSuccess,
  resendInvitationEmail,
  queryUnits,
  clearUnits,
  addUnitToSelection,
  removeUnitFromSelection,
  updateSelectedUnitRelationship,
  assignUnitsToUser,
  unassignUnitFromUser,
  changeIndividualStatusInCommunity,
  assignVehicleToUser,
  unassignVehicleFromUser,
  uploadPetImage,
  createNewPet,
  changePasswordByAdmin,
  editVehicle,
  findUserByIdSuccess,
  enableUnitStatusByProfile,
  disableUnitStatusByProfile,
  countUsers,
  assingExistingUserInProgress,
  assignUnitsToUserSuccess,
  assingExistingUserFail
};
