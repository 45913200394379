import find from 'lodash/find';

/**
 * Transforms the segment API's representation into
 * a representation suitable for the UI
 */
export function unserialize(apiSegment) {
  const segment = {};
  segment.id = apiSegment.id;
  segment.created = apiSegment.created;
  segment.name = apiSegment.name;
  const rangeFilters = apiSegment.rangeFilters.map(filter => {
    const unserializedFilter = {
      attribute: filter.attribute,
      typeRange: filter.typeRange,
      typeDate: filter.typeDate
    };
    if (filter.typeRange === 'BETWEEN') {
      unserializedFilter.fromValue = filter.betweenFromValue;
      unserializedFilter.toValue = filter.toFromValue;
    } else {
      unserializedFilter.fromValue = filter.value;
    }
    return unserializedFilter;
  });
  const booleanFilters = apiSegment.booleanFilters.map(filter => {
    return {
      attribute: filter.attribute,
      typeRange: filter.typeBoolean,
      fromValue: filter.value.toUpperCase()
    };
  });
  segment.filters = [...rangeFilters, ...booleanFilters];
  return segment;
}

/**
 * Takes the segment object representation and transforms it to what the
 * API expects.
 * @param {Object} segment
 */
export function serialize(segment, state) {
  const filters = segment.filters.map(filter => {
    const filterDto = {
      attribute: filter.attribute,
      typeRange: filter.typeRange
    };
    if (filter.typeRange === 'BETWEEN') {
      filterDto.betweenFromValue = filter.fromValue;
      filterDto.betweenToValue = filter.toValue;
    } else {
      filterDto.value = filter.fromValue;
    }
    return filterDto;
  });

  const rangeFilters = mapRangeFilters(filters, state);
  const booleanFilters = mapBooleanFilters(filters, state);

  const serializedSegment = {
    ...(segment.id && { id: segment.id }),
    name: segment.name,
    rangeFilters,
    booleanFilters
  };

  return serializedSegment;
}

function getFilterType(state, filter) {
  const filters = state.segments.filters;
  const collectionFilter = find(filters, { attribute: filter.attribute });
  return collectionFilter && collectionFilter.type;
}

export function mapRangeFilters(filters, state) {
  const filteredFilters = filters.filter(filter => {
    return getFilterType(state, filter) === 'RANGE';
  });

  return filteredFilters.map(filter => {
    const serializedFilter = {
      filterType: 'RANGE',
      typeDate: 'YEARS',
      ...filter
    };
    if (filter.typeRange === 'BETWEEN') {
      serializedFilter.betweenFromValue = parseInt(filter.betweenFromValue, 10);
      serializedFilter.betweenToValue = parseInt(filter.betweenToValue, 10);
    } else {
      serializedFilter.value = parseInt(filter.value, 10);
    }
    return serializedFilter;
  });
}

export function mapBooleanFilters(filters, state) {
  const filteredFilters = filters.filter(filter => {
    return getFilterType(state, filter) === 'BOOLEAN';
  });

  return filteredFilters.map(filter => {
    filter.filterType = 'BOOLEAN';
    filter.typeBoolean = filter.typeRange;
    return filter;
  });
}
