import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

class PrivateRoute extends PureComponent {
  render() {
    const { component: Component, hasValidSession, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          hasValidSession ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          )
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  hasValidSession: PropTypes.bool
};

export default PrivateRoute;
