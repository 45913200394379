import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
//import * as Icon from 'react-feather';
import * as Icon from '@heroicons/react/outline';

import './styles.css';

/**
 * Side navigation component. Provides an experience such as
 * an Off Canvas.
 */
const OffCanvasItem = ({ label, icon, linkTo, isActive, isExpanded }) => {
  const itemClass = classNames('kv-offcanvas__item', {
    'kv-offcanvas__item--active': isActive
  });
  return (
    <Link className={itemClass} to={linkTo}>
      {icon === 'home' ? (
        <Icon.HomeIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'users' ? (
        <Icon.UserIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'chat' ? (
        <Icon.NewspaperIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'arrows' ? (
        <Icon.SwitchHorizontalIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'box' ? (
        <Icon.UserGroupIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'calendar' ? (
        <Icon.CalendarIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'settings' ? (
        <Icon.AdjustmentsIcon className="kv-offcanvas__item__icon" />
      ) : icon === 'blacklist' ? (
        <Icon.BanIcon className="kv-offcanvas__item__icon" />
      ) : null}
      <span>{label}</span>
    </Link>
  );
};

const OffCanvasNavigation = ({ children, expanded }) => {
  const menuClass = classNames('kv-offcanvas', {
    'kv-offcanvas--expanded': expanded
  });
  return <div className={menuClass}>{children}</div>;
};

OffCanvasItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isExpanded: PropTypes.bool
};

OffCanvasItem.defaultProps = {
  label: '',
  icon: 'question',
  linkTo: '/',
  isActive: false,
  isExpanded: false
};

OffCanvasNavigation.defaultProps = {
  expanded: false
};

OffCanvasNavigation.propTypes = {
  expanded: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element)
};

export { OffCanvasItem };
export default OffCanvasNavigation;
