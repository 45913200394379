import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Townhall Module endpoints
 */
class TownhallModule extends ApiModule {
  /**
   * Queries the articles lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   * @return {Object} The list of articles that match the query.
   */
  queryArticles(query) {
    return this.adapter.query('articles', query);
  }

  /**
   * Finds an Article by ID.
   * @param {String} id The article ID to find by.
   * @return {Object} the article identified by the ID or 404
   *                  if no article exists.
   */
  findArticleById(id) {
    return this.adapter.find(`articles/${id}`);
  }

  /**
   * Creates an Article.
   * @param {Object} article The article to be created.
   * @return {Object} the newly created article.
   */
  createArticle(article) {
    return this.adapter.save('articles', article);
  }

  /**
   * Updates an Article.
   * @param {Object} article The article to update.
   * @return {Object} the updated article.
   */
  updateArticleById(article) {
    return this.adapter.update(`articles/${article.id}`, article);
  }

  /**
   * Deletes an Article by ID.
   * @param {String} id The ID of the article to delete.
   * @return nothing.
   */
  deleteArticleById(type = 'articles', id) {
    return this.adapter.delete(`${type}/${id}`, false);
  }

  /**
   * Uploads a file to attach to an article.
   * @param {Object} fileData The file data to upload.
   * @return {Object} The uploaded file URI.
   */
  uploadArticleFile(fileData) {
    return this.adapter.upload(`articles/upload`, fileData);
  }

  /**
   * Finds a Poll by ID.
   * @param {String} id The poll ID to find by.
   * @return {Object} the poll identified by the ID or 404
   *                  if no poll exists.
   */
  findPollById(id) {
    return this.adapter.find(`polls/${id}`);
  }

  /**
   * Creates a Poll.
   * @param {Object} poll The poll to be created.
   * @return {Object} the newly created poll.
   */
  createPoll(poll) {
    return this.adapter.save('polls', poll);
  }

  /**
   * Updates a Poll.
   * @param {Object} poll The poll to update.
   * @return {Object} the updated poll.
   */
  updatePollById(poll) {
    return this.adapter.update(`polls/${poll.id}`, poll);
  }

  /**
   * Deletes a Poll by ID.
   * @param {String} id The ID of the poll to delete.
   * @return nothing.
   */
  deletePollById(id) {
    return this.adapter.delete(`polls/${id}`, false);
  }

  /**
   * Finds an Event by ID.
   * @param {String} id The event ID to find by.
   * @return {Object} the event identified by the ID or 404
   *                  if no event exists.
   */
  findEventById(id) {
    return this.adapter.find(`events/${id}`);
  }

  /**
   * Creates an Event.
   * @param {Object} event The event to be created.
   * @return {Object} the newly created event.
   */
  createEvent(event) {
    return this.adapter.save('events', event);
  }

  /**
   * Updates an Event.
   * @param {Object} event The event to update.
   * @return {Object} the updated event.
   */
  updateEventById(event) {
    return this.adapter.update(`events/${event.id}`, event);
  }

  /**
   * Deletes an Event by ID.
   * @param {String} id The ID of the event to delete.
   * @return nothing.
   */
  deleteEventById(id) {
    return this.adapter.delete(`events/${id}`, false);
  }

  /**
   * Queries the messages lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   * @return {Object} The list of articles that match the query.
   */
  queryMessages(query) {
    return this.adapter.query('messages', query);
  }

  /**
   * Finds a Quiz by ID.
   * @param {String} id The quiz ID to find by.
   * @return {Object} the quiz identified by the ID or 404
   *                  if no quiz exists.
   */
  findQuizById(id) {
    return this.adapter.find(`polls/${id}`);
  }

  /**
   * Creates a Quiz.
   * @param {Object} quiz The quiz to be created.
   * @return {Object} the newly created quiz.
   */
  createQuiz(quiz) {
    return this.adapter.save('quizzes', quiz);
  }

  /**
   * Updates a Quiz.
   * @param {Object} quiz The quiz to update.
   * @return {Object} the updated quiz.
   */
  updateQuiz(quiz) {
    return this.adapter.update(`quizzes/${quiz.id}`, quiz);
  }

  /**
   * Deletes a Quiz by ID.
   * @param {String} id The ID of the quiz to delete.
   * @return nothing.
   */
  deleteQuiz(id) {
    return this.adapter.delete(`quizzes/${id}`, false);
  }
}

export default new TownhallModule('townhall', ApiConfig);
