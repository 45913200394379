import isString from 'lodash/isString';
import map from 'lodash/map';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';
import some from 'lodash/some';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import differenceInYears from 'date-fns/differenceInYears';
import {
  normalProfileTypes,
  readableGuardProfiles,
  statusTypes,
  allProfileTypes
} from '../../constants/profileTypes';

//USER FUNCTIONS

export function getResidentProfiles(profiles) {
  return profiles.filter(profile => !isEmpty(normalProfileTypes[profile.type]));
}

export function getEnabledResidentProfiles(profiles) {
  return profiles.filter(
    profile =>
      !isEmpty(normalProfileTypes[profile.type]) &&
      profile.status == statusTypes.ENABLED
  );
}

export function getDisabledResidentProfiles(profiles) {
  return profiles.filter(
    profile =>
      !isEmpty(normalProfileTypes[profile.type]) &&
      profile.status == statusTypes.DISABLED
  );
}

export function getSecurityProfiles(profiles) {
  const securityProfiles = Object.keys(readableGuardProfiles);
  return profiles.filter(profile => securityProfiles.includes(profile.type));
}

export function getLotName(lot) {
  return get(lot, 'lotNumber', '');
}

export function getUserRolesNames(profiles) {
  return uniq(map(profiles, profile => mapUserRoleToName(profile.type)));
}

export function getUserRoles(profiles) {
  return uniq(map(profiles, profile => profile.type));
}

export function isUserAuthorized(profiles, allowedRoles) {
  const roles = getUserRoles(profiles);
  return intersection(allowedRoles, roles).length > 0;
}

export function getUserLots(profiles) {
  return map(profiles, profile => getLotName(profile.unit));
}

export function getUserUnits(profiles) {
  return map(profiles, profile => profile.unit);
}

export function getUserUnitsForTable(profiles) {
  return map(
    filter(profiles, profile => profile.unit != null),
    profile => {
      const unit = profile.unit;
      return {
        id: unit.id ? unit.id : null,
        unitNumber: unit.unitNumber ? unit.unitNumber : '-',
        lotNumber: getLotName(unit),
        type: getUnitType(unit),
        address: unit.address ? unit.address : '-',
        status: getUnitStatus(unit),
        role: mapUserRoleToName(profile.type),
        owner: unit.name ? unit.name : '-'
      };
    }
  );
}

export function getWizardSelectedUnits(state) {
  return state.users.createWizard?.selectedUnits;
}

export function getUserVehiclesForTable(user) {
  return user.vehicles;
}

export function getUserFullName(user) {
  return `${user?.firstName} ${user?.lastName}`;
}

export function getSessionUserFullName(state) {
  return getUserFullName(state.session.user);
}

export function getUserProfilesByType(user, profileType) {
  return user.profiles.filter(
    profile => profile.status === 'ENABLED' && profile.type === profileType
  );
}

export function getUnitType(unit) {
  switch (unit.type) {
    case 'SHOP':
      return 'Negocio';
    case 'HOUSE':
      return 'Casa';
    case 'APARTMENT':
      return 'Departamento';
    case 'PARK':
      return 'Parque';
    case 'SUM':
      return 'SUM';
    case 'CLUB_HOUSE':
      return 'Club House';
    case 'AMENITY':
      return 'Amenity';
    case 'OTHER':
      return 'Otro';
    default:
      return '-';
  }
}

export function getUnitStatus(unit) {
  switch (unit.status) {
    case 'EMPTY':
      return 'Vacía';
    case 'IN_CONSTRUCTION':
      return 'En construcción';
    case 'TAKEN':
      return 'Ocupada';
    default:
      return '-';
  }
}

export function mapProfileToUnitsPerRole(profiles) {
  return map(
    filter(profiles, profile => profile.lot != null && profile.lot.lotNumber),
    profile => {
      return {
        id: profile.id,
        lot: getLotName(profile.lot),
        role: mapUserRoleToName(profile.type)
      };
    }
  );
}

export function mapUserRoleToName(role) {
  switch (role) {
    case 'TENANT':
      return 'Inquilino';
    case 'OWNER':
      return 'Propietario';
    case 'CORESIDENT':
      return 'Coresidente';
    case 'GUARD':
      return 'Guardia';
    case 'SUPERVISOR':
      return 'Supervisor';
    case 'ADMIN':
      return 'Admin';
    default:
      return 'N/A';
  }
}

export function mapUserRelationshipToName(role) {
  switch (role) {
    case 'CHILD':
      return 'Hijo/a';
    case 'COUPLE':
      return 'Pareja';
    case 'FAMILY':
      return 'Familiar';
    case 'PARENT':
      return 'Padre/Madre';
    case 'PARTNER':
      return 'Compañero/a';
    case 'OTHER':
      return 'Otro';
    case 'OWNER':
      return 'Propietario/a';
    default:
      return 'N/A';
  }
}

export function mapVisitorRelationshipToName(role, providerType) {
  switch (role) {
    case 'EMPLOYEE':
      if (providerType) {
        return providerType.name;
      } else {
        return 'Empleado/Proveedor';
      }
    case 'DELIVERY':
      return 'Delivery';
    case 'FAMILY':
      return 'Familiar';
    case 'SERVICE':
      return 'Servicio';
    default:
      return 'N/A';
  }
}

//STATE SELECTORS

export function getUsers(state) {
  return state.users.list;
}

export function getUserById(state, id) {
  let userId = id;
  if (isString(id)) {
    userId = parseInt(id, 10);
  }
  const users = state.list;
  const result = users.filter(user => user.id === userId);
  return result.length > 0 ? result[0] : null;
}

export function getCurrentUser(state) {
  return state.users.currentUser;
}

export function isQueryUsersInProgress(state) {
  return state.users.queryUsersIsInProgress;
}

export function getQueryUsersTotalPages(state) {
  return state.users.totalPages;
}

export function getQueryUsersCurrentPage(state) {
  return state.users.currentPage;
}

export function isDeleteUserByIdInProgress(state) {
  return state.users.deleteUserByIdIsInProgress;
}

export function hasCreateUserFailed(state) {
  return state.users.createUserHasFailed;
}

export function hasSendWelcomeEmailSucceded(state) {
  return state.users.sendWelcomeEmailSuccess;
}

export function isCurrentUserOfProfileType(state, profileType) {
  const currentUserProfiles = get(state, 'users.currentUser.profiles');
  return (
    currentUserProfiles &&
    some(currentUserProfiles, profile => profile.type === profileType)
  );
}

// Selectors for units assignment

export function getCreateUserFullName(state) {
  const user = getCurrentUser(state);
  return user != null ? `${user.firstName} ${user.lastName}` : '';
}

export function getQueriedUnits(state) {
  return state.users.currentUserUnits;
}

export function getSelectedUnits(state) {
  return state.users.currentUserSelectedUnits;
}

export function hasUnitsWithoutRelationship(state) {
  const unitsWithoutRelationship = getSelectedUnits(state).filter(
    unit => unit.relationship == null
  );
  return unitsWithoutRelationship.length > 0;
}

export function hasAssignUnitsFailed(state) {
  return state.users.assignUnitsToUserHasFailed;
}

export function isAssigningUnitToUser(state) {
  return state.users.assignUnitsToUserInProgress;
}

export function hasAssignUnitsSuccess(state) {
  return state.users.assignUnitsToUserSuccess;
}

export function getDisabledProfile(user) {
  return find(user.profiles, { status: 'DISABLED' });
}

export function getUserProfile(user) {
  return find(
    user.profiles,
    profile => !['ADMIN', 'GUARD', 'SUPERVISOR'].includes(profile.profileType)
  );
}

export function ischangeIndividualStatusInProgress(state) {
  return state.users.changeIndividualStatusInCommunityInProgress;
}

export function isUserDisabled(state) {
  const currentUser = getCurrentUser(state);
  if (!currentUser) {
    return false;
  }
  const disabledProfiles = getDisabledProfile(currentUser);
  return !isEmpty(disabledProfiles);
}

export function isCurrentUserDisabled(currentUser) {
  if (!currentUser) {
    return false;
  }
  const disabledProfiles = getDisabledProfile(currentUser);
  return !isEmpty(disabledProfiles);
}

export function isUserActive(user) {
  if (!user) {
    return false;
  }
  return user.accountInfo.status === 'ACTIVE';
}

export function isUnassignUnitFromUserInProgress(state) {
  return state.users.unassignUnitFromUserInProgress;
}

export function hasUnassignUnitFromUserFailed(state) {
  return state.users.unassignUnitFromUserHasFailed;
}

export function getUserApiError(state) {
  return state.users.apiError;
}

export function hasChangeIndividualStatusInCommunityFailed(state) {
  return state.users.changeIndividualStatusInCommunityFailed;
}

export function isChangeIndividualStatusInCommunityInProgress(state) {
  return state.users.changeIndividualStatusInCommunityInProgress;
}

export function hasUpdateUserByIdHasFailed(state) {
  return state.users.updateUserByIdHasFailed;
}

// Vehicles Selectors
export function hasAssignVehicleFailed(state) {
  return state.users.hasAssignVehicleFailed;
}

export function isUnassignVehicleFromUserInProgress(state) {
  return state.users.isUnassignVehicleFromUserInProgress;
}

export function hasUnassignVehicleFromUserFailed(state) {
  return state.users.hasUnassignVehicleFromUserFailed;
}

export function unassignVehicleToUserSuccess(state) {
  return state.users.unassignVehicleToUserSuccess;
}

export function isAssignVehicleInProgress(state) {
  return state.users.isAssignVehicleInProgress;
}

export function assignVehicleToUserSuccess(state) {
  return state.users.assignVehicleToUserSuccess;
}

export function isUnderAge(user) {
  return differenceInYears(Date.now(), user.birthDate) < 18;
}

export function getPetImageUri(state) {
  return state.users.petImageUploadedUri?.id;
}

export function uploadImageState(state) {
  return state.users.uploadingSuccess;
}

export function createNewPetStatus(state) {
  return state.users.petCreated;
}

export function getRecentWizardSelectedProfiles(state) {
  return state.users.createWizard.recentSelectedProfiles;
}

export function getLoggedUser(state) {
  return state.session.user;
}

export function getAssignExistingUser(state) {
  return {
    inProgress: state.users.assignExistingUserInProgress,
    success: state.users.assignExistingUserSuccess,
    fail: state.users.assignExistingUserFailed
  };
}

export function isGuard(user) {
  return (
    user?.profiles.filter(
      profile =>
        profile.type === allProfileTypes.GUARD &&
        profile.status == statusTypes.ENABLED
    ).length > 0
  );
}

export function isAdmin(user) {
  return (
    user?.profiles?.filter(
      profile =>
        profile.type === allProfileTypes.ADMIN &&
        profile.status == statusTypes.ENABLED
    ).length > 0
  );
}

export function isSupervisor(user) {
  return (
    user?.profiles.filter(
      profile =>
        profile.type === allProfileTypes.SUPERVISOR &&
        profile.status == statusTypes.ENABLED
    ).length > 0
  );
}

export function getTotalGuards(state) {
  return state.users.totalGuards;
}

export function getTotalEnabledUsers(state) {
  return state.users.totalEnabledUsers;
}

export function getTotalDisabledUsers(state) {
  return state.users.totalDisabledUsers;
}

export function findUserByIdInProgress(state) {
  return state.users.findUserByIdIsInProgress;
}
