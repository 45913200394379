/**
 * Returns the UTC representation of a Date object
 * @param {*} date a Date object
 */
export default function utc(date) {
  const parsedDate = new Date(date);
  return new Date(
    parsedDate.getUTCFullYear(),
    parsedDate.getUTCMonth(),
    parsedDate.getUTCDate(),
    parsedDate.getUTCHours(),
    parsedDate.getUTCMinutes(),
    parsedDate.getUTCSeconds()
  );
}
