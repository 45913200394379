import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Segment Module endpoints
 */
class SegmentModule extends ApiModule {
  /**
   * Queries the segments lists
   * @param {Object} query filter object.
   *                 Contains q, page, limit, and sort.
   *                 All optionals.
   * @return {Object} The list of segments that match the query.
   */
  querySegments(query) {
    return this.adapter.query('segments', query);
  }

  /**
   * Finds an Segment by ID.
   * @param {String} id The segment ID to find by.
   * @return {Object} the segment identified by the ID or 404
   *                  if no segment exists.
   */
  findSegmentById(id) {
    return this.adapter.find(`segments/${id}`);
  }

  /**
   * Creates an Segment.
   * @param {Object} segment The segment to be created.
   * @return {Object} the newly created segment.
   */
  createSegment(segment) {
    return this.adapter.save('segments', segment);
  }

  /**
   * Updates an Segment.
   * @param {Object} segment The segment to update.
   * @return {Object} the updated segment.
   */
  updateSegmentById(segment) {
    return this.adapter.update(`segments/${segment.id}`, segment);
  }

  /**
   * Deletes an Segment by ID.
   * @param {String} id The ID of the segment to delete.
   * @return nothing.
   */
  deleteSegmentById(id) {
    return this.adapter.delete(`segments/${id}`, false);
  }

  /**
   * Gets the possible segment's filter lists
   * @return {Object} The list of available filters.
   */
  findFilters() {
    return this.adapter.find('segments/filters');
  }
}

export default new SegmentModule('segment', ApiConfig);
