import { createAction } from 'redux-actions';
import { destroy } from 'redux-form';
import BlacklistActions from '../actions';

const types = {
  startCreateBlacklistWizard: 'startCreateBlacklistWizard',
  stopCreateBlacklistWizard: 'stopCreateBlacklistWizard',
  setCurrentStep: 'setCurrentStep',
  updateWizardBlacklist: 'updateWizardBlacklist'
};

const startCreateBlacklistWizard = createAction(
  types.startCreateBlacklistWizard
);
const stopCreateBlacklistWizard = createAction(types.stopCreateBlacklistWizard);
const setCurrentStep = createAction(types.setCurrentStep);
const updateWizardBlacklist = createAction(types.updateWizardBlacklist);

const backInCreateWizard = () => {
  return (dispatch, getState) => {
    const currentStep = getState().blacklist.createWizard.step;
    if (currentStep == null || currentStep === 'identity') {
      return; //no-op
    }
    if (currentStep === 'block') {
      dispatch(setCurrentStep('identity'));
    }
  };
};

const nextInCreateWizard = item => {
  return (dispatch, getState) => {
    const currentStep = getState().blacklist.createWizard.step;
    if (currentStep == null) {
      return; //no-op
    }
    if (currentStep === 'success') {
      dispatch(
        BlacklistActions.queryBlacklist({
          page: 0,
          limit: 15,
          sort: 'ASC'
        })
      );
      dispatch(stopCreateBlacklistWizard());
      return;
    }
    if (currentStep === 'identity') {
      dispatch(setCurrentStep('block'));
    }
    dispatch(updateWizardBlacklist(item));
    if (currentStep === 'block') {
      dispatch(BlacklistActions.createBlacklist(item)).then(() => {
        dispatch(setCurrentStep('success'));
        dispatch(destroy('createBlacklistForm'));
      });
    }
  };
};

/**
 * Used when the user closes the wizard without finishing.
 * That happens when closes the modal.
 */
const closeCreateBlacklistWizard = () => {
  return dispatch => {
    dispatch(destroy('createBlacklistForm'));
    dispatch(stopCreateBlacklistWizard()); //quizas aca pueda volver a llamar a la funcion que carga la tabla de blacklists
    dispatch(
      BlacklistActions.queryBlacklist({
        page: 0,
        limit: 15,
        sort: 'ASC'
      })
    );
  };
};

export { types };
export default {
  startCreateBlacklistWizard,
  closeCreateBlacklistWizard,
  nextInCreateWizard,
  backInCreateWizard
};
