import { isString } from 'lodash';
import { types } from './actions';

export const initialState = {
  step: null,
  createUser: null,
  units: [],
  selectedUnits: [],
  contractsPerUnits: [],
  queryUnitsIsInProgress: false,
  queryUnitsHasFailed: false,
  type: 'resident',
  selectedGuardProfile: 'GUARD',
  generatedPassword: null,
  openConfirmModal: false,
  withSpecificUnit: false,
  showSuccessModalInSpecificUnit: false,
  recentSelectedProfiles: null,
  continueWithExistingUser: false,
  notifiDeletedUser: false,
  deletedUser: null
};
const reducer = {
  [types.notifyDeletedUser]: (state, action) => {
    return {
      ...state,
      notifiDeletedUser: true,
      deletedUser: action.payload
    };
  },
  [types.continueWithUser]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        continueWithExistingUser: true
      }
    };
  },
  [types.retryIdentity]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        createUser: null,
        existingUser: null
      }
    };
  },
  [types.retryEmail]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        createUser: {
          ...createWizardState.createUser
        },
        existingUser: null
      }
    };
  },
  [types.openSuccessModalInSpecificUnit]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        showSuccessModalInSpecificUnit: true
      }
    };
  },
  [types.closeSuccessModalInSpecificUnit]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        showSuccessModalInSpecificUnit: false
      }
    };
  },
  [types.openConfirmModal]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      openConfirmModal: true
    };
  },
  [types.closeConfirmModal]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      openConfirmModal: false,
      createWizard: {
        ...createWizardState
      }
    };
  },
  [types.createWizardWithSpecificUnit]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        withSpecificUnit: true
      }
    };
  },
  [types.verifyUserExistInProgress]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        verifyUserExistInProgress: true,
        verifyUserExistSuccess: false,
        verifyUserExistFail: false,
        existingUser: null
      }
    };
  },
  [types.resetExistingUser]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        verifyUserExistInProgress: false,
        verifyUserExistSuccess: false,
        verifyUserExistFail: false,
        existingUser: null,
        continueWithExistingUser: false
      }
    };
  },
  [types.verifyUserExistSuccess]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        verifyUserExistInProgress: false,
        verifyUserExistSuccess: true,
        verifyUserExistFail: false,
        existingUser: action.payload
      }
    };
  },
  [types.verifyUserExistFail]: (state, payload) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        verifyUserExistInProgress: false,
        verifyUserExistSuccess: false,
        verifyUserExistFail: true,
        existingUser: null
      }
    };
  },
  [types.startCreateUserWizard]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        createUser: {
          personalId: {
            idType: 'DNI',
            number: ''
          }
        },
        step: 'identity',
        type: action.payload
      }
    };
  },
  [types.setCurrentStep]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        step: action.payload
      }
    };
  },
  [types.updateWizardUser]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        createUser: action.payload
      }
    };
  },
  [types.queryWizardUnitsInProgress]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        queryUnitsIsInProgress: true,
        queryUnitsHasFailed: false
      }
    };
  },
  [types.queryWizardUnitsFailed]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: true
      }
    };
  },
  [types.queryWizardUnitsSuccess]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        units: action.payload.content,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: false
      }
    };
  },
  [types.clearWizardUnits]: state => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        units: []
      }
    };
  },
  [types.addWizardUnitToSelection]: (state, action) => {
    const createWizardState = state.createWizard;
    const selectedUnits = createWizardState.selectedUnits;
    const unitToAdd = action.payload;
    const duplicateUnit = selectedUnits.filter(
      unit => unit.id === unitToAdd.id
    );

    return {
      ...state,
      createWizard: {
        ...createWizardState,
        selectedUnits:
          duplicateUnit.length > 0
            ? selectedUnits
            : selectedUnits.concat([unitToAdd])
      }
    };
  },
  [types.removeWizardUnitFromSelection]: (state, action) => {
    const createWizardState = state.createWizard;
    const selectedUnits = createWizardState.selectedUnits;
    const unitToRemove = action.payload;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        selectedUnits: selectedUnits.filter(unit => unit.id !== unitToRemove.id)
      }
    };
  },
  [types.updateWizardSelectedUnitRelationship]: (state, action) => {
    const createWizardState = state.createWizard;
    const selectedUnits = createWizardState.selectedUnits;
    const unitToUpdate = action.payload.unit;
    const newRelationship = action.payload.relationship;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        selectedUnits: selectedUnits.map(unit => {
          if (unit.id === unitToUpdate.id) {
            unit.relationship = newRelationship;
          }
          return unit;
        })
      }
    };
  },
  [types.setSelectedGuardProfile]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        selectedGuardProfile: action.payload
      }
    };
  },
  [types.setGuardGeneratedPassword]: (state, action) => {
    const createWizardState = state.createWizard;
    const generatedPassword = action.payload;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        generatedPassword
      }
    };
  },
  [types.setContractsPerUnit]: (state, action) => {
    const createWizardState = state.createWizard;
    return {
      ...state,
      createWizard: {
        ...createWizardState,
        contractsPerUnits: action.payload
      }
    };
  }
};

export default reducer;
