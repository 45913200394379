export const contractTypes = {
  PERMANENT_RENTAL: 'PERMANENT_RENTAL',
  TEMPORARY_RENTAL: 'TEMPORARY_RENTAL',
  PROPERTY_TITLE: 'PROPERTY_TITLE'
};

export const contractTypesToName = {
  PERMANENT_RENTAL: 'Alquiler Permanente',
  TEMPORARY_RENTAL: 'Alquiler Temporal',
  PROPERTY_TITLE: 'Compra Venta'
};

export const contractStatus = {
  REVOKED: 'REVOKED',
  EXPIRED: 'EXPIRED',
  CREATED: 'CREATED',
  VALID: 'VALID'
};

export const readableContractStatus = {
  CREATED: 'Creado pendiente de activar',
  REVOKED: 'Revocado',
  EXPIRED: 'Expirado',
  VALID: 'Validado y Activo'
};

export const profiles = {
  OWNER: 'OWNER',
  TENANT: 'TENANT',
  CORESIDENT: 'CORESIDENT'
};
