import { handleActions } from 'redux-actions';
import { types } from './actions';

const initialState = {
  getContractsInProgress: false,
  getContractsFail: false,
  getContractsSuccess: false,
  createContractFail: false,
  createContractInProgress: false,
  createContractSuccess: false,
  contractCreated: false,
  apiErrors: null,
  currentUnitContract: {},
  validatedContract: false,
  validateContractInProgress: false,
  validateContractFail: false,
  revokedContract: false,
  revokeContractInProgress: false,
  revokeContractFail: false,
  uploadFileInProgress: false,
  uploadFileFail: false,
  uploadFileSuccess: false,
  assignContractsUnitInProgress: false,
  assignContractsUnitFail: false,
  assignContractsUnitSuccess: false,
  fileUri: undefined,
  getContractOwnersByIdInProgress: false,
  contractOwner: {},
  updateContractDatesInProgress: false,
  updateContractDatesFail: false,
  updateContractDatesSuccess: false
};

const reducer = handleActions(
  {
    [types.updateContractDatesInProgress]: state => {
      return {
        ...state,
        updateContractDatesInProgress: true,
        updateContractDatesFail: false,
        updateContractDatesSuccess: false
      };
    },
    [types.updateContractDatesSuccess]: state => {
      return {
        ...state,
        updateContractDatesInProgress: false,
        updateContractDatesFail: false,
        updateContractDatesSuccess: true
      };
    },
    [types.updateContractDatesFail]: state => {
      return {
        ...state,
        updateContractDatesInProgress: false,
        updateContractDatesFail: true,
        updateContractDatesSuccess: false
      };
    },
    [types.asignContractsUnitInProgress]: state => {
      return {
        ...state,
        assignContractsUnitInProgress: true,
        assignContractsUnitFail: false,
        assignContractsUnitSuccess: false
      };
    },
    [types.asignContractsUnitSuccess]: state => {
      return {
        ...state,
        assignContractsUnitInProgress: false,
        assignContractsUnitFail: false,
        assignContractsUnitSuccess: true
      };
    },
    [types.asignContractsUnitFail]: state => {
      return {
        ...state,
        assignContractsUnitInProgress: false,
        assignContractsUnitFail: true,
        assignContractsUnitSuccess: false
      };
    },
    [types.resetAssignContractsUnit]: state => {
      return {
        ...state,
        assignContractsUnitInProgress: false,
        assignContractsUnitFail: false,
        assignContractsUnitSuccess: false
      };
    },
    [types.uploadFileInProgress]: state => {
      return {
        ...state,
        uploadFileInProgress: true,
        uploadFileFail: false,
        uploadFileSuccess: false
      };
    },

    [types.uploadFileSuccess]: (state, { payload }) => {
      return {
        ...state,
        uploadFileInProgress: false,
        uploadFileFail: false,
        uploadFileSuccess: true,
        fileUri: payload.fileUri
      };
    },
    [types.uploadFileFail]: state => {
      return {
        ...state,
        uploadFileInProgress: false,
        uploadFileFail: true,
        uploadFileSuccess: false
      };
    },

    [types.cleanCurrentUnitContracts]: state => {
      return {
        ...state,
        currentUnitContract: []
      };
    },

    [types.getContractsInProgress]: state => {
      return {
        ...state,
        getContractsInProgress: false,
        getContractsFail: false,
        currentUnitContract: {}
      };
    },
    [types.getContractsFail]: (state, action) => {
      return {
        ...state,
        getProvidersInProgress: false,
        getProvidersFail: true,
        apiErrors: action.payload.apiError
      };
    },
    [types.getContractsSuccess]: (state, { payload }) => {
      return {
        ...state,
        getContractsInProgress: false,
        getContractsFail: false,
        currentUnitContract: payload
      };
    },
    [types.createContractsFail]: state => {
      return {
        ...state,
        createContractFail: true,
        createContractInProgress: false,
        createContractSuccess: false,
        contractCreated: false,
        apiErrors: action.payload.apiError
      };
    },
    [types.createContractInProgress]: state => {
      return {
        ...state,
        createContractFail: false,
        createContractInProgress: true,
        createContractSuccess: false,
        contractCreated: false
      };
    },
    [types.createContractsSuccess]: state => {
      return {
        ...state,
        createContractFail: false,
        createContractInProgress: false,
        createContractSuccess: true,
        contractCreated: true
      };
    },

    [types.validateContractInProgress]: state => {
      return {
        ...state,
        validatedContract: false,
        validateContractInProgress: true,
        validateContractFail: false
      };
    },
    [types.validateContractSuccess]: state => {
      return {
        ...state,
        validatedContract: true,
        validateContractInProgress: false,
        validateContractFail: false
      };
    },
    [types.validateContractFail]: state => {
      return {
        ...state,
        validatedContract: false,
        validateContractInProgress: false,
        validateContractFail: true
      };
    },
    [types.revokeContractInProgress]: state => {
      return {
        ...state,
        revokedContract: false,
        revokeContractInProgress: true,
        revokeContractFail: false
      };
    },
    [types.revokeContractSuccess]: state => {
      return {
        ...state,
        revokedContract: true,
        revokeContractInProgress: false,
        revokeContractFail: false
      };
    },
    [types.revokeContractFail]: state => {
      return {
        ...state,
        revokedContract: false,
        revokeContractInProgress: false,
        revokeContractFail: true
      };
    },
    [types.getContractOwnersByIdSuccess]: (state, { payload }) => {
      return {
        ...state,
        getContractOwnersByIdInProgress: false,
        contractOwner: payload
      };
    },
    [types.getContractOwnersByIdInProgress]: state => {
      return {
        ...state,
        getContractOwnersByIdInProgress: true
      };
    },
    [types.getContractOwnersByIdFail]: (state, action) => {
      return {
        ...state,
        getContractOwnersByIdInProgress: false,
        apiErrors: action.payload.apiError
      };
    }
  },
  initialState
);

export default reducer;
