export const unitTypes = {
  APARTMENT: 'Departamento',
  HOUSE: 'Casa',
  SHOP: 'Negocio',
  PARK: 'Parque',
  SUM: 'SUM',
  CLUB_HOUSE: 'Club House',
  AMENITY: 'Amenity',
  MOORING: 'Amarra',
  OTHER: 'Otro'
};

export const individualStatusTypes = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED'
};

export const unitsStatusTypes = {
  EMPTY: 'EMPTY',
  TAKEN: 'TAKEN',
  RENTED: 'RENTED',
  IN_CONSTRUCTION: 'IN_CONSTRUCTION'
};

export const readableUnitStatusTypes = {
  EMPTY: 'Vacia',
  TAKEN: 'Ocupada',
  RENTED: 'Alquilada',
  IN_CONSTRUCTION: 'En construccion'
};
