import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';

/**
 * Security Module endpoints
 */

class SecurityModule extends ApiModule {
  /**
   * Authenticates against Keenvil's API.
   *
   * @param {String} email
   * @param {String} password
   * @returns a promise object
   */
  auth(email, password) {
    return this.adapter.save('auth', {
      email,
      password
    });
  }

  changeUserPasswordByAdmin(userId, newPassword) {
    return this.adapter.update(
      `individuals/account/${userId}/password`,
      { newPassword },
      false
    );
  }

  /**
   * Logs Off a user from Keenvil's API.
   *
   */
  logOff() {
    return this.adapter.delete('session', false);
  }

  /**
   * Refreshes an expired JWT
   * @param {String} token
   */
  refreshToken(token) {
    return this.adapter.save(`auth/refresh?token=${token}`, {});
  }

  getCarBrands(query) {
    return this.adapter.query('cars/brands', query);
  }

  requestPasswordReset(email) {
    return this.adapter.save('passwords', { email }, false);
  }

  uploadFile(image) {
    return this.adapter.save(`media/images`, image);
  }
}

export default new SecurityModule('security', ApiConfig);
