import { createAction } from 'redux-actions';

const types = {
  startBulkWizard: 'startBulkWizard',
  cancelBulkWizard: 'cancelBulkWizard',

  uploadUsersFileInProgress: 'uploadUsersFileInProgress',
  uploadUsersFileSuccess: 'uploadUsersFileSuccess',
  uploadUsersFileFailed: 'uploadUsersFileFailed',

  startBulkUserCreationProcessInProgress:
    'startBulkUserCreationProcessInProgress',
  startBulkUserCreationProcessSuccess: 'startBulkUserCreationProcessSuccess',
  startBulkUserCreationProcessFailed: 'startBulkUserCreationProcessFailed'
};

const startBulkWizard = createAction(types.startBulkWizard);
const cancelBulkWizard = createAction(types.cancelBulkWizard);

const uploadUsersFileSuccess = createAction(types.uploadUsersFileSuccess);
const uploadUsersFileInProgress = createAction(types.uploadUsersFileInProgress);
const uploadUsersFileFailed = createAction(types.uploadUsersFileFailed);

const startBulkUserCreationProcessSuccess = createAction(
  types.startBulkUserCreationProcessSuccess
);
const startBulkUserCreationProcessInProgress = createAction(
  types.startBulkUserCreationProcessInProgress
);
const startBulkUserCreationProcessFailed = createAction(
  types.startBulkUserCreationProcessFailed
);

const uploadUsersFile = usersFile => {
  return (dispatch, getState, Api) => {
    dispatch(uploadUsersFileInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .uploadUsersFile(usersFile)
      .then(response => {
        return dispatch(uploadUsersFileSuccess(response));
      })
      .catch(error => {
        dispatch(
          uploadUsersFileFailed({
            apiError: error.apiErrors
          })
        );
        throw new Error(error);
      });
  };
};

const startBulkUserCreationProcess = jobId => {
  return (dispatch, getState, Api) => {
    dispatch(startBulkUserCreationProcessInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .startBulkUserCreationProcess(jobId)
      .then(response => {
        return dispatch(startBulkUserCreationProcessSuccess(response));
      })
      .catch(error => {
        dispatch(
          startBulkUserCreationProcessFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  startBulkWizard,
  cancelBulkWizard,
  uploadUsersFile,
  startBulkUserCreationProcess
};
