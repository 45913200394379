import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as CreateWizardActionsTypes } from './createWizard/actions';
import { types as SessionActionsTypes } from '../session/actions';
import createWizardReducer, {
  initialState as createWizardInitialState
} from './createWizard/reducers';

const initialState = {
  queryUnitsIsInProgress: false,
  queryUnitsHasFailed: false,
  updateUnitByIdIsInProgress: false,
  updateUnitByIdHasFailed: false,
  updateUnitByIdSuccess: false,
  deleteUnitByIdIsInProgress: false,
  deleteUnitByIdHasFailed: false,
  deleteUnitByIdSuccess: false,
  findUnitByIdIsInProgress: false,
  findUnitByIdHasFailed: false,
  list: [],
  rentedUnits: 0,
  rentedUnitsTotalPages: -1,
  totalPages: -1,
  currentPage: 0,
  apiError: null,
  // currentUnit is the currently displayed unit
  // in the detail route
  currentUnit: null,
  updatedCurrentUnitCoordinates: {
    latitude: 0,
    longitude: 0
  },
  createUnitWizard: createWizardInitialState,
  createUnitHasFailed: false,
  createUnitInProgress: false
};

const reducer = handleActions(
  {
    ...createWizardReducer,
    [CreateWizardActionsTypes.stopCreateUnitWizard]: state => {
      return {
        ...state,
        createUnitWizard: createWizardInitialState,
        apiError: null
      };
    },
    [types.queryRentedContractsSuccess]: (state, action) => {
      const { totalElements, totalPages } = action.payload;
      return {
        ...state,
        rentedUnits: totalElements,
        rentedUnitsTotalPages: totalPages
      };
    },
    [types.queryMultipleUnitsSuccess]: (state, action) => {
      const units = action.payload;
      return {
        ...state,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: false,
        list: units,
        totalPages: units.totalPages,
        currentPage: units.number,
        apiError: null
      };
    },
    [types.queryUnitsSuccess]: (state, action) => {
      const units = action.payload;
      return {
        ...state,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: false,
        list: units.content,
        totalPages: units.totalPages,
        currentPage: units.number,
        apiError: null
      };
    },
    [types.queryMultipleUnitsFailed]: (state, action) => {
      return {
        ...state,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryUnitsFailed]: (state, action) => {
      return {
        ...state,
        queryUnitsIsInProgress: false,
        queryUnitsHasFailed: true,
        list: [],
        totalPages: -1,
        currentPage: 0,
        apiError: action.payload.apiError
      };
    },
    [types.queryUnitsInProgress]: state => {
      return {
        ...state,
        queryUnitsIsInProgress: true,
        queryUnitsHasFailed: false,
        apiError: null
      };
    },
    [types.findUnitByIdSuccess]: (state, action) => {
      return {
        ...state,
        findUnitByIdIsInProgress: false,
        findUnitByIdHasFailed: false,
        currentUnit: action.payload,
        apiError: null,
        updatedCurrentUnitCoordinates: {
          latitude: action.payload.location
            ? action.payload.location.latitude
            : 0,
          longitude: action.payload.location
            ? action.payload.location.longitude
            : 0
        }
      };
    },
    [types.findUnitByIdInProgress]: state => {
      return {
        ...state,
        currentUnit: null,
        findUnitByIdIsInProgress: true,
        findUnitByIdHasFailed: false,
        apiError: null
      };
    },
    [types.findUnitByIdFailed]: (state, action) => {
      return {
        ...state,
        findUnitByIdIsInProgress: false,
        findUnitByIdHasFailed: true,
        currentUnit: null,
        apiError: action.payload.apiError
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    },
    [types.deleteUnitByIdInProgress]: state => {
      return {
        ...state,
        deleteUnitByIdIsInProgress: true,
        deleteUnitByIdHasFailed: false,
        deleteUnitByIdSuccess: false,
        apiError: null
      };
    },
    [types.deleteUnitByIdSuccess]: state => {
      return {
        ...state,
        deleteUnitByIdIsInProgress: false,
        deleteUnitByIdHasFailed: false,
        deleteUnitByIdSuccess: true,
        apiError: null
      };
    },
    [types.deleteUnitByIdFailed]: (state, action) => {
      return {
        ...state,
        deleteUnitByIdIsInProgress: false,
        deleteUnitByIdHasFailed: true,
        deleteUnitByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.updateUnitByIdInProgress]: state => {
      return {
        ...state,
        updateUnitByIdIsInProgress: true,
        updateUnitByIdHasFailed: false,
        updateUnitByIdSuccess: false,
        apiError: null
      };
    },
    [types.updateUnitByIdSuccess]: state => {
      return {
        ...state,
        updateUnitByIdIsInProgress: false,
        updateUnitByIdHasFailed: false,
        updateUnitByIdSuccess: true,
        apiError: null
      };
    },
    [types.updateUnitByIdFailed]: (state, action) => {
      return {
        ...state,
        updateUnitByIdIsInProgress: false,
        updateUnitByIdHasFailed: true,
        updateUnitByIdSuccess: false,
        apiError: action.payload.apiError
      };
    },
    [types.createUnitSuccess]: state => {
      return {
        ...state,
        createUnitHasFailed: false,
        createUnitInProgress: false,
        apiError: null
      };
    },
    [types.createUnitInProgress]: state => {
      return {
        ...state,
        createUnitHasFailed: false,
        createUnitInProgress: true,
        apiError: null
      };
    },
    [types.createUnitFailed]: (state, action) => {
      return {
        ...state,
        createUnitHasFailed: true,
        createUnitInProgress: false,
        apiError: action.payload.apiError
      };
    },
    [types.setCurrentUnitCoordinates]: (state, action) => {
      return {
        ...state,
        updatedCurrentUnitCoordinates: action.payload
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
