import { createAction } from 'redux-actions';

const types = {
  getCarBrandsInProgress: 'getCarBrandsInProgress',
  getCarBrandsFail: 'getCarBrandsFail',
  getCarBrandsSuccess: 'getCarBrandsSuccess'
};

const getCarBrandsInProgress = createAction(types.getCarBrandsInProgress);
const getCarBrandsFail = createAction(types.getCarBrandsFail);
const getCarBrandsSuccess = createAction(types.getCarBrandsSuccess);

const getCarBrands = query => {
  return (dispatch, getState, Api) => {
    const securityModule = Api.getModule('security');
    securityModule.adapter.token = getState().session.user.token;
    securityModule.adapter.communityId = null;

    dispatch(getCarBrandsInProgress());

    return securityModule
      .getCarBrands({ size: 80 })
      .then(brands => {
        dispatch(getCarBrandsSuccess(brands));
      })
      .catch(error => {
        dispatch(
          getCarBrandsFail({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  types,
  getCarBrands
};
