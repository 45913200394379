import { handleActions } from 'redux-actions';
import { types } from './actions';

const initialState = {
  status: false
};

const reducer = handleActions(
  {
    [types.enableSupportTools]: state => {
      return {
        ...state,
        status: true
      };
    },
    [types.disableSupportTools]: state => {
      return {
        ...state,
        status: false
      };
    }
  },
  initialState
);

export default reducer;
