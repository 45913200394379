import isString from 'lodash/isString';

export function getCommunications(state) {
  return state.communications.list.map(communication => {
    return {
      ...communication
    };
  });
}

export function isQueryCommunicationsInProgress(state) {
  return state.communications.queryCommunicationsIsInProgress;
}

export function getQueryCommunicationsTotalPages(state) {
  return state.communication.totalPages;
}

export function getQueryCommunicationsCurrentPage(state) {
  return state.communication.currentPage;
}

export function getCommunicationById(state, id, type) {
  let communicationId = id;

  if (isString(id)) {
    communicationId = parseInt(id, 10);
  }
  const communications = state.list;
  const result = communications.filter(
    communication =>
      communication.id === communicationId && communication.type === type
  );
  return result.length > 0 ? result[0] : null;
}

export function getCurrentCommunication(state) {
  return state.communications.currentCommunication;
}

export function isDeleteCommunicationByIdInProgress(state) {
  return state.communications.deleteCommunicationByIdIsInProgress;
}

export function communicationfileUploadHasFailed(state) {
  return !!state.communications.uploadArticleFileHasFailed;
}

export function communicationfileUploadIsInProgress(state) {
  return !!state.communications.uploadArticleFileIsInProgress;
}

export function communicationfileUploadSucceed(state) {
  return !!state.communications.uploadArticleFileSucceded;
}
