import { push } from 'react-router-redux';
import { purgeStoredState } from 'redux-persist';
import SessionActions from './actions';
import NationalitiesActions from '../nationalities/actions';
import CarsActions from '../cars/actions';
import CommunitiesActions from '../communities/actions';
import UsersActions from '../users/actions';
import { setUserInformation } from '../../services/eventLogger';

const onSessionLoaded = store => next => action => {
  next(action);

  if (action.type === 'sessionHasBeenLoaded') {
    const user = store.getState().session.user;
    setUserInformation(user.id, user.email);
    store.dispatch(SessionActions.setupSessionTimer());
    store.dispatch(NationalitiesActions.findNationalities());
    store.dispatch(
      CarsActions.getCarBrands({
        size: 50
      })
    );

    store.dispatch(CommunitiesActions.findCommunityById());
    store.dispatch(CommunitiesActions.findCommunityGates());
  }

  if (action.type === 'updateActiveProfile') {
    store.dispatch(CommunitiesActions.findCommunityById());
    store.dispatch(CommunitiesActions.findCommunityGates());
    store
      .dispatch(
        UsersActions.queryUsers({
          type: 'RESIDENT',
          page: 0,
          limit: 15
        })
      )
      .then(() => {
        push('/users');
      });
  }

  if (action.type === 'sessionHasBeenDestroyed') {
    purgeStoredState(
      {
        storage: localStorage,
        keyPrefix: 'Keenvil-Castleblack'
      },
      ['session']
    ).catch(() => {
      throw new Error('The session has failed to purge');
    });
    store.dispatch(push('/login'));
  }
};

export default onSessionLoaded;
