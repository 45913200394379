const appConfig = {
  host: 'qa',

  setupHost() {
    const currentHost = window.location.host;
    switch (currentHost) {
      case 'admin.v2.a.keenvil.com':
        this.host = 'qa';
        break;
      case 'admin.v2.uat.keenvil.com':
        this.host = 'uat';
        break;
      case 'admin.v2.keenvil.com':
        this.host = 'prod';
        break;
      default:
        this.host = 'prod';
    }
  },

  apiKey: 'key',
  ApiGeoKey: '94ee75dc7bmshec43ff506349d9bp129bf1jsn91f84dda82a7',

  debugMode() {
    return this.host === 'uat ';
  },

  s3BucketsMap: {
    qa: 'http://assets.v2.qa.keenvil.com',
    uat: 'https://d3gus4w2ie67ip.cloudfront.net',
    prod: 'https://assets.v2.keenvil.com'
  },

  getS3BucketUrlForResource(resourcePath) {
    return resourcePath
      ? `${this.s3BucketsMap[this.host]}/${resourcePath}`
      : null;
  },

  apiBaseUrlMap: {
    qa: 'https://api.v2.qa.keenvil.com:8080',
    uat: 'https://v2.uat.keenvil.com',
    prod: 'https://api.v2.keenvil.com'
  },

  getApiBaseUrl() {
    return this.apiBaseUrlMap[this.host];
  },

  googleMapsUrl:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyD2QwsWx-H8ATEff7sUudZtGpfuVcOylv4&v=3.exp'
};

export default appConfig;
