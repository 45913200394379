import { handleActions } from 'redux-actions';
import { types } from './actions';

const initialState = {
  getCarBrandsInProgress: false,
  getCarBrandsHasFailed: false,
  apiErrors: null,
  brands: []
};

const reducer = handleActions(
  {
    [types.getCarBrandsInProgress]: state => {
      return {
        ...state,
        getCarBrandsInProgress: true,
        getCarBrandsHasFailed: false,
        apiErrors: null
      };
    },
    [types.getCarBrandsFail]: (state, action) => {
      return {
        ...state,
        getCarBrandsInProgress: false,
        getCarBrandsHasFailed: true,
        apiErrors: action.payload.apiErrors,
        brands: []
      };
    },
    [types.getCarBrandsSuccess]: (state, action) => {
      return {
        ...state,
        getCarBrandsInProgress: false,
        getCarBrandsHasFailed: false,
        apiErrors: null,
        brands: action.payload.content
      };
    }
  },
  initialState
);

export default reducer;
