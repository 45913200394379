import React, { PureComponent } from 'react';
import PropType from 'prop-types';
import Avatar from '../../components/avatar';
import AppConfig from '../../config/appConfig';

import './styles.css';
import { readableUnitStatusTypes, unitTypes } from '../../constants/units';
import { readableAllProfileTypes } from '../../constants/profileTypes';
import { getResidentProfiles } from '../../store/users/selectors';
import {
  getContractOwnersAndCoresidents,
  getContractTenantsAndCoresidents
} from '../../store/units/selectors';

const SearchResult = ({
  item,
  onResultSelected,
  isSecurity = false,
  isUnit = false,
  ...props
}) => {
  if (item == null) {
    return null;
  }

  const { firstName, lastName, avatar, contracts } = item;

  const avatarUri = avatar
    ? AppConfig.getS3BucketUrlForResource(avatar.uri)
    : null;

  const units =
    !isSecurity && !isUnit
      ? getResidentProfiles(item.profiles)
          .map(
            profile =>
              `${profile?.unit?.lotNumber}: ${
                readableAllProfileTypes[profile?.type]
              }`
          )
          .join(' | ')
      : '';

  const { tenantsContract } = getContractTenantsAndCoresidents(item, contracts);
  const { ownersContract } = getContractOwnersAndCoresidents(item, contracts);

  const unitState = tenantsContract
    ? readableUnitStatusTypes.RENTED
    : ownersContract
    ? readableUnitStatusTypes.TAKEN
    : readableUnitStatusTypes.EMPTY;

  return (
    <li
      {...props}
      onClick={event => {
        event.stopPropagation();
        onResultSelected(item);
      }}
    >
      {isUnit ? (
        <>
          <div className="main-search-results__list__itemUnit__avatar">
            <i className="fa fa-home fa-2x" aria-hidden="true" />
          </div>
          <p className="main-search-results__list__itemUnit__name">
            {item?.unitNumber}
          </p>
          <p className="main-search-results__list__itemUnit__name">
            {unitState}
          </p>
          <p className="main-search-results__list__itemUnit__name">
            {item?.lotNumber}
          </p>
          <p className="main-search-results__list__itemUnit__info">
            {unitTypes[item.type]}
          </p>
        </>
      ) : (
        <>
          <div className="main-search-results__list__itemUser__avatar">
            <Avatar modifier="small" uri={avatarUri} />
          </div>
          <p className="main-search-results__list__itemUser__name">{`${firstName} ${lastName}`}</p>
          <p className="main-search-results__list__itemUser__info">{units}</p>
        </>
      )}
    </li>
  );
};

class SearchResults extends PureComponent {
  render() {
    const {
      userResults,
      securityResults,
      onResultSelected,
      unitsResults,
      onUnitSelected
    } = this.props;
    return (
      <div className="main-search-results">
        {userResults.length > 0 ? (
          <>
            <div className="main-search-results__header">RESIDENTES</div>

            <ul className="main-search-results__list">
              {userResults.map(user => {
                return (
                  <SearchResult
                    className="main-search-results__list__itemUser"
                    onResultSelected={onResultSelected}
                    key={user.id}
                    item={user}
                  />
                );
              })}
            </ul>
          </>
        ) : null}
        {securityResults.length > 0 ? (
          <>
            <div className="main-search-results__header">SEGURIDAD</div>
            <ul className="main-search-results__list">
              {securityResults.map(guard => {
                return (
                  <SearchResult
                    item={guard}
                    className="main-search-results__list__itemUser"
                    key={guard.id}
                    onResultSelected={onResultSelected}
                    isSecurity={true}
                  />
                );
              })}
            </ul>
          </>
        ) : null}
        {unitsResults.length > 0 ? (
          <>
            <div className="main-search-results__header">UNIDADES</div>
            <div className="main-search-results__units-header">
              <p></p>
              <p>Unidad</p>
              <p>Estado</p>
              <p>Lote</p>
              <p>Tipo</p>
            </div>
            <ul className="main-search-results__list">
              {unitsResults.map(unit => {
                return (
                  <SearchResult
                    item={unit}
                    className="main-search-results__list__itemUnit"
                    key={unit.id}
                    onResultSelected={onUnitSelected}
                    isUnit={true}
                  />
                );
              })}
            </ul>
          </>
        ) : null}
      </div>
    );
  }
}

SearchResults.propTypes = {
  userResults: PropType.array,
  securityResults: PropType.array,
  onResultSelected: PropType.func
};

export default SearchResults;
