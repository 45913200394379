import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { autoRehydrate } from 'redux-persist';
import SessionMiddleware from './session/middleware';
import createLogger from 'redux-logger';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';
import Api from './api';

export const history = createBrowserHistory();
const thunkMiddleware = thunk.withExtraArgument(Api);

const enhancers = [autoRehydrate()];
const middleware = [
  thunkMiddleware,
  SessionMiddleware,
  routerMiddleware(history),
  createLogger
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const configureStore = function(initialState = {}) {
  return process.env.NODE_ENV === 'production'
    ? createStore(rootReducer, initialState, composedEnhancers)
    : console.tron.createStore(rootReducer, initialState, composedEnhancers);
};

export default configureStore;
