import { createAction } from 'redux-actions';
import { persistStore } from 'redux-persist';
import SessionActions from '../../store/session/actions';
import UsersActions from '../../store/users/actions';
import { sessionPersistFilter } from '../../store/session/persistFilters';
import AppConfig from '../../config/appConfig';

const types = {
  appHasBooted: 'appHasBooted'
};
const appHasBooted = createAction(types.appHasBooted);

const initializeApp = store => {
  return dispatch => {
    AppConfig.setupHost();

    persistStore(
      store,
      {
        whitelist: ['session'],
        transforms: [sessionPersistFilter],
        keyPrefix: 'Keenvil-Castleblack'
      },
      (sessionLoadError, state) => {
        dispatch(appHasBooted());

        if (sessionLoadError) {
          dispatch(SessionActions.logOffUser());
        } else {
          if (
            state.session &&
            SessionActions.isValidSession(state.session.user)
          ) {
            dispatch(SessionActions.sessionHasBeenLoaded());
            dispatch(UsersActions.countUsers());
          }
        }
      }
    );
  };
};

const terminateApp = isLoggedIn => {
  return dispatch => {
    //logoff actions
  };
};

export { types };
export default {
  types,
  initializeApp,
  terminateApp
};
