import { ApiModule } from '@keenvil/littlefinger';
import ApiConfig from '../apiConfig';
/**
 * Amenity Module endpoints
 */

class AmenitiesModule extends ApiModule {
  findAmenities(query) {
    return this.adapter.query('management/amenities/search', query);
  }

  findAmenityById(id) {
    return this.adapter.query(`management/amenities/${id}`);
  }

  getBookings(filters) {
    return this.adapter.query('management/bookings/search', filters);
  }

  getCategories() {
    return this.adapter.query('management/categories');
  }

  createCategory(category) {
    return this.adapter.save('management/categories', category);
  }

  updateCategory(category) {
    return this.adapter.update(
      `management/categories/${category.id}`,
      category
    );
  }

  deleteCategory(id) {
    return this.adapter.delete(`management/categories/${id}`, false);
  }

  uploadAmenityFile(file) {
    return Promise.resolve(file);
  }

  getBookableSlots(amenityId, dates) {
    return this.adapter.query(`booking/bookings/${amenityId}/slots`, {
      ...dates
    });
  }

  createAmenity(amenity) {
    return this.adapter.save('management/amenities', amenity);
  }

  updateAmenityById(amenity) {
    return this.adapter.update(`management/amenities/${amenity.id}`, amenity);
  }

  deleteAmenityById(id) {
    return this.adapter.delete(`management/amenities/${id}`, false);
  }

  addScheduleToAmenity(amenityId, schedule) {
    return this.adapter.save(
      `management/amenities/${amenityId}/schedules`,
      schedule
    );
  }

  addCreditsToUnit(unitId, credits) {
    return this.adapter.save(`management/units/${unitId}/credits`, credits);
  }

  findBookings(query) {
    return this.adapter.query('management/bookings/search', query);
  }

  getBookingById(id) {
    return this.adapter.query(`management/bookings/${id}`);
  }

  createBooking(booking) {
    return this.adapter.save('booking/bookings', booking);
  }

  cancelBooking(id, reason) {
    return this.adapter.save(`booking/bookings/${id}/cancel`, { reason });
  }

  getCreditsAssignments(filters) {
    let queryString = Object.keys(filters)
      .map(key => {
        if (Array.isArray(filters[key])) {
          return filters[key]
            .map(value => {
              return key + '[]=' + value;
            })
            .join('&');
        }

        return key + '=' + filters[key];
      })
      .join('&');
    return this.adapter.find(
      `management/units/creditAssignments/search?${queryString}`
    );
  }

  getDefaultersList() {
    return this.adapter.query('management/defaulters');
  }

  removeUnitFromDefaultersList(unitId) {
    return this.adapter.delete(`management/defaulters/${unitId}`, false);
  }

  getUnitBalance(unitId) {
    return this.adapter.query(`booking/units/${unitId}/creditBalance`);
  }

  addUnitToDefaultersList(unitId) {
    return this.adapter.save(`management/defaulters`, { unitId });
  }
}

export default new AmenitiesModule('amenities', ApiConfig);
