import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Avatar from '../avatar';
import SearchBar from '../searchBar';
import Logo from '../logo';
import AppConfig from '../../config/appConfig';
import './styles.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target: null,
      showProfile: false
    };
  }

  handleProfileClick = e => {
    this.setState({
      target: e.target,
      showProfile: !this.state.showProfile
    });
  };

  handleCommunitySelection = community => {
    const { onCommunitySelection } = this.props;
    return () => {
      onCommunitySelection(community);
    };
  };

  renderCommunityOption = (community, currentCommunity) => {
    return (
      <MenuItem
        active={community.id === currentCommunity.id}
        onClick={this.handleCommunitySelection}
      >
        <div onClick={this.handleCommunitySelection(community)}>
          {community.name}
        </div>
      </MenuItem>
    );
  };

  renderCommunitiesDropdown = (communities, currentCommunity) => {
    if (communities == null || communities.length === 0) {
      return null;
    }
    if (communities.length === 1) {
      return <p>{currentCommunity.name}</p>;
    } else {
      return (
        <DropdownButton
          bsStyle={'default'}
          title={currentCommunity.name}
          id={'communities-dropdown'}
        >
          {communities.map(community => {
            return this.renderCommunityOption(community, currentCommunity);
          })}
        </DropdownButton>
      );
    }
  };

  render() {
    const {
      communities,
      currentCommunity,
      avatar,
      userName,
      onLogOff
    } = this.props;
    return (
      <section className="kv-header">
        <div className="kv-header__logo">
          <Logo />
        </div>
        <article className="kv-header__search">
          <SearchBar />
        </article>
        <article className="kv-header__community">
          {this.renderCommunitiesDropdown(communities, currentCommunity)}
        </article>
        <article
          className="kv-header__profile"
          onClick={this.handleProfileClick}
        >
          <Avatar uri={AppConfig.getS3BucketUrlForResource(avatar)} />
          <span className="caret" />
        </article>
        <Overlay
          show={this.state.showProfile}
          target={this.state.target}
          placement="bottom"
          container={this}
          containerPadding={20}
        >
          <Popover id="popover-contained" title={userName}>
            <a
              href="/"
              className="kv-header__profile__logoff"
              onClick={onLogOff}
            >
              Cerrar Sesión
            </a>
          </Popover>
        </Overlay>
      </section>
    );
  }
}

Header.propTypes = {
  communities: PropTypes.arrayOf(PropTypes.object),
  currentCommunity: PropTypes.object,
  onCommunitySelection: PropTypes.func,
  avatar: PropTypes.string,
  userName: PropTypes.string,
  onLogOff: PropTypes.func
};

Header.defaultProps = {
  communities: [],
  currentCommunity: {},
  avatar: null,
  userName: ''
};

export default Header;
