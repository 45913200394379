export function serialize(unit, updatedCoordinates, communityId) {
  return {
    lotNumber: unit.lotNumber,
    unitNumber: unit.unitNumber,
    description: unit.description,
    location:
      updatedCoordinates.latitude !== 0 && updatedCoordinates.longitude !== 0
        ? updatedCoordinates
        : unit.location,
    status: unit.status,
    type: unit.type,
    address: unit.address,
    id: unit.id,
    community: {
      id: communityId
    }
  };
}
