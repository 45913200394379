import { isEmpty } from 'lodash';
import { createAction } from 'redux-actions';
import { contractTypes } from '../../constants/contracts';
import UnitsActions from '../units/actions';
import { getCurrentUnit } from '../units/selectors';

const types = {
  getContractsInProgress: 'getContractsInProgress',
  getContractsFail: 'getContractsFail',
  getContractsSuccess: 'getContractsSuccess',

  createContractsInProgress: 'createContractsInProgress',
  createContractsFail: 'createContractsFail',
  createContractsSuccess: 'createContractsSuccess',

  asignContractsUnitInProgress: 'asignContractsUnitInProgress',
  asignContractsUnitFail: 'asignContractsUnitFail',
  asignContractsUnitSuccess: 'asignContractsUnitSuccess',
  resetAssignContractsUnit: 'resetAssignContractsUnit',

  validateContractInProgress: 'validateContractInProgress',
  validateContractFail: 'validateContractFail',
  validateContractSuccess: 'validateContractSuccess',

  revokeContractInProgress: 'revokeContractInProgress',
  revokeContractFail: 'revokeContractFail',
  revokeContractSuccess: 'revokeContractSuccess',

  updateContractDatesInProgress: 'updateContractEndDateInProgress',
  updateContractDatesFail: 'updateContractEndDateFail',
  updateContractDatesSuccess: 'updateContractEndDateSuccess',

  getContractOwnersByIdInProgress: 'getContractOwnersByIdInProgress',
  getContractOwnersByIdFail: 'getContractOwnersByIdFail',
  getContractOwnersByIdSuccess: 'getContractOwnersByIdSuccess',

  addCoresidentsInProgress: 'addCoresidentsInProgress',
  addCoresidentsFail: 'addCoresidentsFail',
  addCoresidentsSuccess: 'addCoresidentsSuccess',

  uploadFileInProgress: 'uploadFileInProgress',
  uploadFileSuccess: 'uploadFileSuccess',
  uploadFileFail: 'uploadFileFail',

  uploadFileToContractInProgress: 'uploadFileToContractInProgress',
  uploadFileToContractSuccess: 'uploadFileToContractSuccess',
  uploadFileToContractFail: 'uploadFileToContractFail',

  cleanCurrentUnitContracts: 'cleanCurrentUnitContracts',

  refreshInProgress: 'refreshInProgress'
};

const cleanCurrentUnitContracts = createAction(types.cleanCurrentUnitContracts);

const cleanContracts = () => {
  return (dispatch, getState, Api) => {
    dispatch(cleanCurrentUnitContracts());
  };
};

const refreshInProgress = createAction(types.refreshInProgress);

const uploadFileInProgress = createAction(types.uploadFileInProgress);
const uploadFileSuccess = createAction(types.uploadFileSuccess);
const uploadFileFail = createAction(types.uploadFileFail);

const uploadFileToContractInProgress = createAction(
  types.uploadFileToContractInProgress
);
const uploadFileToContractSuccess = createAction(
  types.uploadFileToContractSuccess
);
const uploadFileToContractFail = createAction(types.uploadFileToContractFail);

const addCoresidentsInProgress = createAction(types.addCoresidentsInProgress);
const addCoresidentsSuccess = createAction(types.addCoresidentsSuccess);
const addCoresidentsFail = createAction(types.addCoresidentsFail);

const getContractOwnersByIdInProgress = createAction(
  types.getContractOwnersByIdInProgress
);
const getContractOwnersByIdFail = createAction(types.getContractOwnersByIdFail);
const getContractOwnersByIdSuccess = createAction(
  types.getContractOwnersByIdSuccess
);

const getContractsInProgress = createAction(types.getContractsInProgress);
const getContractsSuccess = createAction(types.getContractsSuccess);
const getContractsFail = createAction(types.getContractsFail);

const revokeContractInProgress = createAction(types.revokeContractInProgress);
const revokeContractSuccess = createAction(types.revokeContractSuccess);
const revokeContractFail = createAction(types.revokeContractFail);

const validateContractInProgress = createAction(
  types.validateContractInProgress
);
const validateContractFail = createAction(types.validateContractFail);
const validateContractSuccess = createAction(types.validateContractSuccess);

const updateContractDatesInProgress = createAction(
  types.updateContractDatesInProgress
);
const updateContractDatesFail = createAction(types.updateContractDatesFail);
const updateContractDatesSuccess = createAction(
  types.updateContractDatesSuccess
);

const createContractsInProgress = createAction(types.createContractsInProgress);
const createContractsSuccess = createAction(types.createContractsSuccess);
const createContractsFail = createAction(types.createContractsFail);

const asignContractsUnitInProgress = createAction(
  types.asignContractsUnitInProgress
);
const asignContractsUnitSuccess = createAction(types.asignContractsUnitSuccess);
const asignContractsUnitFail = createAction(types.asignContractsUnitFail);
const resetAssignContractsUnit = createAction(types.resetAssignContractsUnit);

const uploadContract = file => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(uploadFileInProgress());

    return communityModule
      .uploadContract(file)
      .then(response => {
        dispatch(uploadFileSuccess(response));
      })
      .catch(error => {
        dispatch(uploadFileFail({ apiError: error.apiErrors }));
      });
  };
};

const uploadFileToContract = (contractId, file) => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(uploadFileToContractInProgress());

    return communityModule
      .uploadContractFileToContract(contractId, file)
      .then(response => {
        dispatch(uploadFileToContractSuccess(response));
      })
      .catch(error => {
        dispatch(uploadFileFail({ apiError: error.apiErrors }));
      });
  };
};

const addCoresidents = userId => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(addCoresidentsInProgress());

    const { content } = getState().contracts.currentUnitContract;
    const validContract = content.filter(
      contract => contract.status === 'VALID'
    );

    let contract2;

    if (
      !isEmpty(
        validContract.filter(
          contract => contract.type !== contractTypes.PROPERTY_TITLE
        )
      )
    ) {
      contract2 = validContract.filter(
        contract => contract.type !== contractTypes.PROPERTY_TITLE
      );
    } else {
      contract2 = validContract.filter(
        contract => contract.type === contractTypes.PROPERTY_TITLE
      );
    }
    const contractObject = contract2.map(e => {
      return {
        tenantsId: e.tenantsId,
        ownersId: e.ownersId,
        unitId: e.unitId,
        coresidentsId: [userId]
      };
    });

    return communityModule
      .addCoresidents(contract2[0].id, contractObject)
      .then(() => {
        dispatch(addCoresidentsSuccess());
        return;
      })
      .catch(error => {
        dispatch(addCoresidentsFail());
      });
  };
};

const getCreatedContracts = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    return {
      getCreatedContractsAction: query =>
        communityModule.getCreatedContracts(query),
      success: res => dispatch(getContractsSuccess(res)),
      fail: err =>
        dispatch(
          getContractsFail({
            apiError: err.apiErrors
          })
        )
    };
  };
};

const createContract = (contractObject, usersToAssign, unit) => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(createContractsInProgress());

    return communityModule
      .createContract(contractObject)
      .then(res => {
        dispatch(createContractsSuccess(res));
      })
      .catch(error => error);
  };
};

const validateContract = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    return {
      validateContractAction: (contractId, validateObject) => {
        dispatch(validateContractInProgress());
        return communityModule.validateContract(contractId, validateObject);
      },
      refreshByUnitId: unitId => dispatch(refreshUnitAndContracts(unitId)),
      success: () => {
        dispatch(validateContractSuccess());
      },
      fail: () => dispatch(validateContractFail())
    };
  };
};

const refreshUnitAndContracts = unitId => {
  return (dispatch, getState, Api) => {
    dispatch(refreshInProgress());
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    communityModule
      .getCreatedContracts({ unitId })
      .then(res => dispatch(getContractsSuccess(res)));

    dispatch(UnitsActions.findUnitById(unitId));

    return Promise.resolve();
  };
};

const getContractOwnersById = (owners, tenants, coresidents) => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    const emptyFieldFIlter = [...owners, ...tenants, ...coresidents];

    const ownersSet = new Set(emptyFieldFIlter);

    const ownerSetValues = [...ownersSet].join(',');

    dispatch(getContractOwnersByIdInProgress());

    return communityModule
      .getContractOwnersById(ownerSetValues)
      .then(response => {
        dispatch(getContractOwnersByIdSuccess(response));
      })
      .catch(error => {
        dispatch(getContractOwnersByIdFail({ apiError: error.apiErrors }));
      });
  };
};

const revokeContract = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;

    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(revokeContractInProgress());

    return {
      revokeContractAction: (contractId, reason) =>
        communityModule.revokeContract(contractId, reason),
      refreshByUnitId: unitId => dispatch(refreshUnitAndContracts(unitId)),
      success: () => dispatch(revokeContractSuccess()),
      fail: () => dispatch(revokeContractFail())
    };
  };
};

const assignContractUnitsToUser = (userId, serializedUnits) => {
  return (dispatch, getState, Api) => {
    dispatch(asignContractsUnitInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    return communityModule
      .assignUnitsToUser(userId, [serializedUnits])
      .then(response => {
        dispatch(asignContractsUnitSuccess(response));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          asignContractsUnitFail({
            apiError: error.apiErrors
          })
        );
        return Promise.reject();
      });
  };
};

const changeDates = () => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    const currentUnit = getCurrentUnit(getState());

    return {
      changeDatesAction: (contractId, contract) => {
        dispatch(updateContractDatesInProgress());
        return communityModule.updateContractDates(contractId, contract);
      },
      changeDatesSuccess: res => {
        dispatch(refreshUnitAndContracts(currentUnit.id));
        return dispatch(updateContractDatesSuccess(res));
      },
      changeDatesFail: err => dispatch(updateContractDatesFail(err))
    };
  };
};

const addResidentsToContract = (residentsToAdd, contractId, unitId) => {
  return (dispatch, getState, Api) => {
    dispatch(asignContractsUnitInProgress());

    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    function* generateMultipleAssigns() {
      let index = 0;

      for (index = 0; index < residentsToAdd.length; index++) {
        const profileType = residentsToAdd[index].profiles[0].type;
        const individualId = residentsToAdd[index].id;
        yield communityModule
          .updateContract(profileType, individualId, contractId)
          .then(res => {
            if (index === residentsToAdd.length - 1) {
              dispatch(asignContractsUnitSuccess());
            }
          })
          .catch(err => {});
      }

      yield dispatch(resetAssignContractsUnit());

      yield dispatch(getCreatedContracts({ unitId }));
      yield dispatch(UnitsActions.findUnitById(unitId));
    }

    const asyncIterative = async () => {
      for await (let res of generateMultipleAssigns()) {
      }
    };

    return asyncIterative();
  };
};

export { types };

export default {
  types,
  getCreatedContracts,
  createContract,
  assignContractUnitsToUser,
  validateContract,
  revokeContract,
  getContractOwnersById,
  addCoresidents,
  cleanContracts,
  uploadContract,
  uploadFileToContract,
  refreshUnitAndContracts,
  addResidentsToContract,
  createContractsSuccess,
  changeDates
};
