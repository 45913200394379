import { createAction } from 'redux-actions';

export const types = {
  enableSupportTools: 'enableSupportTools',
  disableSupportTools: 'disableSupportTools'
};

const enableSupportTools = createAction(types.enableSupportTools);
const disableSupportTools = createAction(types.disableSupportTools);

export default {
  enableSupportTools,
  disableSupportTools
};
