import React from 'react';
import Loading from './components/loading';
import Loadable from 'react-loadable';

const LoadableUsers = Loadable({
  loader: () => import('./modules/users'),
  loading() {
    return <Loading legend="Cargando usuarios" />;
  }
});

const LoadableUnits = Loadable({
  loader: () => import('./modules/units'),
  loading() {
    return <Loading legend="Cargando unidades" />;
  }
});

const LoadableCommunication = Loadable({
  loader: () => import('./modules/communication'),
  loading() {
    return <Loading legend="Cargando comunicaciones" />;
  }
});

const LoadableMovements = Loadable({
  loader: () => import('./modules/movements'),
  loading() {
    return <Loading legend="Cargando movimientos" />;
  }
});

const LoadableAmenities = Loadable({
  loader: () => import('./modules/amenities'),
  loading() {
    return <Loading legend="Cargando comodidades" />;
  }
});

const LoadableSegments = Loadable({
  loader: () => import('./modules/segments'),
  loading() {
    return <Loading legend="Cargando segmentos" />;
  }
});

const LoadableInbox = Loadable({
  loader: () => import('./modules/inbox'),
  loading() {
    return <Loading legend="Cargando inbox" />;
  }
});

const LoadableBlacklist = Loadable({
  loader: () => import('./modules/blacklist'),
  loading() {
    return <Loading legend="Cargando blacklist" />;
  }
});

const LoadableSettings = Loadable({
  loader: () => import('./modules/settings'),
  loading() {
    return <Loading legend="Cargando segmentos" />;
  }
});

const LoadableTutorials = Loadable({
  loader: () => import('./modules/tutorials'),
  loading() {
    return <Loading legend="Cargando tutoriales" />;
  }
});

/**
 * This file defines the Modules Routes.
 * Do not define subroutes here. Use the Module index file
 * to do so. That way we encapsulate features. THANKS, Esteban.
 */
export default function(store) {
  return [
    {
      name: 'Comunicación',
      path: '/communications',
      component: LoadableCommunication
    },
    {
      name: 'Usuarios',
      path: '/users',
      component: LoadableUsers
    },
    {
      name: 'Movimientos',
      path: '/movements',
      component: LoadableMovements,
      exact: true
    },
    {
      name: 'Unidades Funcionales',
      path: '/units',
      component: LoadableUnits
    },
    {
      name: 'Amenities',
      path: '/amenities',
      component: LoadableAmenities
    },
    {
      name: 'Segmentos',
      path: '/segments',
      component: LoadableSegments
    },
    {
      name: 'Notificationes',
      path: '/inbox',
      component: LoadableInbox
    },
    {
      name: 'Blacklist',
      path: '/blacklist',
      component: LoadableBlacklist
    },
    {
      name: 'Configuración',
      path: '/settings',
      component: LoadableSettings
    },
    {
      name: 'Tutoriales',
      path: '/tutorials',
      component: LoadableTutorials
    }
  ];
}
