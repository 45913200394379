import { createAction } from 'redux-actions';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import isAfter from 'date-fns/isAfter';
import some from 'lodash/some';
import { KeenvilApiError } from '@keenvil/littlefinger';

let sessionTimerId = null;

/**
 * This constant indicates the number of miliseconds before
 * the session
 */
const SESSION_REFRESH_LATENCY = 5000;

const ADMIN_TYPE = 'ADMIN';

const SUPERVISOR_TYPE = 'SUPERVISOR';

const TIME_TO_RESET = 800;

const types = {
  authenticationInProgress: 'authenticationInProgress',
  authenticationFail: 'authenticationFail',
  authenticationSuccess: 'authenticationSuccess',
  refreshTokenInProgress: 'refreshTokenInProgress',
  refreshTokenFail: 'refreshTokenFail',
  refreshTokenSuccess: 'refreshTokenSuccess',
  sessionHasBeenLoaded: 'sessionHasBeenLoaded',
  sessionHasBeenDestroyed: 'sessionHasBeenDestroyed',
  updateActiveProfile: 'updateActiveProfile',
  logOff: 'logOff',
  passwordResetInProgress: 'passwordResetInProgress',
  passwordResetFail: 'passwordResetFail',
  passwordResetSuccess: 'passwordResetSuccess'
};

const authenticationInProgress = createAction(types.authenticationInProgress);
const authenticationFail = createAction(types.authenticationFail);
const authenticationSuccess = createAction(types.authenticationSuccess);

const refreshTokenInProgress = createAction(types.refreshTokenInProgress);
const refreshTokenFail = createAction(types.refreshTokenFail);
const refreshTokenSuccess = createAction(types.refreshTokenSuccess);

const sessionHasBeenLoaded = createAction(types.sessionHasBeenLoaded);
const sessionHasBeenDestroyed = createAction(types.sessionHasBeenDestroyed);

const logOff = createAction(types.logOff);

const updateActiveProfile = createAction(types.updateActiveProfile);

const passwordResetInProgress = createAction(types.passwordResetInProgress);
const passwordResetFail = createAction(types.passwordResetFail);
const passwordResetSuccess = createAction(types.passwordResetSuccess);

/**
 * A session is valid if has a token and its
 * tokenTTL has not expired
 * @param {Object} session session data
 */
function isValidSession(session) {
  return (
    session != null &&
    session.token != null &&
    session.tokenTtl != null &&
    isAfter(new Date(session.tokenTtl), new Date())
  );
}

/**
 * A session is expired if both tokenTTL and refreshTokenTTL
 * have expired.
 * @param {Object} session session data
 */
function isExpiredSession(session) {
  return (
    session != null &&
    session.token != null &&
    session.tokenTtl != null &&
    !isAfter(new Date(session.tokenTtl), new Date()) &&
    !isAfter(new Date(session.refreshTokenTtl), new Date())
  );
}

function isValidUser(profiles) {
  return some(
    profiles,
    profile => profile.type === ADMIN_TYPE || profile.type === SUPERVISOR_TYPE
  );
}

function addPlatformHeaders(securityModule) {
  securityModule.adapter.applicationId = 'CASTLEBLACK';
  securityModule.adapter.operatingSystem = 'BROWSER';
}

const authenticateUser = (email, password) => {
  return (dispatch, getState, Api) => {
    const securityModule = Api.getModule('security');
    securityModule.adapter.communityId = null;

    dispatch(authenticationInProgress());

    addPlatformHeaders(securityModule);
    return securityModule
      .auth(email, password)
      .then(response => {
        if (isValidUser(response.profiles)) {
          dispatch(
            authenticationSuccess({
              session: response
            })
          );
          dispatch(sessionHasBeenLoaded());
        } else {
          const error = new KeenvilApiError();
          error.apiErrors = [
            {
              code: 'invalidUserRole'
            }
          ];
          throw error;
        }
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          authenticationFail({
            apiErrors: error.apiErrors
          })
        );
        return Promise.reject(error.apiErrors);
      });
  };
};

const refreshToken = onRefreshedToken => {
  return (dispatch, getState, Api) => {
    const session = getState().session.user;

    const securityModule = Api.getModule('security');
    securityModule.adapter.communityId = null;
    securityModule.adapter.token = null;
    addPlatformHeaders(securityModule);

    dispatch(refreshTokenInProgress());

    return securityModule
      .refreshToken(session.refreshToken)
      .then(json => {
        dispatch(
          refreshTokenSuccess({
            refreshToken: json.refreshToken,
            refreshTokenTtl: json.refreshTokenTtl,
            token: json.token,
            tokenTtl: json.tokenTtl
          })
        );
        dispatch(setupSessionTimer());

        if (onRefreshedToken) {
          onRefreshedToken();
        }
      })
      .catch(() => {
        dispatch(refreshTokenFail());
        dispatch(logOffUser());
      });
  };
};

function clearSessionTimer() {
  clearInterval(sessionTimerId);
}

const setupSessionTimer = () => {
  return (dispatch, getState) => {
    const session = getState().session.user;

    const sessionWindow =
      differenceInMilliseconds(new Date(session.tokenTtl), new Date()) -
      SESSION_REFRESH_LATENCY;

    const interval = sessionWindow > 0 ? sessionWindow : 0;

    clearSessionTimer();
    sessionTimerId = setInterval(function() {
      dispatch(refreshToken());
    }, interval);
  };
};

const logOffUser = () => {
  return (dispatch, getState, Api) => {
    clearSessionTimer();

    const session = getState().session;

    dispatch(sessionHasBeenDestroyed());

    //Before resetting the store, we need to wait until the navigation has been resetted
    //and all scenes have been dismounted, so we don't fire unnecessary and dandegoures re-renders.
    setTimeout(function() {
      dispatch(logOff());
    }, TIME_TO_RESET);

    if (session && session.user) {
      const securityModule = Api.getModule('security');
      securityModule.adapter.communityId = null;
      securityModule.adapter.token = session.user.token;

      addPlatformHeaders(securityModule);
      securityModule.logOff().catch(error => {
        error = null;
      });
    }
  };
};

const requestPasswordReset = email => {
  return (dispatch, getState, Api) => {
    const securityModule = Api.getModule('security');
    securityModule.adapter.communityId = null;

    dispatch(passwordResetInProgress());

    addPlatformHeaders(securityModule);
    return securityModule
      .requestPasswordReset(email)
      .then(response => {
        dispatch(passwordResetSuccess());
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          passwordResetFail({
            apiErrors: error.apiErrors
          })
        );
        return Promise.resolve();
      });
  };
};

export { types };
export default {
  types,
  authenticateUser,
  isValidSession,
  isExpiredSession,
  setupSessionTimer,
  sessionHasBeenLoaded,
  logOffUser,
  updateActiveProfile,
  requestPasswordReset
};
