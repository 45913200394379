import { createAction } from 'redux-actions';
import { getBlacklistById } from './selectors';
import { serialize } from './serializer';

const types = {
  queryBlacklistSuccess: 'queryBlacklistSuccess',
  queryBlacklistInProgress: 'queryBlacklistInProgress',
  queryBlacklistFailed: 'queryBlacklistFailed',
  findBlacklistByIdSuccess: 'findBlacklistByIdSuccess',
  findBlacklistByIdInProgress: 'findBlacklistByIdInProgress',
  findBlacklistByIdFailed: 'findBlacklistByIdFailed',
  updateBlacklistByIdSuccess: 'updateBlacklistByIdSuccess',
  updateBlacklistByIdInProgress: 'updateBlacklistByIdInProgress',
  updateBlacklistByIdFailed: 'updateBlacklistByIdFailed',
  deleteBlacklistByIdSuccess: 'deleteBlacklistByIdSuccess',
  deleteBlacklistByIdInProgress: 'deleteBlacklistByIdInProgress',
  deleteBlacklistByIdFailed: 'deleteBlacklistByIdFailed',
  createBlacklistSuccess: 'createBlacklistSuccess',
  createBlacklistInProgress: 'createBlacklistInProgress',
  createBlacklistFailed: 'createBlacklistFailed'
};

const queryBlacklistSuccess = createAction(types.queryBlacklistSuccess);
const queryBlacklistInProgress = createAction(types.queryBlacklistInProgress);
const queryBlacklistFailed = createAction(types.queryBlacklistFailed);
const findBlacklistByIdSuccess = createAction(types.findBlacklistByIdSuccess);
const findBlacklistByIdInProgress = createAction(
  types.findBlacklistByIdInProgress
);
const findBlacklistByIdFailed = createAction(types.findBlacklistByIdFailed);
const updateBlacklistByIdSuccess = createAction(
  types.updateBlacklistByIdSuccess
);
const updateBlacklistByIdInProgress = createAction(
  types.updateBlacklistByIdInProgress
);
const updateBlacklistByIdFailed = createAction(types.updateBlacklistByIdFailed);
const deleteBlacklistByIdSuccess = createAction(
  types.deleteBlacklistByIdSuccess
);
const deleteBlacklistByIdInProgress = createAction(
  types.deleteBlacklistByIdInProgress
);
const deleteBlacklistByIdFailed = createAction(types.deleteBlacklistByIdFailed);
const createBlacklistSuccess = createAction(types.createBlacklistSuccess);
const createBlacklistInProgress = createAction(types.createBlacklistInProgress);
const createBlacklistFailed = createAction(types.createBlacklistFailed);

/**
 *
 * @param {Object} query query filter object.
 *                 Contains q, page, limit, and sort.
 *                 All optionals.
 */
const queryBlacklist = query => {
  return (dispatch, getState, Api) => {
    dispatch(queryBlacklistInProgress());

    const crowdModule = Api.getModule('crowd');
    crowdModule.adapter.token = getState().session.user.token;
    crowdModule.adapter.communityId = getState().session.profile.community.id;

    return crowdModule
      .queryBlacklist(query)
      .then(response => {
        dispatch(queryBlacklistSuccess(response));
      })
      .catch(error => {
        dispatch(
          queryBlacklistFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const findBlacklistById = (id, forceUpdate = false) => {
  return (dispatch, getState, Api) => {
    dispatch(findBlacklistByIdInProgress());

    const localBlacklist = getBlacklistById(getState().blacklist, id);

    if (localBlacklist && !forceUpdate) {
      dispatch(findBlacklistByIdSuccess(localBlacklist));
      return Promise.resolve(localBlacklist);
    } else {
      const crowdModule = Api.getModule('crowd');
      crowdModule.adapter.token = getState().session.user.token;
      crowdModule.adapter.communityId = getState().session.profile.community.id;

      return crowdModule
        .findBlacklistById(id)
        .then(response => {
          dispatch(findBlacklistByIdSuccess(response));
          return Promise.resolve(response);
        })
        .catch(error => {
          dispatch(
            findBlacklistByIdFailed({
              apiError: error.apiErrors
            })
          );
          return Promise.reject(error);
        });
    }
  };
};

const updateBlacklistById = blacklist => {
  return (dispatch, getState, Api) => {
    dispatch(updateBlacklistByIdInProgress());

    const crowdModule = Api.getModule('crowd');
    crowdModule.adapter.token = getState().session.user.token;
    crowdModule.adapter.communityId = getState().session.profile.community.id;

    const serializedBlacklist = serialize(blacklist);

    return crowdModule
      .updateBlacklistById(serializedBlacklist)
      .then(response => {
        dispatch(updateBlacklistByIdSuccess(response));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(
          updateBlacklistByIdFailed({
            apiError: error.apiErrors
          })
        );
        return Promise.reject(error);
      });
  };
};

const deleteBlacklistById = blacklist => {
  return (dispatch, getState, Api) => {
    dispatch(deleteBlacklistByIdInProgress());

    const crowdModule = Api.getModule('crowd');
    crowdModule.adapter.token = getState().session.user.token;
    crowdModule.adapter.communityId = getState().session.profile.community.id;

    return crowdModule
      .deleteBlacklistById(blacklist)
      .then(response => {
        dispatch(deleteBlacklistByIdSuccess(response));
      })
      .catch(error => {
        dispatch(
          deleteBlacklistByIdFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

const createBlacklist = blacklist => {
  return (dispatch, getState, Api) => {
    dispatch(createBlacklistInProgress());

    const crowdModule = Api.getModule('crowd');
    crowdModule.adapter.token = getState().session.user.token;
    crowdModule.adapter.communityId = getState().session.profile.community.id;

    const serializedBlacklist = serialize(blacklist);
    return crowdModule
      .createBlacklist(serializedBlacklist)
      .then(response => {
        dispatch(createBlacklistSuccess(response));
        return response;
      })
      .catch(error => {
        dispatch(
          createBlacklistFailed({
            apiError: error.apiErrors
          })
        );
        throw error;
      });
  };
};

export { types };
export default {
  queryBlacklist,
  findBlacklistById,
  updateBlacklistById,
  deleteBlacklistById,
  createBlacklist
};
