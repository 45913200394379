import { handleActions } from 'redux-actions';
import { types } from './actions';
import { types as SessionActionsTypes } from '../session/actions';

const initialState = {
  id: '',
  name: '',
  administrator: {},
  location: {
    latitude: 0,
    longitude: 0
  },
  timeZone: '',
  communityNumbers: [],
  findCommunityByIdInProgress: false,
  findCommunityByIdHasFailed: false,
  findCommunityGatesInProgress: false,
  findCommunityGatesHasFailed: false,
  apiErrors: null,
  gates: []
};

const reducer = handleActions(
  {
    [types.findCommunityByIdInProgress]: state => {
      return {
        ...state,
        findCommunityByIdInProgress: true,
        findCommunityByIdHasFailed: false,
        apiErrors: null
      };
    },
    [types.findCommunityByIdFail]: (state, action) => {
      return {
        ...state,
        findCommunityByIdInProgress: false,
        findCommunityByIdHasFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.findCommunityByIdSuccess]: (state, action) => {
      const community = action.payload;
      return {
        ...state,
        findCommunityByIdInProgress: false,
        findCommunityByIdHasFailed: false,
        apiErrors: null,
        id: community.id,
        name: community.name,
        administrator: community.administrator,
        communityNumbers: community.communityNumbers,
        location: community.location,
        timeZone: community.timeZone,
        contractRules: community?.contractRules,
        settings: community?.settings
      };
    },
    [types.findCommunityGatesInProgress]: state => {
      return {
        ...state,
        findCommunityGatesInProgress: true,
        findCommunityGatesHasFailed: false,
        apiErrors: null
      };
    },
    [types.findCommunityGatesFail]: (state, action) => {
      return {
        ...state,
        findCommunityGatesInProgress: false,
        findCommunityGatesHasFailed: true,
        apiErrors: action.payload.apiErrors
      };
    },
    [types.findCommunityGatesSuccess]: (state, action) => {
      return {
        ...state,
        findCommunityGatesInProgress: false,
        findCommunityGatesHasFailed: false,
        apiErrors: null,
        gates: action.payload
      };
    },
    [SessionActionsTypes.logOff]: () => {
      return initialState;
    }
  },
  initialState
);

export default reducer;
