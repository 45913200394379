import AppConfig from '../../config/appConfig';

export default {
  getBaseUrl() {
    return AppConfig.getApiBaseUrl();
  },
  getApiKey() {
    return AppConfig.apiKey;
  }
};
