import { createAction } from 'redux-actions';

const types = {
  sendNotificationSuccess: 'sendNotificationSuccess',
  sendNotificationInProgress: 'sendNotificationInProgress',
  sendNotificationFailed: 'sendNotificationFailed'
};

const sendNotificationSuccess = createAction(types.sendNotificationSuccess);
const sendNotificationInProgress = createAction(
  types.sendNotificationInProgress
);
const sendNotificationFailed = createAction(types.sendNotificationFailed);

const sendNotification = (unitId, id) => {
  return (dispatch, getState, Api) => {
    const communityModule = Api.getModule('community');
    communityModule.adapter.token = getState().session.user.token;
    communityModule.adapter.communityId = getState().session.profile.community.id;

    dispatch(sendNotificationInProgress());

    return communityModule
      .sendNotification(parseInt(unitId, 10), id)
      .then(() => {
        dispatch(sendNotificationSuccess());
      })
      .catch(error => {
        dispatch(
          sendNotificationFailed({
            apiError: error.apiErrors
          })
        );
      });
  };
};

export { types };
export default {
  types,
  sendNotification
};
