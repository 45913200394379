import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchActions from '../../store/search/actions';
import throttle from 'lodash/throttle';
import get from 'lodash/get';

import SearchResults from '../searchResults';
import './styles.css';

const SEARCH_THROTTLE_DELAY = 800;
const MIN_SEARCH_QUERY_LENGTH = 3;

class SearchBar extends Component {
  state = {
    searchValue: ''
  };

  constructor(props) {
    super(props);
    this.throttledSearch = throttle(this.props.search, SEARCH_THROTTLE_DELAY);
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  clearResults() {
    this.props.clear();
    this.setState({
      searchValue: ''
    });
  }

  handleKeyUp = event => {
    const key = event.keyCode;
    if (key === 27) {
      this.clearResults();
    }
  };

  handleSearch = event => {
    const searchValue = get(event, 'target.value');
    this.setState(
      {
        searchValue
      },
      () => {
        if (searchValue.length >= MIN_SEARCH_QUERY_LENGTH) {
          this.throttledSearch(searchValue);
        }
      }
    );
  };

  handleResultSelection = result => {
    const { history } = this.props;
    history.push(`/users/${result.id}`);
    this.clearResults();
  };

  handleResulUnitSelection = unit => {
    const { history } = this.props;
    history.push(`/units/${unit.id}`);
  };

  handleOnBlur = () => {
    setTimeout(() => {
      this.clearResults();
    }, 200);
  };

  render() {
    const {
      userSearchResults,
      unitsSearchResults,
      securitySearchResults
    } = this.props;

    const hasResults =
      userSearchResults.length ||
      unitsSearchResults.length ||
      securitySearchResults.length;

    return (
      <label className="main-search-bar" htmlFor="searchBarInput">
        <div className="main-search-bar__input">
          <i
            className="icon-search main-search-bar__input__icon"
            aria-hidden="true"
          />
          <input
            id="searchBarInput"
            className=""
            placeholder="Buscar residentes y guardias (mínimo de 3 caracteres)"
            value={this.state.searchValue}
            onChange={this.handleSearch}
            onBlur={this.handleOnBlur}
          />
          {hasResults ? (
            <SearchResults
              userResults={userSearchResults}
              securityResults={securitySearchResults}
              onResultSelected={this.handleResultSelection}
              unitsResults={unitsSearchResults}
              onUnitSelected={this.handleResulUnitSelection}
            />
          ) : null}
        </div>
      </label>
    );
  }
}

SearchBar.propTypes = {
  userSearchResults: PropTypes.array,
  securitySearchResults: PropTypes.array,
  search: PropTypes.func,
  clear: PropTypes.func
};

const mapStateToProps = state => {
  return {
    userSearchResults: state.search.usersList,
    securitySearchResults: state.search.securityList,
    unitsSearchResults: state.search.unitsList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    search: query => {
      dispatch(SearchActions.search(query));
    },
    clear: () => {
      dispatch(SearchActions.clearUsersSearch());
    }
  };
};

const ConnectedSearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);

export default withRouter(ConnectedSearchBar);
